const proto=Blob.prototype;
if (!proto.arrayBuffer) {
	proto.arrayBuffer=function() {
		return new Promise((resolve,reject) => {
			const file_reader=new FileReader();
			file_reader.onerror=(ev) => {
				reject(ev.target.error);
			};
			file_reader.onload=(ev) => {
				resolve(ev.target.result);
			};
			file_reader.readAsArrayBuffer(this);
		});
	};
}
