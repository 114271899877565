const proto=DOMTokenList.prototype;

if (!proto.replace) {
	proto.replace=function(old_token,new_token) {
		if (this.contains(old_token)) {
			this.remove(old_token);
			this.add(new_token);
			return true;
		}
		return false;
	};
}
