import * as tx_util from "./util";

let main_menu_overlay=document.getElementById("main-menu-overlay");
let main_menu_overlay_drawer=main_menu_overlay.querySelector(".drawer");
let main_menu_username=main_menu_overlay_drawer.querySelector(".top .item.user .text");

let overflown_seq_no=0;
let closing_timeout_id;

function check_item_overflown(text_elem) {
	let d=text_elem.scrollWidth-text_elem.offsetWidth;
	if (text_elem._d!==d) {
		text_elem._d=d;
		let stylesheet=text_elem._overflow_stylesheet;
		if (d>0) {
			if (!stylesheet)
				stylesheet=text_elem._overflow_stylesheet=new tx_util.DynStyleSheet();
			let animation_name=`overflown-${++overflown_seq_no}-crawl`;
			stylesheet.add(
				`@keyframes ${animation_name} {`+
					'from {'+
						'transform:translateX(0);'+
					'}'+
					'to {'+
						`transform:translateX(-${d}px);`+
					'}'+
				'}'
			);
			let text=text_elem.textContent;
			text_elem.innerHTML="";
			let inner=document.createElement("div");
			inner.classList.add("crawl");
			inner.textContent=text;
			inner.style.animation=`${animation_name} 4s infinite alternate ease-in-out`;
			text_elem.appendChild(inner);
		}
		else if (stylesheet)
			stylesheet.reset();
	}
}

function open_submenu(submenu_name) {
	close_all_submenus();
	for (let submenu of main_menu_overlay_drawer.querySelectorAll('.submenu[data-submenu="'+submenu_name+'"]')) {
		submenu.classList.add("open");
		if (submenu_name!=="root")
			for (let text_elem of submenu.querySelectorAll(".item .text"))
				check_item_overflown(text_elem);
	}
}

function close_all_submenus() {
	for (let submenu of main_menu_overlay_drawer.querySelectorAll(".submenu.open"))
		submenu.classList.remove("open");
}

function menu_item_click(ev) {
	let submenu_name=this.getAttribute("data-submenu");
	if (submenu_name!=null)
		open_submenu(submenu_name);
	else if (!this.classList.contains("no-close"))
		close_main_menu(true);
}

function open_main_menu() {
	if (closing_timeout_id!==undefined) {
		clearTimeout(closing_timeout_id);
		closing_timeout_id=undefined;
	}
	main_menu_overlay.style.display="";
	// use _dummy to prevent closure from removing the next line:
	main_menu_overlay._dummy=getComputedStyle(main_menu_overlay).display.length;
	main_menu_overlay.classList.add("open");
	open_submenu("root");
}

function close_main_menu(immediately) {
	if (immediately) {
		main_menu_overlay.style.display="none";
		main_menu_overlay.classList.remove("open");
		close_all_submenus();
	}
	else {
		main_menu_overlay.classList.remove("open");
		closing_timeout_id=setTimeout(() => {
			closing_timeout_id=undefined;
			main_menu_overlay.style.display="none";
			close_all_submenus();
		},250);
	}
}

export function set_user(username) {
	main_menu_username.setAttribute("data-username",username);
	check_item_overflown(main_menu_username);
}

export function init() {
	const scroll_mode=Symbol("scroll_mode");
	const scroll_mode_horiz=Symbol("scroll_mode_horiz");
	const scroll_mode_vert=Symbol("scroll_mode_vert");
	tx_util.make_draggable(main_menu_overlay,{mouse:false},function() {
		main_menu_overlay.classList.add("dragging");
	},function(ev) {
		if (ev.data[scroll_mode]===undefined) {
			if (Math.abs(ev.cdx)<=Math.abs(ev.cdy))
				ev.data[scroll_mode]=scroll_mode_vert;
			else {
				ev.data[scroll_mode]=scroll_mode_horiz;
				main_menu_overlay.classList.add("no-scroll");
			}
		}
		if (ev.data[scroll_mode]===scroll_mode_horiz)
			main_menu_overlay_drawer.style.left=Math.min(ev.cdx,0)+"px";
	},function (ev) {
		main_menu_overlay.classList.remove("dragging");
		if (ev.data[scroll_mode]===scroll_mode_horiz) {
			main_menu_overlay.classList.remove("no-scroll");
			if (ev.cdx<-50) close_main_menu();
			main_menu_overlay_drawer.style.left="";
		}
	});
	main_menu_overlay.addEventListener("click",function(ev) {
		if (ev.target===main_menu_overlay)
			close_main_menu();
	},false);
	for (let item of main_menu_overlay_drawer.querySelectorAll(".item"))
		item.addEventListener("click",menu_item_click,true);
	document.getElementById("main-menu-overlay-burger").addEventListener("click",open_main_menu);
	matchMedia("(min-width:768px) and (min-height:400px)").addListener(() => {
		close_main_menu(true);
	});
}
