import * as tx_util from "./util";
import {mke} from "./mke";

const toast_overlay=document.getElementById("toast-overlay");
const visible_messages=new Map();
let count=0;

export function toast(msg,timeout=3000,id) {
	timeout=Math.max(0,timeout);
	if (id) visible_messages.get(id)?.();

	const msg_div=mke(".msg",msg+"");
	const wrap_div=mke(".wrap",msg_div);
	toast_overlay.appendChild(wrap_div);

	let removed=false;
	let aborter=new tx_util.Aborter();
	const remove_message=() => {
		if (removed) return;
		removed=true;
		if (id) visible_messages.delete(id);
		aborter.abort();
		wrap_div.classList.remove("visible");
		setTimeout(() => {
			count--;
			wrap_div.style[tx_util.flexBasis]="";
			setTimeout(() => {
				toast_overlay.removeChild(wrap_div);
			},100);
		},500);
	};
	visible_messages.set(id,remove_message);
	const show_message=() => {
		count++;
		getComputedStyle(msg_div).opacity;
		wrap_div.classList.add("visible");
		if (isFinite(timeout)) {
			//const user_interacted=wait_for_user_interaction();
			tx_util.sleep(500,aborter)
				//.then(() => user_interacted) // bad, use Promise.all
				.then(() => tx_util.sleep_visible(timeout,aborter))
				.then(remove_message,tx_util.do_nothing);
		}
	};

	if (count===0) {
		wrap_div.style.transitionDuration="0s";
		wrap_div.style[tx_util.flexBasis]=wrap_div.scrollHeight+"px";
		getComputedStyle(wrap_div)[tx_util.flexBasis];
		wrap_div.style.transitionDuration="";
		show_message();
	}
	else {
		wrap_div.style[tx_util.flexBasis]=wrap_div.scrollHeight+"px";
		setTimeout(show_message,100);
	}
	msg_div.addEventListener("click",remove_message);
}

export function close_toast(id) {
	visible_messages.get(id)?.();
}

//const event_types=["mousemove","mousedown","touchstart","keydown"];
//const listener_options={capture:true,passive:true};

// TODO: support the Aborter api
//function wait_for_user_interaction() {
	//return new Promise(resolve => {
		//const done=() => {
			//resolve();
			//for (const type of event_types)
				//removeEventListener(type,done,listener_options);
		//};
		//for (const type of event_types)
			//addEventListener(type,done,listener_options);
	//});
//}
