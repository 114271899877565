import * as tx_util from "./util";

var std_menu_bar;

export function Bar() {
	var active_menu;
	var active_target;
	var active_menu_div;

	var close=() => {
		if (active_menu!==undefined) {
			active_menu.close();
			active_menu=undefined;
			active_target.blur();
		}
	}
	this.close=close;

	this.notify_opened=(menu,target,menu_div) => {
		close();
		active_menu=menu;
		active_target=target;
		active_menu_div=menu_div;
	}

	this.notify_closed=(menu) => {
		if (active_menu===menu)
			active_menu=undefined;
	}

	var event_handler=ev => {
		if (active_menu!==undefined && !active_menu_div.contains(ev.target) && !active_target.contains(ev.target)) {
			active_target.classList.remove("hover");
			close();
		}
	};
	addEventListener("mousedown",event_handler);
	addEventListener("keydown",event_handler);
	addEventListener("touchstart",event_handler,{passive:true});
}

export function Menu(target,menu,{right_aligned,allow_scrolling,onclose,onopen}={}) {
	var bar=std_menu_bar;
	var that=this;
	that._target=target;
	that._$target=$(target);
	that._focus=$(".menu_focus",target)[0];
	that._menu=menu;
	that._$menu=$(menu);
	that.is_open=false;
	that._right_aligned=right_aligned;
	that._onclose=onclose;
	that._onopen=onopen;
	that._closing=undefined;
	that._bar=bar;
	that._$menu.css("position","absolute");
	that._$menu.hide();
	$("> .menu_closeOnClick",menu).click(function() {that._close(true)});
	$("> .menu_closeOnClick[onclick]",menu).each(function() {
		var elem=this;
		elem._onclick=elem.onclick;
		elem.onclick=function() {
			setTimeout(function() {elem._onclick()},0);
		}
	});
	$(menu).hover(function() {that.open()},function() {that._close()});
	$(target).hover(function() {
		target.classList.add("hover");
		that.open();
	},function() {
		that._close();
		target.classList.remove("hover");
	});
	if (!target.classList.contains("notaptoggle"))
		tx_util.listen_for_taps(target,{passive:false},(ev) => {
			if (that.is_open)
				that._close(true);
			else
				that.open();
			tx_util.prevent_default(ev);
		});
	const unhighlight_menu_items=() => {
		for (const item of menu.querySelectorAll(".hover")) {
			if (item.parentElement===menu)
				item.classList.remove("hover");
		}
	};
	const highlight_menu_item=(elt) => {
		unhighlight_menu_items();
		while (elt && elt.parentElement!==menu)
			elt=elt.parentElement;
		if (elt)
			elt.classList.add("hover");
	};
	const highlight_menu_item_at_clientXY=(obj) => {
		highlight_menu_item(document.elementFromPoint(obj.clientX,obj.clientY));
	};
	menu.addEventListener("mouseenter",highlight_menu_item_at_clientXY);
	menu.addEventListener("mousemove",highlight_menu_item_at_clientXY);
	menu.addEventListener("mouseleave",unhighlight_menu_items);
	menu.addEventListener("touchstart",(ev) => {
		if (!allow_scrolling)
			tx_util.prevent_default(ev);
		highlight_menu_item_at_clientXY(ev.touches[0]);
	});
	menu.addEventListener("touchmove",(ev) => {
		highlight_menu_item_at_clientXY(ev.touches[0]);
	});
	menu.addEventListener("touchend",(ev) => {
		const elt=document.elementFromPoint(ev.changedTouches[0].clientX,ev.changedTouches[0].clientY);
		if (menu.contains(elt) && ev.cancelable) {
			tx_util.prevent_default(ev);
			elt.click();
		}
		unhighlight_menu_items();
	});
	menu.addEventListener("touchcancel",unhighlight_menu_items);
	target.addEventListener("focus",() => {that.open()},true);
}

Menu.prototype._position=function() {
	//if (this._pos) return;
	//this._pos=true;
	var left;
	if (this._right_aligned)
		left=this._target.offsetLeft+this._target.offsetWidth-this._$menu.outerWidth()
	else
		left=Math.max(0,Math.min(this._target.offsetParent.offsetWidth-this._menu.offsetWidth,this._target.offsetLeft));
	this._$menu.css({left:left,top:this._target.offsetTop+this._target.offsetHeight});
}

Menu.prototype.open=function(do_not_focus) {
	if (this._closing) {
		clearTimeout(this._closing);
		this._closing=undefined;
	}
	if (this.is_open) return;
	if (this._onopen) this._onopen();
	this._$menu.fadeIn("fast");
	this._position();
	this._$target.addClass("menu_open");
	this._bar.notify_opened(this,this._target,this._menu);
	this.is_open=true;
	if (this._focus && !do_not_focus) this._focus.focus();
}

Menu.prototype._close=function(now) {
	if (!this.is_open) return;
	var that=this;
	if (this._closing) clearTimeout(this._closing);
	if (now) {
		if (that._onclose) that._onclose();
		that._closing=undefined;
		that._$menu.hide(); //that._$menu.fadeOut(0);
		that._$target.removeClass("menu_open");
		that.is_open=false;
		that._bar.notify_closed(that);
	}
	else {
		this._closing=setTimeout(function() {
			if (that._onclose) that._onclose();
			that._closing=undefined;
			that._$menu.fadeOut("fast");
			that._$target.removeClass("menu_open");
			that.is_open=false;
			that._bar.notify_closed(that);
		},500);
	}
}

Menu.prototype.close=function() {
	this._close(true);
}

export function init() {
	std_menu_bar=new Bar();
}
