import * as tx_menu_overlay from "./menu_overlay";
import * as tx_util from "./util";
import * as tx_oauth2 from "./oauth2";

var session_info;
var permissions={};
var username_span=document.querySelector("#menu_userT .username");
var on_auth_change_cbs=[];
var stylesheet;
const permission_regex=/^[a-zA-Z0-9_-]+$/;

export function get_auth_token() {
	return session_info && session_info.auth_token;
}

export function can(permission) {
	return permissions[permission]||false;
}

export function get_user_id() {
	return session_info && session_info.id;
}

export function is_logged_in() {
	return session_info!=undefined;
}

function after_auth_change() {
	while (stylesheet.cssRules.length>0)
		stylesheet.deleteRule(0);
	const permissions_css=((session_info||{}).permissions||[]).filter(p => permission_regex.test(p));
	const permissions_not=permissions_css.map(p => `:not([data-req~="${p}"])`).join("");
	const permissions_not2=permissions_css.map(p => `:not([data-req2~="${p}"])`).join("");
	stylesheet.insertRule(`[data-req]${permissions_not},[data-req2]${permissions_not2}{display:none!important}`,0);
	permissions={};
	if (!session_info) {
		document.body.setAttribute("data-loggedin",0);
		document.body.removeAttribute("data-can");
		username_span.setAttribute("data-username","");
		tx_menu_overlay.set_user("");
	}
	else {
		document.body.setAttribute("data-loggedin",1);
		document.body.setAttribute("data-can",session_info.permissions.join(" "));
		username_span.setAttribute("data-username",session_info.name);
		tx_menu_overlay.set_user(session_info.name);
		for (const permission of session_info.permissions) {
			permissions[permission]=true;
		}
	}
	for (let i in on_auth_change_cbs)
		on_auth_change_cbs[i]();
}

export function on_auth_change(cb) {
	on_auth_change_cbs.push(cb);
}

function check_auth() {
	session_info=tx_util.get_value("session");
	after_auth_change();
}

export function login(new_session_info) {
	session_info=new_session_info;
	tx_util.set_value("session",session_info);
	after_auth_change();
}

export function forget_session() {
	session_info=undefined;
	tx_util.delete_value("session");
	after_auth_change();
}

export function logout() {
	return tx.api_request_promise("logout").then(tx_util.do_nothing);
}

const facebook_oauth2_params={
	client_id:facebook_app_id,
	display:"popup",
	response_type:"token",
	auth_type:"reauthenticate",
	scope:"email",
};

var google_oauth2_params={client_id:google_oauth2_client_id,scope:"openid email",response_type:"id_token"};

export function get_google_token(cb) {
	google_oauth2_params.nonce=tx_util.random_letters(8);
	tx_oauth2.request("https://accounts.google.com/o/oauth2/v2/auth",google_oauth2_params,function(res) {
		cb(res ? res.id_token : null);
	});
}

export function login_google(cb) {
	cb=cb||tx_util.do_nothing;
	get_google_token(function(id_token) {
		if (!id_token) return;
		tx_util.api_request("login_google",id_token,function(res,err) {
			if (!res)
				cb(err);
			else {
				login(res);
				cb(undefined);
			}
		});
	});
}

export function login_reset_code(reset_code) {
	return tx_util.api_request_promise("login_reset_code",reset_code).then(login);
}

export function login_cn(user_id) {
	return tx_util.api_request_promise("login_cn",user_id).then(login);
}

export function init() {
	stylesheet=document.querySelector("#req-style").sheet;
	check_auth();
	tx_util.api_request_promise("get_session").then(login,err => {
		if (err.code!==1) throw err;
	});
}
