const _map=Symbol();
const _value=Symbol();
const _timestamp=Symbol();

const prefix=Date.now()+"-"+(Math.random()*1000000000|0)+"-";
let seq_no=0;

export function new_key() {
	return prefix+(++seq_no);
}

export class Cache {
	constructor() {
		this[_map]={};
	}

	new_key() {
		return new_key();
	}

	set(key,value) {
		this[_map][key]={
			[_value]:value,
			[_timestamp]:performance.now(),
		};
	}

	put(value) {
		const key=this.new_key();
		this.set(key,value);
		return key;
	}

	get(key) {
		const r=this[_map][key];
		if (r) {
			r[_timestamp]=performance.now();
			return r[_value];
		}
	}

	ping(key) {
		this.get(key);
	}

	has(key) {
		return key in this[_map];
	}
}
