import * as tx_util from "./util";

var prefix;
var callbacks={};

var origin=location.origin||(location.protocol+"//"+location.hostname+(location.port ? ":"+location.port : ""));
var redirect_uri=origin+location.pathname+"oauth2-redirect/";

export function request(base_uri,params,cb) {
	var state=prefix+tx_util.random_letters(12);
	params.redirect_uri=redirect_uri;
	params.state=state;
	var uri=base_uri+"?"+$.param(params,true);
	callbacks[state]=cb;
	var popup=open(uri,"oauth2-redirect-"+state,"width=580,height=530");
	// this is ugly:
	var poll_timer=setInterval(function() {
		if (popup.closed) {
			clearInterval(poll_timer);
			call_callback(state,undefined);
		}
	},250);
}

function call_callback(state,res) {
	var cb=callbacks[state];
	if (!cb) return;
	delete callbacks[state];
	cb(res);
}

export function on_redirect(search,hash) {
	search=search||"";
	if (search[0]==="?") search=search.slice(1);
	hash=hash||"";
	if (hash[0]==="#") hash=hash.slice(1);
	var parts=[];
	if (search) parts=parts.concat(search.split("&"));
	if (hash) parts=parts.concat(hash.split("&"));
	var res={};
	for (var i=0; i<parts.length; i++) {
		var j=parts[i].indexOf("=");
		if (j<0) continue;
		res[decodeURIComponent(parts[i].slice(0,j))]=decodeURIComponent(parts[i].slice(j+1));
	}
	call_callback(res.state,res);
}

export function init() {
	prefix=tx_util.random_letters(12);
}
