import * as tx_util from "./util";
import {mke} from "./mke";

export function create_tabs(tabs_div,pages,selected) {
	tabs_div.classList.add("tabs_live");
	var headerc=document.createElement("div");
	headerc.className="tabs_headerc";
	var header=document.createElement("div");
	header.className="tabs_header";
	headerc.appendChild(header);
	tabs_div.appendChild(headerc);
	var bodyc=document.createElement("div");
	bodyc.className="tabs_bodyc";
	var body=document.createElement("div");
	body.className="tabs_body";
	var veil=document.createElement("div");
	veil.className="loading-page";
	veil.style.display="none";
	bodyc.appendChild(body);
	bodyc.appendChild(veil);
	tabs_div.appendChild(bodyc);
	var scrollLeft=[];
	var scrollTop=[];
	var active_page=-1;
	var onselect=[];
	const onready_cbs=[];
	tabs_div._scroll_save=function() {
		scrollLeft[active_page]=body.scrollLeft;
		scrollTop[active_page]=body.scrollTop;
	};
	tabs_div._scroll_restore=function() {
		if (scrollTop[active_page]==null) {
			body.scrollLeft=1; // firefox bug
			body.scrollLeft=0;
			body.scrollTop=1; // firefox bug
			body.scrollTop=0;
		}
		else {
			body.scrollLeft=scrollLeft[active_page];
			body.scrollTop=scrollTop[active_page];
		}
	};
	var select=function(i,clicked) {
		if (active_page==i) return;
		if (active_page>=0) {
			tabs_div._scroll_save();
			header.childNodes[active_page].classList.remove("tabs_selected");
			while (body.firstChild) body.removeChild(body.firstChild);
		}
		if (pages[i]) {
			const metadata={};
			metadata.tab_name=pages[i].name||undefined;
			metadata.tab_title=pages[i].title||undefined;
			metadata.tab_title_fixed=pages[i].title_fixed!=null ? pages[i].title_fixed : undefined;
			body.append(mke("script.metadata",{type:"application/json"},JSON.stringify(metadata)),pages[i].content);
		}
		header.childNodes[i].classList.add("tabs_selected");
		var prev_active_page=active_page;
		active_page=i;
		tabs_div._scroll_restore();
		veil.style.display=pages[i].busy ? "" : "none";
		for (let cb of onselect)
			cb(active_page,pages[i].name,clicked,prev_active_page);
		if (pages[i].onselect) pages[i].onselect(clicked);
	}
	tabs_div._select=i => {select(i)};
	tabs_div._onselect=function(cb) {
		onselect.push(cb);
	}
	var select_by_name=function(name) {
		name=name||"";
		var l=pages.length;
		for (var i=0; i<l; i++)
			if ((pages[i].name||"")===name)
				return select(i,false);
	}
	tabs_div._select_by_name=select_by_name;
	tabs_div._get_page_content=(i) => pages[i].content;
	$.each(pages,function(i,page) {
		const s=mke("a",page.title);
		$(s).mousedown(function(ev) {if (ev.which===1) select(i,true)});
		s.onclick=tx_util.prevent_default;
		s.onmouseup=function() {page.content.focus(); page.content.blur()};
		header.appendChild(s);
		$(page.content).addClass("tabs_page_content");
		page.header=s;
		s._page=page;
		page.content._page=page;
		scrollLeft.push(0);
		scrollTop.push(null);
		$(".tabs-script.onscroll",page.content).each(function() {
			if (this._callback) page.onscroll=this._callback;
		});
		$(".tabs-script.onresize",page.content).each(function() {
			if (this._callback) page.onresize=this._callback;
		});
		$(".tabs-script.onready",page.content).each(function() {
			if (this._callback) onready_cbs.push(this._callback);
		});
		$(".tabs-script",page.content).remove();
		page.busy=false;
		page.content._set_busy=function(busy) {
			page.busy=busy;
			if (active_page===i)
				veil.style.display=busy ? "" : "none";
		}
	});
	body.onscroll=function() {
		if (pages[active_page] && pages[active_page].onscroll)
			pages[active_page].onscroll(body);
	}

	var header_left=0;
	tx_util.make_draggable(header,{cursor:"default",mouse:false},
		function(ev) {
			ev.data.l=header_left;
		},
		function(ev) {
			var l=ev.data.l+ev.cdx;
			header_left=Math.min(0,Math.max(header.offsetWidth-header.scrollWidth,l));
			header.style.left=header_left+"px";
		}
	);

	tabs_div._resized=function() {
		if (pages[active_page] && pages[active_page].onresize)
			pages[active_page].onresize(body);
		header_left=Math.min(0,Math.max(header.offsetWidth-header.scrollWidth,header_left));
		header.style.left=header_left+"px";
	};
	$(".tabs-script.onready",tabs_div).each(function() {
		if (this._callback) onready_cbs.push(this._callback);
	});
	$(".tabs-script",tabs_div).remove();
	select(selected,false);
	for (const cb of onready_cbs) cb(tabs_div);
}

export function tabify(tabs_div) {
	if (tabs_div.nodeType!=1 || tabs_div.tagName!="DIV" || !tabs_div.classList.contains("tabs")) return false;
	var pages=[];
	var c=tabs_div.firstChild;
	while (c) {
		if (c.nodeType==1 && c.tagName=="DIV" && c.classList.contains("tabs_page")) {
			const title=c.getAttribute("data-tab-title")||"";
			const title_fixed=c.getAttribute("data-tab-title-fixed");
			const name=c.getAttribute("data-tab-name");
			pages.push({title,title_fixed,name,content:c});
			c.removeAttribute("data-tab-title");
			c.removeAttribute("data-tab-title-fixed");
			c.removeAttribute("data-tab-name");
			c.classList.remove("tabs_page");
		}
		c=c.nextSibling;
	}
	while (tabs_div.firstChild) tabs_div.removeChild(tabs_div.firstChild);
	tabs_div.classList.remove("tabs");
	create_tabs(tabs_div,pages,0);
	return true;
}

export function recursive_tabify(node) {
	tabify(node);
	$("div.tabs",node).each(function() {
		tabify(this);
	});
}

export function set_title(node,title) {
	var page_content=$(node).closest(".tabs_page_content")[0];
	if (page_content) {
		page_content._page.title=title;
		page_content._page.header.textContent=title;
	}
}

export function set_busy(node,busy) {
	var page_content=$(node).closest(".tabs_page_content")[0];
	if (page_content) page_content._set_busy(busy);
}
