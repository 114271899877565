import * as tx_util from "./util";
import * as tx_input_validation from "./input_validation";
import {mke} from "./mke";
import {unaccent_fc} from "./unaccent";

const _set_focus=Symbol();
const _update_text=Symbol();
const _get_input_state=Symbol();
const _delay=Symbol();
const _wrapper=Symbol();
const _input=Symbol();
const _dropdown_div=Symbol();
const _style_copied=Symbol();
const _padding=Symbol();
const _index=Symbol();
const _open=Symbol();
const _input_state=Symbol();
const _suggestions=Symbol();
const _abort_req=Symbol();
const _aborter=Symbol();
const _get_suggestions=Symbol();

export class Autocompleter {
	constructor(input,delay) {
		this[_delay]=delay;
		this[_wrapper]=input.closest(".autocompleter");
		if (!this[_wrapper])
			throw new Error("Could not find the autocompleter wrapper");
		input.autocomplete="off";
		this[_input]=input;
		//this[_input_state]=[before,after];
		const container=mke();
		this[_dropdown_div]=mke(".suggestions");
		container.append(this[_dropdown_div]);
		input.after(container);

		this[_open]=false;
		this[_index]=-1; // ranges from -1 to this[_suggestions].length-1
		this[_suggestions]=[];
		//this[_aborter]=undefined;
		this[_style_copied]=false;

		input.addEventListener("input",() => {
			this.show_suggestions();
		});
		input.addEventListener("keydown",(ev) => {
			// up & down arrow keys move the cursor, prevent that
			if (ev.keyCode===38 || ev.keyCode===40)
				tx_util.prevent_default(ev);
			else {
				if (ev.keyCode===9 && this[_open])
					tx_util.prevent_default(ev);
				this.close();
			}
		});
		input.addEventListener("keyup",(ev) => {
			const d=ev.keyCode===38 ? -1 : ev.keyCode===40 ? 1 : 0;
			if (d===0) return;
			const l=1+this[_suggestions].length;
			this[_set_focus]((this[_index]+1+d+l)%l-1);
			this[_update_text]();
		});
		input.addEventListener("blur",() => {
			this.close();
		});
		// prevent the loss of focus from input:
		this[_dropdown_div].addEventListener("mousedown",tx_util.prevent_default);
		this[_dropdown_div].addEventListener("touchstart",tx_util.prevent_default);
		const focus_suggestion_from_touch=(ev) => {
			const elt=document.elementFromPoint(ev.touches[0].clientX,ev.touches[0].clientY).closest(".suggestion");
			if (elt) {
				this[_set_focus](elt[_index]);
				this[_update_text]();
			}
		};
		this[_dropdown_div].addEventListener("touchstart",focus_suggestion_from_touch);
		this[_dropdown_div].addEventListener("touchmove",focus_suggestion_from_touch);
		this[_dropdown_div].addEventListener("touchend",() => {
			this.close();
		});
	}

	close() {
		this[_abort_req]();
		if (this[_open]) {
			this[_open]=false;
			this[_dropdown_div].classList.remove("open");
			this[_dropdown_div].innerHTML="";
			this[_suggestions]=[];
			this[_index]=-1;
		}
	}

	[_abort_req]() {
		if (this[_aborter]) {
			this[_aborter].abort();
			this[_aborter]=undefined;
		}
	}

	// update the focused suggestion_div
	[_set_focus](new_index) {
		if (this[_index]===-1)
			this[_input_state]=this[_get_input_state]();
		else
			this[_dropdown_div].children[this[_index]].classList.remove("focused");
		this[_index]=new_index;
		if (new_index>=0)
			this[_dropdown_div].children[this[_index]].classList.add("focused");
	}

	// update the input text with the currently selected item
	[_update_text]() {
		let str,cursor_pos;
		if (this[_index]===-1) {
			// restore the saved state
			str=this[_input_state][0]+this[_input_state][1];
			cursor_pos=this[_input_state][0].length;
		}
		else {
			let s=this[_suggestions][this[_index]];
			str=s.join("");
			cursor_pos=s[0].length+s[1].length;
		}
		this[_input].value=str;
		tx_input_validation.get_value(this[_input]);
		this[_input].setSelectionRange(cursor_pos,cursor_pos);
		this[_input].focus();
	}

	async show_suggestions() {
		this.close();
		let suggestions;
		const cur_aborter=this[_aborter]=new tx_util.Aborter();
		try {
			await tx_util.sleep(this[_delay],cur_aborter);
			let [before,after]=this[_get_input_state]();
			suggestions=await this[_get_suggestions](before,after,cur_aborter);
			if (cur_aborter.aborted)
				return;
		}
		catch (err) {
			if (!(err instanceof tx_util.RequestAborted))
				throw err;
		}
		if (!suggestions || suggestions.length===0) {
			this.close();
			return;
		}
		let start_offset=Infinity;
		let end_offset=0;
		for (const [before,suggested,after] of suggestions) {
			const before_span=mke("span",before);
			const suggested_span=mke("span",suggested);
			const suggestion_div=mke(".suggestion",
				mke(
					before_span,
					suggested_span,
					mke("span",after),
				),
			);
			const this_index=this[_suggestions].length;
			suggestion_div[_index]=this_index;
			suggestion_div.addEventListener("mouseup",() => {
				this[_update_text]();
				this.close();
			});
			suggestion_div.addEventListener("mouseenter",() => {
				this[_set_focus](this_index);
			});
			this[_dropdown_div].append(suggestion_div);
			this[_suggestions].push([before,suggested,after]);
			if (!this[_open]) {
				if (!this[_style_copied]) {
					this[_style_copied]=true;
					const input_style=getComputedStyle(this[_input]);
					for (let name of ["Style","Variant","Weight","Stretch","Size","Family"])
						this[_dropdown_div].style["font"+name]=input_style["font"+name];
					const border_width_l=parseInt(input_style.borderLeftWidth)-1; // subtract 1 for the dropdown div's border
					const border_width_r=parseInt(input_style.borderRightWidth)-1; // subtract 1 for the dropdown div's border
					this[_dropdown_div].style.left=`${border_width_l}px`;
					this[_dropdown_div].style.right=`${border_width_r}px`;
					this[_padding]=`0 ${input_style.paddingRight} 0 ${input_style.paddingLeft}`;
					this[_dropdown_div].style.lineHeight=getComputedStyle(this[_input].parentElement).lineHeight;
				}
				this[_dropdown_div].classList.add("open");
				this[_open]=true;
			}
			suggestion_div.style.padding=this[_padding];
			const start_off=before_span.offsetWidth;
			const end_off=start_off+suggested_span.offsetWidth;
			start_offset=Math.min(start_offset,start_off);
			end_offset=Math.max(end_offset,end_off);
		}

		let margin=-this[_input].scrollLeft;
		margin-=Math.max(0,margin+end_offset-this[_dropdown_div].firstElementChild.firstElementChild.clientWidth);
		margin=Math.max(margin,-start_offset);
		const margin_str=`${margin}px`;
		for (const suggestion_div of this[_dropdown_div].children)
			suggestion_div.firstElementChild.firstElementChild.style.marginLeft=margin_str;
	}

	[_get_input_state]() {
		const j=this[_input].selectionStart;
		const s=this[_input].value;
		return [s.slice(0,j),s.slice(j)];
	}

	async [_get_suggestions]() {
		return [];
	}
}

const _completion_type=Symbol();

export class AutocompleterApi extends Autocompleter {
	constructor(input,delay,type) {
		super(input,delay);
		this[_completion_type]=type;
	}

	async [_get_suggestions](before,after,aborter) {
		return await tx_util.api_request_promise("get_autocomplete_suggestions",this[_completion_type],before,after,aborter);
	}
}

class AutocompleterEntryName extends AutocompleterApi {
	constructor(input,delay) {
		super(input,delay,"entry_name");
	}

	async [_get_suggestions](before,after,aborter) {
		if (before==="[" && after==="") {
			return [
				["[","Χωρίς Όνομα]",after],
				["[","Άγνωστο Όνομα]",after],
			];
		}
		else {
			return await super[_get_suggestions](before,after,aborter);
		}
	}
}

class AutocompleterChainNameId extends AutocompleterApi {
	constructor(input,delay) {
		super(input,delay,"chain_name_id");
	}

	async [_get_suggestions](before,after,aborter) {
		const value=before+after;
		if (!/^\d+$/.test(value))
			return await super[_get_suggestions](before,after,aborter);
		else if (value.length>10)
			return [];
		else {
			const eid=parseInt(value);
			const entries=await tx_util.api_request_promise("get_entries",[{eid,etype:1}],aborter);
			if (entries.length>0)
				return [["",`${entries[0].name} {${eid}}`,""]];
			else
				return [];
		}
	}
}

const _tlatlon_div=Symbol();
const _tlatlon=Symbol();
const _addr_suggestions=Symbol();

class AutocompleterAddress extends Autocompleter {
	constructor(input,delay) {
		super(input,delay);
		this[_tlatlon_div]=input.closest("form").querySelector(".metadata.tlatlon");
		if (!this[_tlatlon_div])
			console.error("Cannot find the tlatlon div");
		this[_tlatlon]={};
		this[_addr_suggestions]={locality:[],road:[]};
		input.addEventListener("focus",() => {
			if (input.value==="")
				this.show_suggestions();
		});
	}

	async [_get_suggestions](before,after,aborter) {
		const tlatlon=JSON.parse(this[_tlatlon_div].textContent);
		if (tlatlon.tlat!==this[_tlatlon].tlat || tlatlon.tlon!==this[_tlatlon].tlon) {
			const suggestions=await tx_util.api_request_promise("get_address_autocomplete_suggestions",tlatlon.tlat,tlatlon.tlon,aborter);
			for (const suggestions_t of Object.values(suggestions)) {
				for (const suggestion of suggestions_t)
					suggestion.name_norm=unaccent_fc(suggestion.name);
			}
			this[_addr_suggestions]=suggestions;
			this[_tlatlon]=tlatlon;
		}
		const is_road_name=!before.includes(",");
		const [before_word,word]=/^(.*?\s*)([^,]*)$/.exec(before).slice(1);
		const word_len=word.length;
		const word_norm=unaccent_fc(word);
		const suggestions=this[_addr_suggestions][is_road_name && this[_addr_suggestions].road.length>0 ? "road" : "locality"];
		const res=[];
		for (const {name,name_norm} of suggestions) {
			if (name_norm.includes(word_norm)) {
				if (!is_road_name && word_len===0 && !/\s$/.test(before_word))
					res.push([before," "+name,after]);
				else if (name.substring(0,word_len)===word)
					res.push([before,name.substring(word_len),after]);
				else
					res.push([before_word,name,after]);
			}
		}
		return res;
	}
}

// SELECT
// 	row_number() OVER (ORDER BY count(*) DESC,comment),
// 	100.0*sum(count(*)) OVER (ORDER BY count(*) DESC, comment RANGE BETWEEN UNBOUNDED PRECEDING AND CURRENT ROW)/sum(count(*)) OVER (),
// 	count(*),
// 	comment
// FROM (
// 	SELECT (regexp_matches(phone,'^[-\d+() ]+(?: \((.+)\))$'))[1] as comment
// 	FROM metadata,regexp_split_to_table(value,'\s*,\s*') AS phone
// 	WHERE field=get_field_id('phone')
// ) t
// WHERE comment IS NOT NULL
// GROUP BY comment
// ORDER BY count
// DESC,comment;

const phone_comment_suggestions=[
	"Cosmote",
	"Vodafone",
	"Wind",
	"sms",
	"Q",
	"delivery",
].map(str => [str, unaccent_fc(str)]);

class AutocompleterPhone extends Autocompleter {
	async [_get_suggestions](before,after,aborter) {
		const is_road_name=!before.includes(",");
		const m=/^(.*\()([^()]*)$/.exec(before);
		if (!m) return;
		const [before_word,word]=m.slice(1);
		const word_len=word.length;
		const word_norm=unaccent_fc(word);
		const res=[];
		for (const [comment,comment_norm] of phone_comment_suggestions) {
			if (comment_norm.startsWith(word_norm)) {
				if (comment.substring(0,word_len)===word)
					res.push([before,comment.substring(word_len)+")",after]);
				else
					res.push([before_word,comment+")",after]);
			}
		}
		return res;
	}
}

export function enable(container) {
	for (const elt of container.querySelectorAll("[data-autocomplete]")) {
		elt.classList.add("autocompleter");
		const input=elt.querySelector("input");
		const type=elt.getAttribute("data-autocomplete");
		if (type==="entry_name")
			new AutocompleterEntryName(input,100);
		else if (type==="store_front" || type==="self_described" || type==="tag")
			new AutocompleterApi(input,100,type);
		else if (type==="address")
			new AutocompleterAddress(input,50);
		else if (type==="phone")
			new AutocompleterPhone(input,50);
		else if (type==="chain_name_id")
			new AutocompleterChainNameId(input,100);
		else if (type!=="")
			console.error("unknown autocompletion type",type);
	}
}
