import * as tx_gallery from "./gallery";
import * as tx_infobox from "./infobox";
import * as tx_image_editor from "./image_editor";
import * as tx_map from "./map";
import * as tx_util from "./util";
import * as tx_history from "./history";
import * as tx_accounts from "./accounts";
import * as tx_cache from "./cache";
import {remove_exif_orientation} from "./exif_orientation";
import {toast} from "./toast";
import {mke} from "./mke";

const overlay_div=document.querySelector("#image-overlay");
const $overlay_div=$(overlay_div);
const $image_area_div=$(".image-area",overlay_div);
const $entry_div=$(".entry",overlay_div);
const $date_elt=$(".info .date time",overlay_div);
const nav_prev_div=$(".navigate.prev",overlay_div)[0];
const nav_next_div=$(".navigate.next",overlay_div)[0];
const share_btn=overlay_div.querySelector(".share-permalink-btn");
const insta_img_div=overlay_div.querySelector(".insta-img");
const insta_img_img=insta_img_div.querySelector(".insta-img img");

const scale_step=1.1;
const log_scale_step=Math.log(scale_step);
var zoom_level;
var tran_x;
var tran_y;

let state; // {collection:???,idx:???,key:???}
const state_cache=new tx_cache.Cache();

const image_collection=Symbol("image_collection");
const image_idx=Symbol("image_idx");

const placeholder_data_uri=
	"data:image/svg+xml,%3Csvg width='82' height='66' viewBox='0 0 21.695834 17.4625'"+
	" xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='.125' transform='translate(-"+
	"82.13 -124.2)'%3E%3Cg fill='none' stroke='white' stroke-linejoin='round' stroke-"+
	"width='2.117'%3E%3Crect x='83.46' y='125.6' width='19.05' height='14.82' rx='2.1"+
	"17' ry='2.117' color='black' stroke-linecap='round'/%3E%3Cpath d='M83.74 139.5l3"+
	".837-3.59 5.437 4.433M89.78 137.6l5.833-3.678 6.81 3.975'/%3E%3C/g%3E%3Ccircle c"+
	"x='90.74' cy='130.1' r='2.117' color='black' fill='white'/%3E%3C/g%3E%3C/svg%3E";

var ontransitionend=
	!("transition" in document.createElement("div").style)
		? function(node,cb) {setTimeout(cb,1)}
		: function(node,cb) {
			var done=false;
			node.addEventListener("transitionend",function(ev) {
				if (done || ev.target!==node) return;
				done=true;
				cb();
			});
		};


function get_title() {
	return 'Εικόνα από "'+state.collection.get_entry(state.idx).name+'"';
}

function update_doc_title() {
	if (state) {
		const title=get_title();
		let meta_desc;
		const caption=state.collection.get_image(state.idx).caption_html;
		if (!caption)
			meta_desc=title;
		else {
			const temp_div=mke();
			temp_div.innerHTML=caption;
			// innerText might have been more appropriate here but is slower and textContent
			// should be equivalent for this case
			meta_desc=temp_div.textContent;
		}
		tx_history.set_title(1,title,meta_desc);
	}
}

function disable_nav_buttons() {
	nav_prev_div.removeAttribute("href");
	nav_next_div.removeAttribute("href");
}

function add_image(idx,className,preview_elt) {
	let preview_is_placeholder;
	if (!preview_elt) {
		preview_is_placeholder=true;
		preview_elt=new Image();
		preview_elt.src=placeholder_data_uri;
	}
	let full_img=new Image();
	let $preview_div=$("<div>").addClass("img").append(preview_elt);
	let $full_div=$("<div>").addClass("img full").css("opacity","0").append(full_img);
	full_img.addEventListener("load",() => {
		if ((tx_util.ie_version||12)<12) {
			const full_resolution=state.collection.get_image(idx).resolution.l;
			const rect=tx_util.fit_rect($image_area_div[0].clientWidth,$image_area_div[0].clientHeight,full_resolution[0],full_resolution[1]);
			full_img.style.width=rect.width+"px";
			full_img.style.height=rect.height+"px";
		}
		$full_div.css("opacity","");
		setTimeout(function() {
			$preview_div.remove();
		},1100);
	},{once:true});
	full_img.src=state.collection.get_image_url(idx);
	if (className==="current")
		insta_img_img.src=full_img.src;
	let $box_div=$("<div>").addClass("box").append($preview_div,$full_div);
	if ((tx_util.ie_version||12)<12) {
		if (preview_is_placeholder) {
			preview_elt.style.width ="82px";
			preview_elt.style.height="66px";
		}
		else {
			const full_resolution=state.collection.get_image(idx).resolution.l;
			const rect=tx_util.fit_rect($image_area_div[0].clientWidth,$image_area_div[0].clientHeight,full_resolution[0],full_resolution[1]);
			preview_elt.style.width =rect.width +"px";
			preview_elt.style.height=rect.height+"px";
		}
	}
	let image_div=$("<div>").addClass("image").addClass(className).append($box_div).appendTo($image_area_div)[0];
	image_div[image_collection]=state.collection;
	image_div[image_idx]=idx;
	return image_div;
}

function on_found_next_prev(this_state,idx,nav_div,className) {
	if (state===this_state && idx!==undefined) {
		var $existing_div=$(".image."+className,$image_area_div);
		if ($existing_div[0] && $existing_div[0][image_collection]===state.collection && $existing_div[0][image_idx]===idx) {
			// do nothing
		}
		else {
			$existing_div.remove();
			add_image(idx,className);
		}
		nav_div.href=state.collection.get_permalink(idx);
	}
}

function check_prev_next() {
	var this_state=state;
	state.collection.find_previous_image(state.idx,function(idx) {
		on_found_next_prev(this_state,idx,nav_prev_div,"previous");
	});
	state.collection.find_next_image(state.idx,function(idx) {
		on_found_next_prev(this_state,idx,nav_next_div,"next");
	});
}

function update_info() {
	var image=state.collection.get_image(state.idx);
	var entry=state.collection.get_entry(state.idx);
	share_btn.href=state.collection.get_permalink(state.idx);
	share_btn._title=get_title();
	var entry_page=(entry.etype===0 ? "t" : "c")+entry.eid;
	$entry_div
		.toggleClass("chain",entry.etype!==0)
		.toggleClass("closed",!!entry.closed)
		.toggleClass("not_open_yet",entry.closed==="not_open_yet")
		.toggleClass("stopped_food",entry.closed==="stopped_food")
		.toggleClass("moved",!!entry.closed && !!entry.moved_to);
	$("[data-entry]",overlay_div).off("click").click(() => {
		tx_infobox.browse(entry_page);
		return false;
	})
	.attr("data-entry",entry.name+(entry.etype!==0 ? " [αλυσίδα]" : ""))
	.prop("href","?p="+entry_page);
	$(".map",$entry_div)[0].onclick=() => {
		hide_forward();
		tx_map.show_on_map(entry.lat,entry.lon);
	};
	$("input",$entry_div).val(entry_page);
	$(".categories",$entry_div).text(entry.categories.join(", "));
	let prefecture=entry?.prefecture ?? "";
	if (prefecture && !prefecture.includes("[") && prefecture!=="Άγιο Όρος")
		prefecture="Νομός "+prefecture;
	$(".prefecture",$entry_div).text(prefecture);
	$(".address",$entry_div).text(entry.address||"");
	$(".sidebar .caption > span, .image-pane .caption",overlay_div).html(image.caption_html);
	$('.sidebar [name="caption"]',overlay_div).val(image.caption);
	$(".bbcode input",overlay_div).prop("checked",image.bbcode);
	$("[data-username]",overlay_div).off("click").click(() => {
		if (image.user)
			tx_infobox.browse("u"+image.user.id,"img");
		return false;
	}).attr("data-username",(image.user||{}).name||"").prop("href",image.user ? "?p=u"+image.user.id+"&t=img" : "");
	$(".sidebar .info .user > input",overlay_div).val((image.user||{}).id||"");
	$(".foodmenu input",overlay_div).prop("checked",image.foodmenu);
	$('.sidebar [name="approval"]',overlay_div).val(image.approval|0);
	overlay_div.classList.remove("setting_approval");
	$(".moderation .disabled",overlay_div).removeClass("disabled");
	tx_util.set_elt_approval(overlay_div,image.approval);
	const date=new Date(image.created*1000);
	const year=date.getFullYear();
	const month=(date.getMonth()+1)+"";
	const day=date.getDate()+"";
	$date_elt.text(day+"/"+month+"/"+year).attr("datetime",year+"-"+month.padStart(2,"0")+"-"+day.padStart(2,"0"));
	$(insta_img_div).off("click contextmenu").on("click contextmenu",() => {
		let p;
		if (!navigator.clipboard)
			p=Promise.reject();
		else {
			const text=(image.caption ? '"'+image.caption+'" α' : "Α")+'πό το κατάστημα "'+entry.name+'" #tavernoxoros';
			p=navigator.clipboard.writeText(text);
		}
		p.then(() => {toast("Επιτυχής αντιγραφή")},() => {toast("Η αντιγραφή απέτυχε")});
	});
}

function update_image(thumb_img_cntnr) {
	reset_transform();
	update_info();
	state_cache.set(state.key,state);
	disable_nav_buttons();
	var image=state.collection.get_image(state.idx);
	var thumb_img=thumb_img_cntnr && thumb_img_cntnr.querySelector("img");
	if (!thumb_img) {
		var $curr_div=$(".image.current",$image_area_div);
		var $prev_div=$(".image.previous",$image_area_div);
		var $next_div=$(".image.next",$image_area_div);
		var curr_was_moved;
		if ($prev_div[0] && $prev_div[0][image_collection]===state.collection && $prev_div[0][image_idx]===state.idx) {
			$prev_div.removeClass("previous").addClass("current");
			$curr_div.removeClass("current").addClass("next");
			$next_div.remove();
			curr_was_moved=true;
		}
		else if ($next_div[0] && $next_div[0][image_collection]===state.collection && $next_div[0][image_idx]===state.idx) {
			$next_div.removeClass("next").addClass("current");
			$curr_div.removeClass("current").addClass("previous");
			$prev_div.remove();
			curr_was_moved=true;
		}
		else if ($curr_div[0] && $curr_div[0][image_collection]===state.collection && $curr_div[0][image_idx]===state.idx) {
			// do nothing
		}
		else {
			$image_area_div.empty();
			add_image(state.idx,"current");
			$overlay_div.removeClass("editing submitting").addClass("visible").show().focus();
		}
		if (curr_was_moved && $curr_div[0])
			setTimeout(function() {
				$curr_div[0].querySelector(".box").style.transform="";
			},220);
	}
	else {
		$image_area_div.empty();
		// show the overlay first, so that we can obtain the final size of $image_area_div:
		$overlay_div.removeClass("visible editing submitting").show().focus();

		// find the transform needed to match the bounds of the thumbnail
		var full_image_width=image.resolution.l[0];
		var full_image_height=image.resolution.l[1];
		var $thumb_img_cntnr=$(thumb_img_cntnr);
		var thumb_img_cntnr_width=$thumb_img_cntnr.width()
		var thumb_img_cntnr_height=$thumb_img_cntnr.height()
		var image_area_width=$image_area_div.width()
		var image_area_height=$image_area_div.height()
		var thumb_img_cntnr_offset=$thumb_img_cntnr.offset();
		var image_area_offset=$image_area_div.offset();
		var scale_init=tx_util.fit_rect(thumb_img_cntnr_width,thumb_img_cntnr_height,full_image_width,full_image_height,true).scale;
		var scale_final=tx_util.fit_rect(image_area_width,image_area_height,full_image_width,full_image_height,false).scale;
		var translate_x=(thumb_img_cntnr_offset.left+thumb_img_cntnr_width/2)-(image_area_offset.left+image_area_width/2);
		var translate_y=(thumb_img_cntnr_offset.top+thumb_img_cntnr_height/2)-(image_area_offset.top+image_area_height/2);
		var transform="translate("+translate_x+"px,"+translate_y+"px)scale("+scale_init/scale_final+")";

		var preview_canvas=document.createElement("canvas");
		preview_canvas.width=full_image_width;
		preview_canvas.height=full_image_height;
		var canvas_ctx=preview_canvas.getContext("2d");
		canvas_ctx.drawImage(thumb_img,0,0,full_image_width,full_image_height);
		var $image_div=$(add_image(state.idx,"current",preview_canvas)).css("transform",transform);
		$image_div.css("transform"); //force a value before the transition
		$overlay_div.addClass("visible");
		$image_div.css("transform","");
	}
	check_prev_next();
}

export function show(collection,idx,thumb_img_cntnr) {
	if (state!==undefined) thumb_img_cntnr=undefined;
	state={
		collection:collection,
		idx:idx,
		key:state_cache.new_key(),
	};
	tx_history.push_history(state.collection.get_url_params(state.idx),{img:state.key});
	update_image(thumb_img_cntnr);
	update_doc_title();
}

export function show_by_name(name) {
	tx_gallery.collection_from_name(name,null,(collection,idx) => {
		show(collection,idx);
	});
}

export function hide() {
	state=undefined;
	$overlay_div.hide().removeClass("visible");
	$image_area_div.empty();
}

function hide_forward() {
	if (state) {
		tx_history.push_history({i:undefined,ic:undefined},{img:undefined});
		tx_history.set_title(1,"","");
		hide();
	}
}

function close() {
	if (state) tx_history.back();
}

function prev_or_next(prev) {
	return new Promise((resolve,reject) => {
		if (!state) return reject();
		disable_nav_buttons();
		var this_state=state;
		var method=prev ? state.collection.find_previous_image : state.collection.find_next_image;
		method.call(state.collection,state.idx,function(idx,image) {
			if (state!==this_state); // do nothing
			else if (idx===undefined)
				check_prev_next();
			else {
				state={
					collection:state.collection,
					idx:idx,
					key:state_cache.new_key(),
				};
				tx_history.replace_history(state.collection.get_url_params(state.idx),{img:state.key});
				update_image();
				update_doc_title();
			}
			resolve();
		});
	});
}

export function previous() {
	return nav_prev_div.href ? prev_or_next(true) : Promise.resolve();
}

export function next() {
	return nav_next_div.href ? prev_or_next(false) : Promise.resolve();
}

export function on_history(params,global_state) {
	if (params.i==null)
		hide();
	else if (global_state.img!=null && state_cache.has(global_state.img)) {
		const cached_state=state_cache.get(global_state.img);
		if (!cached_state.collection.get_image(cached_state.idx)) {
			tx_history.replace_history({i:undefined,ic:undefined},{img:undefined});
			toast("Η εικόνα δεν υπάρχει");
		}
		else {
			state=cached_state;
			update_image();
		}
	}
	else {
		let global_state_img=(global_state||{}).img;
		if (global_state_img==null) {
			global_state_img=state_cache.new_key();
			tx_history.amend_state({img:global_state_img});
		}
		tx_gallery.collection_from_name(params.i,params.ic,(collection,idx,image) => {
			state={
				collection:collection,
				idx:idx,
				key:global_state_img,
			};
			update_image();
			update_doc_title();
		},() => {
			tx_history.replace_history({i:undefined,ic:undefined},{img:undefined});
		});
	}
}

function reset_transform() {
	zoom_level=0;
	tran_x=0;
	tran_y=0;
}

function transform(dz,pageX,pageY,dx,dy) {
	let previous_zoom_level=zoom_level;
	zoom_level=Math.min(20,Math.max(0,zoom_level+dz));
	let scale=Math.pow(scale_step,zoom_level);
	let this_scale=Math.pow(scale_step,zoom_level-previous_zoom_level);
	let image_area_rect=$image_area_div[0].getBoundingClientRect();
	let full_resolution=state.collection.get_image(state.idx).resolution.l;
	let image_rect=tx_util.fit_rect(image_area_rect.width,image_area_rect.height,full_resolution[0],full_resolution[1]);
	let off_x=image_rect.left/image_area_rect.width;
	let off_y=image_rect.top/image_area_rect.height;
	let min_tran_x=scale*off_x;
	let max_tran_x=scale*(1-off_x)-1;
	let min_tran_y=scale*off_y;
	let max_tran_y=scale*(1-off_y)-1;
	if (min_tran_x>max_tran_x)
		min_tran_x=max_tran_x=(scale-1)/2;
	if (min_tran_y>max_tran_y)
		min_tran_y=max_tran_y=(scale-1)/2;
	let new_tran_x=this_scale*tran_x+(this_scale-1)*(pageX-image_area_rect.left)/image_area_rect.width-dx/image_area_rect.width;
	tran_x=Math.max(min_tran_x,Math.min(max_tran_x,new_tran_x));
	tran_y=Math.max(min_tran_y,Math.min(max_tran_y,
		this_scale*tran_y+(this_scale-1)*(pageY-image_area_rect.top)/image_area_rect.height-dy/image_area_rect.height
	));
	$image_area_div[0].querySelector(".image.current > .box").style.transform=
		"translate("+(-100*tran_x)+"%,"+(-100*tran_y)+"%)scale("+scale+")";
	return tran_x-new_tran_x;
}

function pinch_start(ev) {
	$overlay_div.addClass("dragging");
	const data=ev.data;
	data.p=$image_area_div[0].querySelector(".image.previous");
	data.n=$image_area_div[0].querySelector(".image.next");
	data.c=$image_area_div[0].querySelector(".image.current");
	data.ts=[ev.event.timeStamp];
	data.es=[0];
}

function pinch_update(ev) {
	const data=ev.data;
	const image_area_width=$image_area_div[0].clientWidth;
	let dx=ev.dx;
	const old_excess=data.es[0];
	const old_excess_px=-old_excess*image_area_width;
	const de=
		dx>0 && old_excess<0 ? Math.min(dx,old_excess_px) :
		dx<0 && old_excess>0 ? Math.max(dx,old_excess_px) :
		0;
	dx-=de;
	const excess_x=transform(Math.log(ev.scale||1)/log_scale_step,ev.pageX,ev.pageY,dx,ev.dy);
	const new_excess=old_excess+de/image_area_width+excess_x;
	const offset=100*new_excess;
	data.c.style.left=offset+"%";
	if (data.p) data.p.style.left=(offset-100)+"%";
	if (data.n) data.n.style.left=(offset+100)+"%";
	data.ts.splice(4);
	data.es.splice(4);
	data.ts.unshift(ev.event.timeStamp);
	data.es.unshift(new_excess);
}

function pinch_end(ev) {
	$overlay_div.removeClass("dragging");
	const data=ev.data;
	const excess=data.es[0];
	const space_left=1-Math.abs(excess);
	const n=data.ts.length-1;
	const dur=(0.75*0.58*space_left*(data.ts[0]-data.ts[n])/Math.abs(excess-data.es[n]))|0;
	let dur_s;
	if (Math.abs(excess)>0.2 && dur<200 && dur>0) {
		dur_s=dur+"ms";
		data.c.style.transitionDuration=dur_s;
		getComputedStyle(data.c).transitionDuration;
	}
	data.c.style.left="";
	if (data.p) {
		if (dur_s) {
			data.p.style.transitionDuration=dur_s;
			getComputedStyle(data.p).transitionDuration;
		}
		data.p.style.left="";
	}
	if (data.n) {
		if (dur_s) {
			data.n.style.transitionDuration=dur_s;
			getComputedStyle(data.n).transitionDuration;
		}
		data.n.style.left="";
	}
	let done=
		excess<-0.2 ? next() :
		excess>0.2 ? previous() :
			Promise.resolve();
	if (dur_s) {
		const restore=() => {
			setTimeout(() => {
				data.c.style.transitionDuration="";
				if (data.p) data.p.style.transitionDuration="";
				if (data.n) data.n.style.transitionDuration="";
			},10);
		};
		done.then(restore,restore);
	}
}

function drag_start(ev) {
	if (ev.event.which!==1) return false;
	ev.event.preventDefault();
	$overlay_div.addClass("dragging");
}

function drag_move(ev) {
	transform(0,0,0,ev.dx,ev.dy);
}

function drag_end(ev) {
	$overlay_div.removeClass("dragging");
}

export function edit() {
	if (!state || !tx_accounts.can("edit_images") || $overlay_div.hasClass("editing")) return;
	$("input.onediting",overlay_div).prop("disabled",false);
	$overlay_div.addClass("editing");
	$(".sidebar .caption > input",overlay_div).focus()[0].select();
}

export function cancel_edit() {
	$overlay_div.removeClass("editing submitting");
	$overlay_div.focus();
}

export function submit_edit() {
	if (!state || !$overlay_div.hasClass("editing")) return;
	var edit_entry=$("input",$entry_div).val();
	var edit_entry_matches=/^([tc])(\d+)$/.exec(edit_entry);
	if (!edit_entry_matches) {
		toast("Λανθασμένη μορφή εγγραφής");
		return;
	}
	var edit_user=$(".sidebar .info .user > input",$overlay_div).val();
	if (edit_user.length && !/^\d{1,6}$/.test(edit_user)) {
		toast("Το id του χρήστη πρέπει να είναι ακέραιος αριθμός ή κενό");
		return;
	}
	var edit={
		caption:$(".sidebar .caption > input",$overlay_div).val()||null,
		bbcode:$(".bbcode input",$overlay_div).prop("checked"),
		foodmenu:$(".foodmenu input",$overlay_div).prop("checked"),
		entry:{eid:parseInt(edit_entry_matches[2]),etype:edit_entry_matches[1]==="t" ? 0 : 1},
		user:edit_user.length ? {id:parseInt(edit_user)} : null,
		approval:$('.sidebar [name="approval"]',overlay_div).val()|0,
	};
	$overlay_div.addClass("submitting");
	$("input.onediting",$overlay_div).prop("disabled",true);
	var this_state=state;
	var image=state.collection.get_image(state.idx);
	tx_util.api_request("set_image",image.name,edit,function(res,err) {
		if (err) {
			if (state===this_state) {
				$overlay_div.removeClass("submitting");
				$("input.onediting",$overlay_div).prop("disabled",false);
			}
			if (err.code===20)
				toast("Η εικόνα δεν υπάρχει");
			else
				toast("Η αλλαγή των στοιχείων της εικόνας απέτυχε\n"+JSON.stringify(err),Infinity);
		}
		else {
			image.caption=res.caption;
			image.caption_html=res.caption_html;
			image.bbcode=res.bbcode;
			image.foodmenu=res.foodmenu;
			image.entry=res.entry;
			image.user=res.user;
			image.approval=res.approval;
			if (image._updated)
				image._updated();
			if (state.collection===this_state.collection && state.idx===this_state.idx)
				update_info();
			if (state===this_state) {
				$overlay_div.removeClass("editing submitting").focus();
			}
		}
	});
}

export function delete_image() {
	if (!state || !confirm("Διαγραφή εικόνας;")) return;
	var this_state=state;
	tx_util.api_request("delete_image",state.collection.get_image(state.idx).name,function(res,err) {
		if (err) {
			if (err.code===20)
				toast("Η εικόνα δεν υπάρχει");
			else
				toast("Η διαγραφή της εικόνας απέτυχε\n"+JSON.stringify(err),Infinity);
			return;
		}
		this_state.collection.remove_image(this_state.idx);
		if (state===this_state) {
			if (nav_next_div.href)
				next();
			else if (nav_prev_div.href)
				previous();
			else
				close();
		}
	});
}

export function edit_ops() {
	if (!state || !tx_accounts.can("edit_images") || $overlay_div.hasClass("editing")) return;
	const this_state=state;
	const image=state.collection.get_image(state.idx);
	const name=image.name;
	overlay_div.querySelector(".edit-ops").classList.add("busy");
	const done=() => {
		overlay_div.querySelector(".edit-ops").classList.remove("busy");
	};
	tx_util.api_request_promise("get_image_operations",name).then(ops => {
		tx_util.http_request("POST","action/edit_image",$.param({name}),"application/x-www-form-urlencoded",(resp,status) => {
			if (status!==200) {
				toast(status===404 ? "Η εικόνα δεν βρέθηκε στον server" : `Σφάλμα ${status}`);
				done();
				return;
			}
			const operations=new tx_image_editor.ImageOperations();
			try {
				operations.appendFromJSON(ops);
			}
			catch (err) {
				toast("Could not parse operations: "+err.toString());
				done();
				return;
			}
			const get_obj_url=remove_exif_orientation(resp).then(blob => URL.createObjectURL(blob));
			get_obj_url.then(tx_util.load_image).then(img =>
				tx_image_editor.edit_image(img,operations)
			).then(modified => {
				if (modified) {
					return tx_util.api_request_promise("set_image_operations",name,operations.toJSON()).then(res => {
						Object.assign(image.resolution,res);
						image._edited();
						image._updated();
						const url=this_state.collection.get_image_url(this_state.idx);
						for (const image_div of overlay_div.querySelectorAll(".image-pane .image")) {
							if (image_div[image_collection]===this_state.collection && image_div[image_idx]===this_state.idx) {
								return new Promise(resolve => {
									const img=image_div.querySelector(".img.full img");
									img.addEventListener("load",() => {
										resolve();
									},{once:true});
									img.src=url;
								});
							}
						}
					},err => {
						toast("Απέτυχε: "+JSON.stringify(err));
					});
				}
			},() => {
				toast("Cannot load image");
			}).finally(() => {
				get_obj_url.then(obj_url => {
					URL.revokeObjectURL(obj_url);
				});
				done();
			});
		},xhr => {
			xhr.responseType="blob";
			xhr.timeout=120000;
		});
	},err => {
		done();
		toast("Απέτυχε: "+JSON.stringify(err));
	});
}

function set_approval(approval) {
	if (!state) return;
	const this_state=state;
	$(".moderation > span",overlay_div).addClass("disabled");
	const image=state.collection.get_image(state.idx);
	tx_util.api_request("set_image_approval",image.name,approval,function(res,err) {
		if (err) {
			if (err.code===20)
				toast("Η εικόνα δεν υπάρχει");
			else
				toast("Η αλλαγή απέτυχε\n"+JSON.stringify(err),Infinity);
			return;
		}
		image.approval=approval;
		image._updated();
		if (state===this_state)
			update_info();
	});
}

function overlay_keydown(ev) {
	if (ev.keyCode===27 && $overlay_div.hasClass("editing")) cancel_edit();
	else if (ev.target.nodeType===1 && ev.target.tagName==="INPUT");
	else if (ev.keyCode===39 || ev.keyCode===32) next();
	else if (ev.keyCode===37) previous();
	else if (ev.keyCode===27) close();
	else if (ev.keyCode===113) edit();
}

export function init() {
	$overlay_div.click(function(ev) {
		if (ev.target===overlay_div && state)
			close();
	}).keydown(overlay_keydown);
	$(".close-btn",overlay_div).click(close);
	$(".toggle-sidebar-btn",overlay_div).click(function() {
		$overlay_div.toggleClass("force-sidebar");
	});
	$(".sidebar form.edit-form",overlay_div).submit(function() {
		submit_edit();
		return false;
	});
	$(".btn-edit",overlay_div).click(edit);
	$(".btn-submit-edit",overlay_div).click(submit_edit);
	$(".btn-cancel-edit",overlay_div).click(cancel_edit);
	$(".btn-delete",overlay_div).click(delete_image);
	$(".edit-ops .btn-a",overlay_div).click(edit_ops);
	$(".btn-approve",overlay_div).click(() => {set_approval(1)});
	$(".btn-reject",overlay_div).click(() => {set_approval(-1)});
	nav_prev_div.addEventListener("click",previous);
	nav_next_div.addEventListener("click",next);

	const image_pane_div=overlay_div.querySelector(".image-pane");
	const image_pane_user_a=image_pane_div.querySelector(".info .user a");
	reset_transform();
	image_pane_div.addEventListener("wheel",function(ev) {
		var dz=ev.deltaY<0 ? 1 : ev.deltaY>0 ? -1 : 0;
		if (dz) {
			transform(dz,ev.pageX,ev.pageY,0,0);	
			ev.preventDefault();
		}
	});
	image_pane_div.addEventListener("touchstart",ev => {
		if (!ev.target.matches(".info a,.info a *"))
			tx_util.prevent_default(ev);
	},true);
	tx_util.listen_for_pinch(image_pane_div,{},pinch_start,pinch_update,pinch_end);
	tx_util.make_draggable($image_area_div[0],{passive:false,cursor:"move",touch:false},drag_start,drag_move,drag_end);
	addEventListener("resize",function(ev) {
		if (state!==undefined) transform(0,0,0,0,0);
	});
}
