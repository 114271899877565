const non_spacing_marks = /\p{Mn}/ug;

const normalize=
	String.prototype.normalize
		? (str,form) => str.normalize(form)
		: (str,form) => str;


function unaccent(str) {
	str=normalize(normalize(str,"NFKD").replace(non_spacing_marks,""),"NFKC");
	return [...str].map(c => "ilLoO"["ıłŁøØ".indexOf(c)] || c).join("");
}

// returns fc(unaccent(str))
export function unaccent_fc(str) {
	str=normalize(normalize(str,"NFKD").replace(non_spacing_marks,""),"NFKC").toLowerCase().replace(/ß/g,"ss");
	return [...str].map(c => "ilLoOσσ"["ıłŁøØςϲ".indexOf(c)] || c).join("");
}
