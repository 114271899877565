var style=document.createElement("div").style;
var transform;
var transformOrigin;
$.each(["transform","MozTransform","msTransform","WebkitTransform","OTransform"],function(i,p) {
	if (p in style) {
		transform=p;
		return false;
	}
});
if (transform) {
	$.support.css3Transform=true;
	transformOrigin=transform+"Origin";
}
function apply_tranform(elem) {
	var s="";
	var scale=$.data(elem,"css3Scale");
	if (scale!=null /* && scale!==1 */) s+="scale("+scale+")";
	var rotate=$.data(elem,"css3Rotate");
	if (rotate) s+="rotate("+rotate+"deg)";
	elem.style[transform]=s;
}
$.cssHooks.transformOrigin={
	get:function(elem,computed) {
		return transform && ($.data(elem,"css3TransformOrigin") || "");
	},
	set:function(elem,value) {
		if (!transform) return;
		$.data(elem,"css3TransformOrigin",value);
		elem.style[transformOrigin]=value;
	}
};
$.cssHooks.scale={
	get:function(elem,computed) {
		return transform && ($.data(elem,"css3Scale") || 1)+"";
	},
	set:function(elem,value) {
		if (!transform) return;
		$.data(elem,"css3Scale",parseFloat(value));
		apply_tranform(elem);
	}
};
$.fx.step.scale=function(fx) {
	$.cssHooks.scale.set(fx.elem,fx.now);
};
$.cssHooks.rotate={
	get:function(elem,computed) {
		return transform && ($.data(elem,"css3Rotate") || 0)+"deg";
	},
	set:function(elem,value) {
		if (!transform) return;
		$.data(elem,"css3Rotate",parseFloat(value));
		apply_tranform(elem);
	}
};
$.fx.step.rotate=function(fx) {
	$.cssHooks.rotate.set(fx.elem,fx.now);
};
