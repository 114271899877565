import {toast} from "./toast";

function copy(text) {
	const text_area=document.createElement("textarea");
	text_area.style.opacity="0"; /////////////////////////////////////////
	text_area.value=text;
	document.body.appendChild(text_area);
	text_area.select();
	let success=false;
	try {
		success=document.execCommand('copy');
	}
	catch (err) {}
	document.body.removeChild(text_area);
	return success;
}

export function init() {
	for (const a of document.querySelectorAll(".share-permalink-btn")) {
		a.addEventListener("click",ev => {
			ev.preventDefault();
			if (a.href) {
				if (navigator.share) {
					const data={url:a.href};
					if (a._title) data.title=a._title+" • ταβερνοχώρος";
					navigator.share(data);
				}
				else if (copy(a.href))
					toast("Ο σύνδεσμος αντιγράφηκε",undefined,a.href);
				else
					toast('Κάνετε δεξί κλικ στο "share" και επιλέξτε "αντιγραφή συνδέσμου"/"copy link"',7000,a.href);
			}
		});
	}
}
