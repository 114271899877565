import * as tx_util from "./util";
import {load_gmaps} from "./gmaps_loader";
import * as tx_accounts from "./accounts";

let street_view_service;
let running;

function check_street_view_images() {
	running=tx_accounts.can("edit_entries");
	if (running)
		tx_util.api_request("get_street_view_queue",check_street_view_images_loop);
}

function check_street_view_images_loop(entries) {
	if (entries && entries.length) {
		const entry=entries.shift();
		load_gmaps().then(() => {
			if (!street_view_service)
				street_view_service=new google.maps.StreetViewService();
			street_view_service.getPanorama({pano:entry.pano_id},(data,status) => {
				let value;
				if (status===google.maps.StreetViewStatus.OK) {
					value=data.imageDate;
					if (value===null) value=undefined;
				}
				else if (status===google.maps.StreetViewStatus.ZERO_RESULTS) {
					value=null;
				}
				if (value!==undefined)
					tx_util.api_request("set_street_view_status",entry.eid,entry.etype,value,null);
				setTimeout(() => {check_street_view_images_loop(entries)},20000);
			});
		});
	}
	else {
		if (tx_accounts.can("edit_entries"))
			setTimeout(check_street_view_images,61*60*1000);
		else
			running=false;
	}
}

export function init() {
	setTimeout(() => {
		check_street_view_images();
		tx_accounts.on_auth_change(() => {
			if (!running) check_street_view_images();
		});
	},300*1000);
}
