import * as tx_util from "./util";
import AwaitLock from "await-lock";

export const supports_recording=!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.MediaRecorder);

export class StateError extends Error {}

export class AudioRecorder {
	// state is one of: "idle", "recording", "paused", "stopped", "error"

	// the constructor is supposed to be private
	constructor(stream,on_state_changed,on_error,on_time_updated) {
		this._lock=new AwaitLock();
		this._on_state_changed=on_state_changed||tx_util.do_nothing;
		this._on_time_updated=on_time_updated||tx_util.do_nothing;
		this._prev_seg_dur=0;
		this._cur_seg_start=undefined;
		this._time_interval=0;
		let data_resolve,data_reject;
		this._data=new Promise((resolve,reject) => {
			data_resolve=resolve;
			data_reject=reject;
		});
		const media_recorder=new MediaRecorder(stream,{
			mimeType:"audio/webm;codecs=opus",
			audioBitsPerSecond:24000,
		});
		this._media_recorder=media_recorder;
		let blob;
		media_recorder.addEventListener("dataavailable",(ev) => {
			blob=ev.data;
		});
		media_recorder.addEventListener("error",(ev) => {
			this._stop_time();
			for (const track of stream.getTracks())
				track.stop();
			this._set_state("error");
			if (on_error)
				on_error(ev.error);
			data_reject(ev.error);
		});
		media_recorder.addEventListener("stop",() => {
			this._stop_time();
			for (const track of stream.getTracks())
				track.stop();
			data_resolve(blob);
		});
		media_recorder.addEventListener("start",() => {
			this._start_time();
		});
		media_recorder.addEventListener("resume",() => {
			this._start_time();
		});
		media_recorder.addEventListener("pause",() => {
			this._stop_time();
		});
		this._state="idle";
	}

	static async init(on_state_changed,on_error,on_time_updated) {
		const stream=await navigator.mediaDevices.getUserMedia({
			audio:{
				channelCount:1,
				sampleRate:48000,
				autoGainControl:true,
			},
		});
		return new this(stream,on_state_changed,on_error,on_time_updated);
	}

	_start_time() {
		if (this._cur_seg_start===undefined) {
			this._cur_seg_start=performance.now();
			this._time_interval=setInterval(this._on_time_updated,1000/20);
			this._on_time_updated.call();
		}
	}

	_stop_time() {
		if (this._cur_seg_start!==undefined) {
			this._prev_seg_dur+=performance.now()-this._cur_seg_start;
			this._cur_seg_start=undefined;
			clearInterval(this._time_interval);
			this._time_interval=0;
			this._on_time_updated.call();
		}
	}

	get time() {
		return this._prev_seg_dur+(this._cur_seg_start===undefined ? 0 : performance.now()-this._cur_seg_start);
	}

	get state() {
		return this._state;
	}

	_set_state(state) {
		this._state=state;
		setTimeout(() => {
			this._on_state_changed(state);
		});
	}

	async _call_method(method_name,acceptable_states,new_state) {
		await this._lock.acquireAsync();
		try {
			if (!acceptable_states.includes(this._state))
				throw new StateError();
			const p=tx_util.wait_for_events(this._media_recorder,method_name,"error");
			this._media_recorder[method_name]();
			const [ev_type,ev]=await p;
			if (ev_type==="error")
				throw ev.error;
			this._set_state(new_state);
		}
		finally {
			this._lock.release();
		}
	}

	async start() {
		await this._call_method("start",["idle"],"recording");
	}

	async resume() {
		await this._call_method("resume",["paused"],"recording");
	}

	async pause() {
		await this._call_method("pause",["recording"],"paused");
	}

	async stop() {
		await this._call_method("stop",["recording","paused"],"stopped");
	}

	async get_data() {
		return await this._data;
	}
}
