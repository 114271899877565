function blob_read(blob,length,offset=0) {
	return new Promise((resolve,reject) => {
		if (length!==undefined)
			blob=blob.slice(offset,offset+length);
		const reader=new FileReader();
		reader.onload=ev => {
			resolve(reader.result);
		};
		reader.onerror=() => {
			reject(reader.error);
		};
		reader.readAsArrayBuffer(blob);
	});
}

export function get_exif_orientation(blob) {
	return new Promise(resolve => {
		let offset=0;
		(function loop() {
			blob_read(blob,4,offset).then(marker_buf_ => {
				const marker_buf=new DataView(marker_buf_);
				if (marker_buf.byteLength<2) return resolve();
				const marker=marker_buf.getUint16(0,false);
				if ((offset===0 && marker!==0xffd8) || marker===0xffd9 || (marker&0xff00)!==0xff00 || marker_buf.byteLength<4)
					return resolve();
				if (marker===0xffd8) {
					offset+=2;
					return loop();
				}
				offset+=4;
				const data_len=marker_buf.getUint16(2,false)-2;
				if (marker!==0xffe1) {
					offset+=data_len;
					return loop();
				}
				else {
					blob_read(blob,data_len,offset).then(data_ab => {
						const data=new DataView(data_ab);
						if (data.byteLength<data_len) return resolve();
						if (data_len>=14 && data.getUint32(0,false)===0x45786966) {
							const little_endian=data.getUint16(6,false)===0x4949;
							let off=6+data.getUint32(10,little_endian);
							try {
								const count=data.getUint16(off,little_endian);
								off+=2;
								for (let i=0; i<count; i++) {
									if (data.getUint16(off,little_endian)===0x0112) {
										const orientation_value=data.getUint16(off+8,little_endian);
										let blob_patched=blob;
										if (orientation_value!==1) {
											const data_patched=new DataView(data_ab.slice(0));
											data_patched.setUint16(off+8,1,little_endian);
											blob_patched=new Blob([
												blob.slice(0,offset),
												data_patched,
												blob.slice(offset+data_len),
											],{type:blob.type});
										}
										return resolve([orientation_value,blob_patched]);
									}
									off+=12;
								}
							}
							catch (exn) {
							}
						}
						offset+=data_len;
						return loop();
					});
				}
			});
		})();
	});
}

//async function get_exif_orientation(blob) {
//	let offset=0;
//	while (true) {
//		const marker_buf=new DataView(await blob_read(blob,4,offset))
//		if (marker_buf.byteLength<2) break;
//		const marker=marker_buf.getUint16(0,false);
//		if ((offset===0 && marker!==0xffd8) || marker===0xffd9 || (marker&0xff00)!==0xff00 || marker_buf.byteLength<4)
//			break;
//		if (marker===0xffd8) {
//			offset+=2;
//			continue;
//		}
//		offset+=4;
//		const data_len=marker_buf.getUint16(2,false)-2;
//		if (marker===0xffe1) {
//			const data=new DataView(await blob_read(blob,data_len,offset));
//			if (data.byteLength<data_len) break;
//			if (data_len>=14 && data.getUint32(0,false)===0x45786966) {
//				const little_endian=data.getUint16(6,false)===0x4949;
//				let off=6+data.getUint32(10,little_endian);
//				try {
//					const count=data.getUint16(off,little_endian);
//					off+=2;
//					for (let i=0; i<count; i++) {
//						if (data.getUint16(off,little_endian)===0x0112)
//							return data.getUint16(off+8,little_endian);
//						off+=12;
//					}
//				}
//				catch (exn) {
//				}
//			}
//		}
//		offset+=data_len;
//	}
//}

export function remove_exif_orientation(blob) {
	return get_exif_orientation(blob).catch(() => undefined).then(exif => exif ? exif[1] : blob);
}
