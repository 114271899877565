import {mke} from "./mke";

let promise;
let resolve_promise;

export function gmaps_cb() {
	resolve_promise();
}

function actually_load_gmaps() {
	promise=new Promise((resolve) => {
		resolve_promise=resolve;
	});
	const script=mke("script",{
		src:`https://maps.googleapis.com/maps/api/js?key=${encodeURIComponent(google_api_key)}&v=3&language=el&callback=tx.gmaps_cb`,
	});
	document.head.append(script);
}

export function load_gmaps() {
	if (!promise) actually_load_gmaps();
	return promise;
}
