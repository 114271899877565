import * as tx_util from "./util";
import * as tx_data from "./data";
import * as tx_accounts from "./accounts";
import * as tx_infobox from "./infobox";
import * as tx_geolocation from "./geolocation";
import * as tx_geo from "./geo";
import * as tx_initial_location from "./initial_location";
import * as tx_gps_tracks from "./gps_tracks";
import * as tx_menu from "./menu";
import * as tx_history from "./history";
import * as tx_cache from "./cache";
import * as tx_counters from "./counters";
import {load_gmaps} from "./gmaps_loader";
import {mke} from "./mke";
import {toast} from "./toast";
import {snackbar} from "./snackbar";
import {add_audio_note} from "./audio_notes";
import * as base64js from "base64-js";
import "leaflet.gridlayer.googlemutant";

let init_is_done=false;
const map_div=document.getElementById("map");
let map;
let control_container;
let street_view;
const street_view_overlay=document.getElementById("street-view-overlay");
let street_view_open=false;
let street_view_control;
let toggle_labels=false;
const show_labels_from_zoom=18;
let arrow;
const arrow_icon=mke(".sv-arrow");
const tx_overlay_pane_name="tx_overlay";
let tx_overlay;
let tx_admin_overlay;
let show_admin_overlay=false;
const show_admin_overlay_checkbox=document.querySelector(".show_admin_overlay");
let current_point;
let selected_point;
let temporary_point;
const zerozero={lat:0,lng:0};
let marker;
const gps_track_pane_name="tx_gps_track";
let map_type;
const default_map_type="roadmap";
const map_type_menu_buttons=document.querySelectorAll("[data-set-map-type]");
let layers;
let filters;
const select_coordinates_center_div=document.querySelector(".choose-loc-on-map .frame");
const select_coordinates_center_btns_div=document.querySelector(".choose-loc-on-map .frame .buttons");
let select_coordinates_center_shake_timeout=0;
let select_coordinates_center_resolve;
let select_coordinates_center_reject;
let select_coordinates_callbacks=[];
const show_on_map_zoom_level=17;
let marker_loaded;
let get_rg_marker;
let rg_layer;
let geolocation_control;

let cat_mask;
let flags_min;
let flags_max;
let search_bitmap;
let last_check_enabled=false;
let last_check_min_age;
let last_check_bitmap;
let last_check_bitmap_min_age;
let user_entry_status_mask;
let map_state_key;
const no_cats_cat=1<<31;

const marker_radius=4; // after scaling
const tile_scale=2; // not used by TXOverlay

const street_view_input=$('<input type="hidden">').prop("readOnly",true).focus(tx_util.select_all_on_focus)[0];
const street_view_new_entry_btn=mke("button",{disabled:""},"Νέο");
const street_view_button_label=mke("span");
const street_view_button=mke("button.edit",{display:"none"},"Πάρ' το (",street_view_button_label,")");

let supports_custom_click=false;
try {
	const ev=new MouseEvent("click", {
		screenX:1,
		screenY:1,
		clientX:1,
		clientY:1,
		ctrlKey:false,
		shiftKey:false,
		altKey:false,
		metaKey:false,
		button:0,
		buttons:0
	});
	ev._custom={foo:32};
	const div=document.createElement("div");
	div.addEventListener("click",(ev) => {
		if (ev._custom.foo===32 && ev.clientX===1)
			supports_custom_click=true;
	});
	div.dispatchEvent(ev);
}
catch(err) {}

let search_seq_no=0;

export function show_search_results(q) {
	const this_search_seq_no=++search_seq_no
	return new Promise(function(resolve,reject) {
		tx_util.api_request("search",q,0,0,true,function(res,err) {
			if (this_search_seq_no!==search_seq_no)
				resolve(false);
			else if (err)
				reject(err);
			else {
				search_bitmap=base64js.toByteArray(res.points);
				refresh();
				resolve(true);
			}
		});
	});
}

export function clear_search_results() {
	search_seq_no++;
	search_bitmap=undefined;
	refresh();
}

function map_must_be_initialized() {
	if (!map) {
		toast("map has not been initialized");
		throw "map has not been initialized";
	}
}

function map_create_pane(map,name,z_index) {
	let pane=map.getPane(name);
	if (!pane) {
		pane=map.createPane(name);
		pane.style.zIndex=z_index;
		pane.style.pointerEvents="none";
	}
	return pane;
}

class CustomIcon extends L.Icon {
	// does not support any of the standard Icon options
	// the element must only be used by one icon
	constructor(elt,{iconAnchor}={}) {
		super();
		this._elt=elt;
		elt.classList.add("custom-icon");
		if (iconAnchor) {
			const {x,y}=L.point(iconAnchor);
			elt.style.marginLeft=`${-x}px`;
			elt.style.marginTop=`${-y}px`;
		}
	}

	createIcon(old_icon) {
		return this._elt;
	}

	createShadow() {
		return null;
	}
}

const geolocation_pane_name="tx_geolocation";

class GeoLocationControl extends L.Control {
	constructor({position="bottomright"}={}) {
		super({position});
	}

	onAdd(map) {
		this._geolocation=new tx_geolocation.GeoLocation(
			(coords) => {this._on_location_found(coords)},
			() => {this._on_location_lost()},
			() => {this.stop()},
			() => {
				this.stop();
				toast("Δεν υπάρχουν τα απαραίτητα δικαιώματα για τον εντοπισμό της τοποθεσίας",undefined,"no-geolocation");
			}
		);
		map_create_pane(map,geolocation_pane_name,250);
		const icon_svg=tx_util.create_svg();
		icon_svg.setAttribute("viewBox","0 0 24 24");
		const path_inner=tx_util.create_svg_path("M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z");
		//path_inner.classList.add("inner");
		path_inner.setAttribute("class","inner"); //classList.add fails on IE11
		icon_svg.append(
			tx_util.create_svg_path("M11 1v2.06A8.993 8.993 0 0 0 3.06 11H1v2h2.06A8.993 8.993 0 0 0 11 20.94V23h2v-2.06A8.993 8.993 0 0 0 20.94 13H23v-2h-2.06A8.993 8.993 0 0 0 13 3.06V1h-2zm1 4c3.87 0 7 3.13 7 7s-3.13 7-7 7-7-3.13-7-7 3.13-7 7-7z"),
			path_inner,
		);
		this._on_orientation=({absolute,alpha}) => {
			if (absolute && alpha!=null) {
				alpha-=screen.orientation.angle;
				this._icon.style.transform=`rotate(${-alpha}deg)`;
				if (!this._has_orientation) {
					this._has_orientation=true;
					this._icon.classList.add("has-orientation");
				}
			}
		};
		const container=mke(".leaflet-bar.cur-location",
			mke("a",{
				title:"Εμφάνιση της τοποθεσίας σας",
				role:"button",
				onclick:(ev) => {
					tx_util.prevent_default(ev);
					tx_util.stop_propagation(ev);
					map.getContainer().focus();
					this.get_location();
					addEventListener("deviceorientationabsolute",this._on_orientation);
				},
			},icon_svg),
		);
		L.DomEvent.disableClickPropagation(container);
		this._classes=container.classList;
		this._circle=L.circle(zerozero,{
			radius:100,
			color:"#bbcee0",
			opacity:0.6,
			weight:1,
			fill:true,
			fillColor:"#bbcee0",
			fillOpacity:0.3,
			pane:geolocation_pane_name,
			interactive:false,
		});
		this._icon=mke(".cur-location-marker");
		this._marker=L.marker(zerozero,{
			icon:new CustomIcon(mke(this._icon),{
				iconAnchor:[32,32],
			}),
			keyboard:false,
			interactive:false,
		});
		this._location=undefined;
		this._following=false;
		this._centered=false;
		this._has_orientation=false;
		map.on("dragstart",this.stop_following,this);
		return container;
	}

	onRemove(map) {
		removeEventListener("deviceorientationabsolute",this._on_orientation);
		this._icon.classList.remove("has-orientation");
		this._geolocation.stop();
		map.off("dragstart",this.stop_following,this);
	}

	get_location() {
		this._geolocation.get();
		this._following=true;
		this._classes.remove("following");
		this._classes.add("searching");
	}

	stop() {
		removeEventListener("deviceorientationabsolute",this._on_orientation);
		this._icon.classList.remove("has-orientation");
		this._has_orientation=false;
		this.stop_following();
		this._classes.remove("searching");
		this._location=undefined;
		this._marker.remove();
		this._circle.remove();
	}

	stop_following() {
		this._following=false;
		this._centered=false;
		this._classes.remove("following");
	}

	_on_location_found(coords) {
		if (this._location===undefined)
			this._icon.classList.remove("stale");
		this._location={lat:coords.latitude,lng:coords.longitude};
		this._circle.setRadius(coords.accuracy);
		this._circle.setLatLng(this._location);
		this._marker.setLatLng(this._location);
		this._circle.addTo(map);
		this._marker.addTo(map);
		this._classes.remove("searching");
		if (this._following) this._update_map_view();
	}

	_update_map_view() {
		if (this._location!==undefined) {
			const zoom=this._centered ? map.getZoom() : 18;
			this._map.setView(this._location,zoom,{
				animate:this._centered || Math.abs(zoom-map.getZoom())<=1
			});
			if (!this._centered) {
				this._classes.add("following");
				this._centered=true;
			}
		}
	}

	_on_location_lost() {
		this._location=undefined;
		this._classes.add("searching");
		this._icon.classList.add("stale");
	}
}

class StreetViewControl extends L.Control {
	constructor({position="bottomright"}={}) {
		super({position});
		this.selecting=false;
		this._last_seq_no=0;
	}

	onAdd(map) {
		const container=mke(".leaflet-bar.street-view",
			mke("a",{
				title:"Αναζήτηση εικόνων Street View",
				role:"button",
				onclick:(ev) => {
					tx_util.prevent_default(ev);
					tx_util.stop_propagation(ev);
					map.getContainer().focus();
					if (this.selecting)
						this.cancel_select_on_map();
					else
						this.select_on_map();
				},
			}),
		);
		L.DomEvent.disableClickPropagation(container);
		this.selecting=false;
		this._classes=container.classList;
		map.on("click",this._on_map_clicked,this);
		return container;
	}

	onRemove(map) {
		map.off("click",this._on_map_clicked,this);
	}

	select_on_map() {
		this.selecting=true;
		this._classes.add("selecting");
		const this_seq_no=++this._last_seq_no;
		load_gmaps().then(() => {
			if (this_seq_no!==this._last_seq_no)
				return;
			(this._layer||(this._layer=new StreetViewCoverageLayer())).addTo(this._map);
		});
	}

	cancel_select_on_map() {
		this._last_seq_no++;
		this.selecting=false;
		this._classes.remove("selecting");
		if (this._layer) this._layer.remove();
	}

	_on_map_clicked(ev) {
		if (!this.selecting || !this._layer)
			return;
		const this_seq_no=++this._last_seq_no;
		const {lat,lng}=ev.latlng.wrap();
		const radius=Math.clamp(2621440/(1 << this._map.getZoom()),2,1000);
		find_nearest_street_view(lat,lng,radius).then((sv_data) => {
			if (this_seq_no!==this._last_seq_no)
				return;
			show_street_view(sv_data);
			this.cancel_select_on_map();
		},(errmsg) => {
			if (this_seq_no!==this._last_seq_no)
				return;
			toast(errmsg)
		});
	}
}

class ToggleLabelsControl extends L.Control {
	constructor({position="bottomright"}={}) {
		super({position});
		this._a=undefined;
		this._disabled=false;
	}

	onAdd(map) {
		this._a=mke("a",{
			title:"Εμφάνιση ετικετών στο χάρτη",
			role:"button",
			onclick:() => {this.toggle()},
		},mke());
		this._a.classList.toggle("on",toggle_labels);
		const container=mke(".leaflet-bar.toggle-labels",this._a);
		L.DomEvent.disableClickPropagation(container);
		map.on("zoomstart",this._disable,this);
		map.on("zoomend",this._on_zoom,this);
		this._on_zoom();
		return container;
	}

	onRemove(map) {
		map.off("zoomend",this._on_zoom,this);
		map.off("zoomstart",this._disable,this);
		this._a=undefined;
	}

	toggle(value) {
		if (this._disabled) return;
		if (value===undefined)
			value=!toggle_labels;
		if (toggle_labels!==value) {
			toggle_labels=value;
			if (this._a)
				this._a.classList.toggle("on",toggle_labels);
			refresh();
		}
	}

	_disable() {
		this.toggle(false);
		this._disabled=true;
		this._a.classList.add("leaflet-disabled");
	}

	_on_zoom() {
		this._disabled=false;
		this._a.classList.remove("leaflet-disabled");
		this.toggle(this._map.getZoom()>=show_labels_from_zoom);
	}
}

//class GpsTrackControl extends L.Control {
//	constructor({position="bottomleft"}={}) {
//		super({position});
//	}
//
//	onAdd(map) {
//		let state="stopped";
//		const btn_stop=mke("a.stop",{
//			title:"Ολοκλήρωση καταγραφή διαδρομής",
//			role:"button",
//			onclick:() => {
//				if (!confirm("Ολοκλήρωση καταγραφής;")) return;
//				tx_gps_tracks.stop();
//				state="stopped";
//				container.setAttribute("data-state",state);
//			},
//		});
//		const btn_start=mke("a.start",{
//			title:"Καταγραφή διαδρομής",
//			role:"button",
//			onclick:() => {
//				if (state==="stopped") {
//					if (!confirm("Εκκίνηση καταγραφής;")) return;
//					tx_gps_tracks.start();
//					state="recording";
//				}
//				else if (state==="recording") {
//					tx_gps_tracks.pause();
//					state="paused";
//				}
//				else if (state==="paused") {
//					tx_gps_tracks.resume();
//					state="recording";
//				}
//				container.setAttribute("data-state",state);
//			},
//		});
//		const container=mke(".leaflet-bar.gps-track",
//			{"data-state":state,"data-req":"create_gps_tracks"},
//			btn_stop,
//			btn_start,
//		);
//		window.container=container;
//		L.DomEvent.disableClickPropagation(container);
//		return container;
//	}
//}

let gmap_div;
let gmap;

// monkey patch some GoogleMutant methods to ensure that only one Google Maps map instance is ever loaded
class GoogleLayer extends L.GridLayer.GoogleMutant {
	_initMutantContainer() {
		this._mutantContainer=gmap_div;
		super._initMutantContainer();
		gmap_div=this._mutantContainer;
		gmap_div.classList.add("gmap");
	}

	_initMutant() {
		this._lru?.clear();
		if (gmap) {
			gmap.setMapTypeId(this.options.type);
			this.redraw();
		}
		this._mutant=gmap;
		super._initMutant();
		gmap=this._mutant;
	}
}

class BlankGoogleMapType {
	constructor() {
		this.tileSize=new google.maps.Size(256,256);
		this.maxZoom=21;
	}

	getTile(coords,zoom,doc) {
		return doc.createElement("div");
	}
}

class StreetViewCoverageLayer extends L.GridLayer.GoogleMutant {
	constructor() {
		super({zIndex:2});
	}

	_initMutant() {
		const new_map=!this._mutant;
		super._initMutant();
		if (new_map) {
			this._mutant.mapTypes.set("_blank",new BlankGoogleMapType);
			this._mutant.setMapTypeId("_blank");
			(new google.maps.StreetViewCoverageLayer()).setMap(this._mutant);
		}
	}
}

const osm_attribution='© <a target="_blank" rel="noopener" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';
const mapbox_access_token="pk.eyJ1IjoiZ2VvcG91bCIsImEiOiJjanNrZHc4eGcxZTV4NDZxem4wN3FycGQ1In0.PIqDCiDfS5jfkABB1kyD4Q";
const google_layer_placeholder=Symbol();

const base_layers={
	"roadmap":{
		layer:L.tileLayer("//gis.tavernoxoros.gr/map/osmtx/{z}/{x}/{y}",{
			maxZoom:19,
			attribution:osm_attribution,
		}),
		stat_id:0,
	},
	"google:roadmap":{
		layer:google_layer_placeholder,
		stat_id:6,
	},
	"google:satellite":{
		layer:google_layer_placeholder,
		stat_id:1,
	},
	"google:hybrid":{
		layer:google_layer_placeholder,
		stat_id:2,
	},
	"google:terrain":{
		layer:google_layer_placeholder,
		stat_id:3,
	},
	//"mapbox:streets-old":{
	//	layer:L.tileLayer(`https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=${mapbox_access_token}`,{
	//		maxZoom:20,
	//		attribution:`© <a target="_blank" rel="noopener" href="https://www.mapbox.com/about/maps/">Mapbox</a> | ${osm_attribution}`,
	//	}),
	//	stat_id:7,
	//},
	//"mapbox:streets":{
	//	layer:L.tileLayer(`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/${L.Browser.retina ? 512 : 256}/{z}/{x}/{y}?access_token=${mapbox_access_token}`,{
	//		maxZoom:20,
	//		attribution:`© <a target="_blank" rel="noopener" href="https://www.mapbox.com/about/maps/">Mapbox</a> | ${osm_attribution}`,
	//	}),
	//	stat_id:7,
	//},
	//"mapbox:satellite-streets":{
	//	layer:L.tileLayer(`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/${L.Browser.retina ? 512 : 256}/{z}/{x}/{y}?access_token=${mapbox_access_token}`,{
	//		maxZoom:20,
	//		attribution:`© <a target="_blank" rel="noopener" href="https://www.mapbox.com/about/maps/">Mapbox</a> | ${osm_attribution}`,
	//	}),
	//	stat_id:8,
	//},
	"OSM:osm-carto":{
		layer:L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",{
			maxZoom:19,
			attribution:osm_attribution,
		}),
		stat_id:4,
	},
	"OpenTopoMap:opentopomap":{
		layer:L.tileLayer("https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",{
			maxZoom:17,
			attribution:`${osm_attribution} © <a href="http://viewfinderpanoramas.org">SRTM</a>`
		}),
		stat_id:50,
	},
	"CartoDB:Positron":{
		layer:L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",{
			attribution:`${osm_attribution} © <a href="https://carto.com/attributions">CARTO</a>`,
			subdomains:"abcd",
			maxZoom:19,
		}),
		stat_id:51,
	},
	"blank":{
		layer:new (
			class extends L.GridLayer {
				createTile(coords) {
					return mke(".blank");
				}
			}
		),
		stat_id:5,
	},
};

class TXOverlay extends L.GridLayer {
	constructor() {
		super({
			zIndex:3,
			maxZoom:21,
			pane:tx_overlay_pane_name,
		});
		this.on("tileunload",(ev) => {
			this.tx_tiles.delete(ev.tile);
		});
		this.tx_tiles=new Map;
		this.tx_refresh_seq_no=0;
	}

	get tx_tile_count() {
		return this.tx_tiles.size;
	}

	tx_refresh_tiles() {
		this.tx_refresh_seq_no++;
		return Promise.all(
			Array.from(this.tx_tiles.values()).map(refresh => refresh())
		).then(tx_util.do_nothing);
	}

	createTile(coords,done) {
		const zoom=coords.z;
		const grid_width=1 << zoom;
		const cx=coords.x & (grid_width-1);
		const cy=coords.y;
		const div=mke();
		let refresh;
		if (/* cy<0 || cy>=grid_width */ false) {
			// this cannot happen with Leaflet
			refresh=() => Promise.resolve();
			done();
		}
		else {
			let already_done=false;
			const base_layer_stat_id=(base_layers[map_type||""]||{}).stat_id;
			if (base_layer_stat_id!==undefined)
				tx_counters.inc(base_layer_stat_id,1);
			div.classList.add("tile");
			const offx=cx << (tx_geo.xy_bit_width-zoom);
			const offy=cy << (tx_geo.xy_bit_width-zoom);
			const z_range=tx_geo.zrange_from_xy(cx,cy,zoom);
			refresh=() => {
				const this_refresh_seq_no=this.tx_refresh_seq_no;
				return tx_data.range_search(z_range).then(ps => {
					if (this_refresh_seq_no!==this.tx_refresh_seq_no) return;
					const elts=[];
					const l=ps.length;
					const canvas_elt=mke("canvas");
					canvas_elt.width=(256+2*marker_radius)*2;
					canvas_elt.height=(256+2*marker_radius)*2;
					const ctx=canvas_elt.getContext("2d");
					ctx.translate(2*marker_radius,2*marker_radius);
					const drawn={};
					const user_entry_status_mask_=tx_accounts.is_logged_in() ? user_entry_status_mask : 0;
					const can_edit_entries=tx_accounts.can("edit_entries");
					for (let i=0; i<l; i++) {
						const p=ps[i];
						if (is_point_visible(p,user_entry_status_mask_)) {
							const x=(p.x-offx) >> (tx_geo.xy_bit_width-8-zoom);
							const y=(p.y-offy) >> (tx_geo.xy_bit_width-8-zoom);
							const s=x+","+y;
							let label_container=drawn[s];
							if (!label_container) {
								if (!toggle_labels)
									label_container=true;
								else {
									label_container=mke(".label-container");
									label_container.style.transform=`translate(${x}px,${y}px)`;
									elts.push(label_container);
								}
								drawn[s]=label_container;
								ctx.drawImage(marker,2*(x-marker_radius),2*(y-marker_radius));
							}
							if (label_container!==true) {
								const label_div=mke();
								let label_str=p.tname;
								if (can_edit_entries && is_mobile) {
									let admin_symbols="";
									if (p.flags&64) // approx
										admin_symbols+="~";
									if (p.flags&4) // out
										admin_symbols+="#";
									if (p.flags&8) // delivery
										admin_symbols+="@";
									if (admin_symbols.length>0)
										label_str+=" "+admin_symbols;
								}
								label_div.textContent=label_str;
								label_container.appendChild(label_div);
							}
						}
					}
					canvas_elt.style.transform=`translate(-${marker_radius}px,-${marker_radius}px)scale(0.5)`;
					elts.push(canvas_elt);
					div.innerHTML="";
					div.append.apply(div,elts);
					if (!already_done) {
						already_done=true;
						done();
					}
				});
			};
			refresh();
			this.tx_tiles.set(div,refresh);
		}
		return div;
	}
}

class RGLayer extends L.GridLayer {
	constructor() {
		super({
			zIndex:3,
			maxZoom:21,
		});
	}

	createTile({z,x,y},done) {
		const {x:tile_width,y:tile_height}=this.getTileSize();
		const tile_div=mke(".tile.rg-tile");
		if (!tx_accounts.can("edit_entries"))
			Promise.resolve().then(done);
		else {
			const canvas_elt=mke("canvas");
			canvas_elt.width=(tile_width+2*marker_radius)*tile_scale;
			canvas_elt.height=(tile_height+2*marker_radius)*tile_scale;
			const ctx=canvas_elt.getContext("2d");
			canvas_elt.style.transform=`translate(-${marker_radius}px,-${marker_radius}px)scale(${1/tile_scale})`;
			ctx.translate(tile_scale*marker_radius,tile_scale*marker_radius);
			Promise.all([tx_util.api_request_promise("get_rg_tile",z,x,y),get_rg_marker]).then(([points_str,rg_marker]) => {
				for (let i=0,len=points_str.length; i<len; i+=4) {
					const px=parseInt(points_str.substring(i,i+2),16);
					const py=parseInt(points_str.substring(i+2,i+4),16);
					ctx.drawImage(rg_marker,tile_scale*(px-marker_radius),tile_scale*(py-marker_radius));
				}
				tile_div.append(canvas_elt);
				done();
			},() => {
				done();
			});
		}
		return tile_div;
	}
}

let raised_admin_box_div;

class TXAdminBox {
	constructor(map,pane,point) {
		this._map=map;
		this._pane=pane;
		this._point=point;
		this._latlon={lat:tx_geo.y_to_lat(point.y),lng:tx_geo.x_to_lon(point.x)};
		const raise_above=() => {
			if (raised_admin_box_div)
				raised_admin_box_div.style.zIndex="";
			raised_admin_box_div=this._div;
			raised_admin_box_div.style.zIndex=1;
		};
		this._caption=mke("span");
		const more_menu_target=mke("button.menu_target",{"data-req":"perform_entry_checks"},"+");
		const more_menu_div=mke(".menu.auto",{style:{"pointer-events":"auto"}},
			mke(".menu_closeOnClick",{onclick:(ev) => {this._on_open_closed_click(ev,"month",false)},"data-req":"perform_entry_checks"},"Ανοιχτό, όχι τώρα"),
			mke(".menu_closeOnClick",{onclick:(ev) => {
				this._on_close_perm_click(ev);
				tx_infobox.browse(`replace_entry_compact_t${this._point.id}`);
			// NOTE: this also needs perform_entry_checks, which we get from the + button
			},"data-req":"propose_entry_edits"},"Αντικατάσταση"),
			mke(".menu_closeOnClick",{onclick:(ev) => {this._on_close_perm_click(ev)},"data-req":"perform_entry_checks"},"Έκλεισε"),
		);
		more_menu_div.addEventListener("touchstart",() => {
			map.dragging.disable();
		});
		more_menu_div.addEventListener("touchend",() => {
			map.dragging.enable();
		});
		new tx_menu.Menu(more_menu_target,more_menu_div,{right_aligned:true});
		this._div=mke(".admin-box.leaflet-zoom-animated",
			{
				onmousedown:tx_util.stop_propagation,
				ondblclick:tx_util.stop_propagation,
				ontouchstart:raise_above,
				onmouseenter:raise_above,
			},
			mke("button",{onclick:(ev) => {this._on_open_closed_click(ev,true,true)},"data-req":"perform_entry_checks"},this._caption),
			mke("button",{onclick:(ev) => {add_audio_note(point.id,0,point.tname)},"data-req":"edit_audio_notes"},"Η"),
			mke("button",{onclick:(ev) => {this._on_open_closed_click(ev,false,false)},"data-req":"perform_entry_checks"},mke("span","κ")),
			more_menu_target,
			more_menu_div,
		);
		tx_data.get_last_entry_check(point.id).then((check) => {
			this._update_date((check||{}).date);
			this.update_pos();
			if (!this._removed)
				this._pane.append(this._div);
		});
	}

	_update_date(date) {
		this._date=date;
		let text;
		this._age=Infinity;
		if (!date)
			text="∞";
		else {
			const m=/^(\d{4})(\d{2})/.exec(`${date}01`);
			if (m) {
				const now=new Date();
				this._age=(now.getFullYear()-parseInt(m[1]))*12+now.getMonth()+1-parseInt(m[2]);
				text=this._age;
			}
			else
				text="?";
		}
		this._caption.textContent=text;
	}

	update_pos() {
		const {x,y}=this._map.latLngToLayerPoint(this._latlon);
		this._div.style.transform=`translate(${x}px,${y+15}px)`;
	}

	update_pos_for_view(center,zoom) {
		const {x,y}=this._map._latLngToNewLayerPoint(this._latlon,zoom,center);
		this._div.style.transform=`translate(${x}px,${y+15}px)`;
	}

	remove() {
		this._div.remove();
		this._removed=true;
	}

	_on_open_closed_click(ev,add_entry_check,hours_check) {
		tx_util.stop_propagation(ev);
		const btn=ev.currentTarget;
		btn.disabled=true;
		let entry_check=null;
		let date;
		if (add_entry_check) {
			const now=new Date();
			date=now.getFullYear()+(""+(now.getMonth()+1)).padStart(2,"0");
			if (add_entry_check!=="month")
				date+=(""+now.getDate()).padStart(2,"0");
			entry_check={date,comment:is_mobile ? "επιτόπια" : null,on_site:is_mobile};
		}
		const promise=tx_data.add_check(this._point.id,0,entry_check,is_mobile ? hours_check : null);
		const old_date=this._date;
		const on_undo=() => {
			promise.then(res => {
				if (res.entry_check) {
					tx_data.delete_entry_check(res.entry_check.id,this._point.id,0).then(() => {
						this._update_date(old_date);
						point_last_check_updated(this._point.id,this._age);
						refresh();
					},err => {
						snackbar(`Το undo του entry_check απέτυχε\n${JSON.stringify(err)}`);
					});
				}
				if (res.hours_check!=null) {
					tx_data.delete_hours_check(res.hours_check.id).catch(err => {
						snackbar(`Το undo του hours_check απέτυχε\n${JSON.stringify(err)}`);
					});
				}
			},tx_util.do_nothing);
		};
		const on_goto=() => {
			tx_infobox.browse(`t${this._point.id}`);
		};
		snackbar(`${this._point.tname}`,{actions:[{caption:"OPEN",cb:on_goto},{caption:"UNDO",cb:on_undo}]});
		promise.then(res => {
			if (add_entry_check && date) {
				this._update_date(date);
				point_last_check_updated(this._point.id,this._age);
				refresh();
			}
		},err => {
			toast(`Το entry_check για το "${this._point.tname}" απέτυχε`+"\n"+JSON.stringify(err));
		}).then(() => {
			btn.disabled=false;
		});
	}

	_on_close_perm_click(ev) {
		tx_util.stop_propagation(ev);
		const promise=tx_util.api_request_promise("propose_entry_closure",this._point.id,0);
		const on_undo=() => {
			promise.then(() => {
				tx_util.api_request_promise("remove_proposed_entry_closure",this._point.id,0,tx_accounts.get_user_id()).catch(err => {
					snackbar(`Το undo απέτυχε`+"\n"+JSON.stringify(err));
				});
			},tx_util.do_nothing);
		};
		const on_goto=() => {
			tx_infobox.browse(`t${this._point.id}`);
		};
		snackbar(`Κλείσιμο: ${this._point.tname}`,{actions:[{caption:"OPEN",cb:on_goto},{caption:"UNDO",cb:on_undo}]});
		promise.then(null,err => {
			toast(`Η πρόταση κλεισίματος για το "${this._point.tname}" απέτυχε\n${JSON.stringify(err)}`);
		});
	}
}

function get_normalized_horiz_bounds(bounds) {
	const sw=bounds.getSouthWest();
	const ne=bounds.getNorthEast();
	if (ne.lng-sw.lng>=360)
		return [[-180,180]];
	else {
		const west=sw.wrap().lng;
		const east=ne.wrap().lng;
		return west<east ? [[west,east]] : [[-180,east],[west,180]];
	}
}

class TXAdminOverlay extends L.Layer {
	constructor() {
		super();
		this._boxes=new Map();
		this._last_seq_no=0;
	}

	onAdd(map) {
		this._pane=map_create_pane(map,"tx_admin",631);
		this.refresh();
	}

	onRemove() {
		this._set_points([]);
	}

	getEvents() {
		return {
			moveend:this.refresh,
			viewreset:this.update_pos,
			zoomanim:this.update_pos_for_view,
			zoom:this.update_pos,
		};
	}

	refresh() {
		const should_show=is_mobile && toggle_labels && map.getZoom()>=show_labels_from_zoom && show_admin_overlay && tx_accounts.can("view_admin_boxes") &&
			(tx_accounts.can("propose_entry_edits") || tx_accounts.can("edit_audio_notes") || tx_accounts.can("perform_entry_checks"));
		if (!should_show)
			this._set_points([]);
		else {
			const bounds=this._map.getBounds();
			const north_y=Math.max(0,tx_geo.lat_to_y(bounds.getNorth()));
			const south_y=Math.min(tx_geo.xy_map_width-1,tx_geo.lat_to_y(bounds.getSouth()));
			const user_entry_status_mask_=tx_accounts.is_logged_in() ? user_entry_status_mask : 0;
			const predicate=p => is_point_visible(p,user_entry_status_mask_);
			const this_seq_no=++this._last_seq_no;
			Promise.all(get_normalized_horiz_bounds(bounds).map(
				async ([west,east]) => {
					const res = await tx_data.rect_search(tx_geo.lon_to_x(west),north_y,tx_geo.lon_to_x(east),south_y,predicate);
					console.log("rect_search",tx_geo.lon_to_x(west),north_y,tx_geo.lon_to_x(east),south_y,res);
					return res;
				}
			)).then(pss => {
				if (this_seq_no!==this._last_seq_no) return;
				this._set_points(pss.flat());
			});
		}
	}

	_set_points(ps) {
		const old_boxes=this._boxes;
		this._boxes=new Map();
		for (const p of ps) {
			let box=old_boxes.get(p.id);
			if (box)
				old_boxes.delete(p.id);
			else
				box=new TXAdminBox(this._map,this._pane,p);
			this._boxes.set(p.id,box);
		}
		for (const box of old_boxes.values())
			box.remove();
	}

	update_pos() {
		for (const box of this._boxes.values())
			box.update_pos();
	}

	update_pos_for_view({center,zoom}) {
		for (const box of this._boxes.values())
			box.update_pos_for_view(center,zoom);
	}
}

class TXTooltipOverlay extends L.Layer {
	onAdd(map) {
		const div=mke(".tooltip",{style:{display:"none"}});
		map.getPane("tooltipPane").append(div);
		this._div=div;
		this._visible=false;
	}

	onRemove() {
		this._visible=false;
		this._div.remove();
		this._div.innerHTML="";
		delete this._div;
	}

	getEvents() {
		return {
			viewreset:() => {this._onMouseMove()},
			zoomstart:() => {this._onMouseMove()},
			mousemove:this._onMouseMove,
			mouseout:(ev) => {this._onMouseMove(ev,true)},
		};
	}

	_onMouseMove(ev,out) {
		if (ev && ev.originalEvent.buttons&1!==0)
			return;
		if (this._timeout) {
			clearTimeout(this._timeout);
			this._timeout=undefined;
		}
		if (this._visible) {
			this._visible=false;
			this._div.style.display="none";
			this._div.innerHTML="";
		}
		if (ev && !out) {
			this._timeout=setTimeout(() => {
				this._timeout=undefined;
				taverns_from_pixel(ev.latlng.wrap(),marker_radius+1,10).then(taverns => {
					const taverns_count=taverns.length;
					if (taverns_count>0) {
						const frag=document.createDocumentFragment();
						for (let i=0; i<taverns_count; i++)
							frag.appendChild(mke("",taverns[i].tname));
						this._div.innerHTML="";
						this._div.append(frag);
						this._div.classList.toggle("more",!!taverns._more);
						const {x,y}=ev.layerPoint;
						this._div.style.left=`${x}px`;
						this._div.style.top=`${y+22}px`;
						this._div.style.display="";
						this._visible=true;
					}
				});
			},100);
		}
	}
}

export function select_coordinates_center(old_coords) {
	map_must_be_initialized();
	if (!old_coords)
		select_coordinates_center_btns_div.style.display="none";
	document.body.classList.add("choosing-loc");
	map.invalidateSize(); // showing/hiding the infobox changes the map size
	if (old_coords) {
		const zoom=Math.max(show_on_map_zoom_level,map.getZoom());
		set_view(+old_coords.lat,+old_coords.lon,zoom);
	}
	return new Promise((resolve,reject) => {
		select_coordinates_center_resolve=resolve;
		select_coordinates_center_reject=reject;
	});
}

export function cancel_select_coordinates_center() {
	if (select_coordinates_center_reject) {
		document.body.classList.remove("choosing-loc");
		map.invalidateSize(); // showing/hiding the infobox changes the map size
		select_coordinates_center_reject();
		select_coordinates_center_resolve=null;
		select_coordinates_center_reject=null;
	}
}

select_coordinates_center_btns_div.querySelector(".btn-ok").addEventListener("click",() => {
	document.body.classList.remove("choosing-loc");
	map.invalidateSize(); // showing/hiding the infobox changes the map size
	if (select_coordinates_center_resolve) {
		const latlon=map.getCenter().wrap();
		select_coordinates_center_resolve({lat:latlon.lat,lon:latlon.lng});
		select_coordinates_center_resolve=null;
		select_coordinates_center_reject=null;
	}
});

select_coordinates_center_btns_div.querySelector(".btn-cancel").addEventListener("click",() => {
	tx_history.back();
});

export function select_coordinates(cb) {
	map_must_be_initialized();
	select_coordinates_callbacks.push(cb);
}

export function cancel_select_coordinates() {
	select_coordinates_callbacks=[];
}

function is_point_visible(p,user_entry_status_mask_) {
	const r=((p.categories||no_cats_cat) & cat_mask)!==0 &&
		(p.flags & flags_min)===flags_min &&
		(p.flags | flags_max)===flags_max &&
		(user_entry_status_mask_===0 ||
			((1 << (p.status||0)) & user_entry_status_mask_)!==0
		);
	if (!r) return false;
	if (search_bitmap!=null) {
		const n=p.id-1;
		const b=search_bitmap[n>>3]|0;
		if ((b & (1<<(n&7)))===0) return false;
	}
	if (last_check_bitmap!==undefined) {
		const n=p.id-1;
		const b=last_check_bitmap[n>>3]|0;
		if ((b & (1<<(n&7)))!==0) return false;
	}
	return true;
}

function taverns_from_pixel(latlon,apparent_radius,count) {
	const x=tx_geo.lon_to_x(latlon.lng);
	const y=tx_geo.lat_to_y(latlon.lat);
	const radius=apparent_radius << (tx_geo.xy_bit_width-8-map.getZoom());
	const user_entry_status_mask_=tx_accounts.is_logged_in() ? user_entry_status_mask : 0;
	const predicate=p => is_point_visible(p,user_entry_status_mask_);
	return tx_data.radius_search(x,y,radius,predicate,count+1).then(ps => {
		if (ps.length>count) {
			ps.length=count;
			ps._more=true;
		}
		return ps;
	});
}

function map_click(ev) {
	if (street_view_control && street_view_control.selecting)
		return;
	const latlon=ev.latlng.wrap();
	if (select_coordinates_callbacks.length!==0) {
		const old_callbacks=select_coordinates_callbacks;
		select_coordinates_callbacks=[];
		const coords={lat:latlon.lat,lon:latlon.lng};
		for (let cb of old_callbacks)
			cb(coords);
		return;
	}
	const pointer_radius=ev.originalEvent._touch_radius||1;
	taverns_from_pixel(latlon,marker_radius+pointer_radius,20).then(taverns => {
		if (select_coordinates_center_resolve) {
			if (taverns.length>0) {
				select_coordinates_center_div.classList.add("shake");
				clearTimeout(select_coordinates_center_shake_timeout);
				select_coordinates_center_shake_timeout=setTimeout(() => {
					select_coordinates_center_div.classList.remove("shake");
				},200);
			}
		}
		else {
			if (taverns.length==0)
				tx_infobox.minimize()
			else if (taverns.length==1)
				tx_infobox.browse("t"+taverns[0].id)
			else {
				const tids=[];
				for (let i=0; i<taverns.length; i++)
					tids.push(taverns[i].id);
				let p="y"+tids.join(",");
				if (taverns._more) p+="m";
				tx_infobox.browse(p);
			}
		}
	});
}

let dblclick_timeout=0;
let prev_click_x,prev_click_y;

function on_map_tap(ev,touch) {
	tx_util.prevent_default(ev); // prevent mouse click
	const params={
		bubbles:true,
		cancelable:true,
		screenX:touch.screenX,
		screenY:touch.screenY,
		clientX:touch.clientX,
		clientY:touch.clientY,
		ctrlKey:ev.ctrlKey,
		shiftKey:ev.shiftKey,
		altKey:ev.altKey,
		metaKey:ev.metaKey,
		button:0,
		buttons:0,
	};
	const click_ev=new MouseEvent("click",params);
	click_ev._touch_radius=Math.max(12,touch.radiusX|0,touch.radiusY|0);
	ev.target.dispatchEvent(click_ev);
	clearTimeout(dblclick_timeout);
	if (prev_click_x!==undefined && Math.abs(prev_click_x-touch.screenX)+Math.abs(prev_click_y-touch.screenY)<=30) {
		ev.target.dispatchEvent(new MouseEvent("dblclick",params));
		prev_click_x=undefined;
	}
	else {
		prev_click_x=touch.screenX;
		prev_click_y=touch.screenY;
		dblclick_timeout=setTimeout(() => {
			prev_click_x=undefined;
		},500);
	}
}

function on_map_long_tap(ev,touch) {
	if (tx_accounts.can("propose_entry_edits") && !control_container.contains(ev.target) && (!street_view_control || !street_view_control.selecting)) {
		// assumes that the map is positioned at the top-left corner of the page
		// so that we can use ev.pageX & ev.pageY here
		const latlon=map.containerPointToLatLng(L.point(touch.pageX,touch.pageY));
		tx_infobox.propose_new_entry({latlon:{lat:latlon.lat,lon:latlon.lng},compact:true});
	}
}

map_div.addEventListener("touchmove",(ev) => {
	if (!control_container.contains(ev.target))
		tx_infobox.minimize();
});

// high-level function, called in response to a user action outside the map
export function set_view(lat,lon,zoom,no_animation) {
	if (map) {
		map.setView({lat,lng:lon},zoom!=null ? zoom : map.getZoom(),{
			animate:!no_animation && (zoom==null || Math.abs(zoom-map.getZoom())<=1)
		});
	}
	if (geolocation_control)
		geolocation_control.stop_following();
}

export function zoom_on_map(lat,lon) {
	set_view(lat,lon,Math.max(show_on_map_zoom_level,map.getZoom()));
}

function accuracy_to_zoom(accuracy) {
	let zoom;
	if (accuracy!==undefined && accuracy>1000)
		zoom=Math.round(24.5-Math.log(accuracy)/Math.log(2));
	if (zoom===undefined || zoom>13)
		zoom=13;
	return zoom;
}

export function resized() {
	if (map)
		map.invalidateSize();
}

let last_map_type_seq_no=0;

export function reset_gmaps() {
	last_map_type_seq_no++;
	gmap_div=undefined;
	gmap=undefined;
	if (/^google:/.test(map_type)) {
		const old_map_type=map_type;
		set_map_type();
		set_map_type(old_map_type);
	}
}

function _set_map_type(type,no_save_map_state) {
	const this_map_type_seq_no=++last_map_type_seq_no;
	const old_layer=(base_layers[map_type||""]||{}).layer;
	if (old_layer) old_layer.remove();
	map_type=type;
	const new_layer=base_layers[type||""];
	if (new_layer) {
		if (new_layer.stat_id!==undefined)
			tx_counters.inc(new_layer.stat_id,tx_overlay.tx_tile_count);
		if (map) {
			if (new_layer.layer!==google_layer_placeholder)
				new_layer.layer.addTo(map);
			else {
				load_gmaps().then(() => {
					if (this_map_type_seq_no===last_map_type_seq_no) {
						new_layer.layer=new GoogleLayer({
							type:type.replace(/^.*:/,""),
							styles:[
								{featureType:"poi",elementType:"labels",stylers:[{visibility:"off"}]},
								{featureType:"transit",elementType:"labels",stylers:[{visibility:"off"}]},
							],
						});
						new_layer.layer.addTo(map);
					}
				});
			}
			if (!no_save_map_state)
				save_map_state();
		}
	}
	for (const elt of map_type_menu_buttons)
		elt.classList.toggle("selected",elt.getAttribute("data-set-map-type")===type);
}

export function set_map_type(type) {
	return _set_map_type(type);
}

function set_map_type_button_listener() {
	set_map_type(this.getAttribute("data-set-map-type"));
}

function update_layer_checkboxes() {
	for (const item of document.querySelectorAll("[data-layer]")) {
		const layer_name=item.getAttribute("data-layer");
		item.classList.toggle("checked",!!layers[layer_name]);
	}
}

function set_layer_state(layer_name,state) {
	if (layer_name==="rg") {
		if (state)
			(rg_layer||(rg_layer=new RGLayer())).addTo(map);
		else if (rg_layer)
			rg_layer.remove();
	}
}

export function toggle_layer(elt) {
	const layer_name=elt.getAttribute("data-layer");
	if (!layer_name)
		return;
	const state=layers[layer_name]=!layers[layer_name];
	update_layer_checkboxes();
	set_layer_state(layer_name,state);
	save_map_state();
}

function update_filter_checkboxes() {
	for (const item of document.querySelectorAll("[data-filter]")) {
		const filter=item.getAttribute("data-filter");
		const matches=/^status-(\d+)$/.exec(filter);
		const state=!!(
			matches
				? user_entry_status_mask&(1<<matches[1])
				: filters[filter]
		);
		item.classList.toggle("checked",state);
	}
}

export function toggle_filter(div) {
	const filter=div.getAttribute("data-filter");
	if (!filter || !/^[a-z0-9_-]+$/.test(filter))
		return;
	const matches=/^status-(\d+)$/.exec(filter);
	if (!matches)
		filters[filter]=!filters[filter];
	else
		user_entry_status_mask^=1<<matches[1];
	update_filter_checkboxes();
	refresh();
	save_map_state();
}

function update_cat_checkboxes() {
	for (const item of document.querySelectorAll("[data-cat]")) {
		const state=!!(cat_mask&(1<<((item.getAttribute("data-cat")|0)-1)));
		item.classList.toggle("checked",state);
	}
}

function cat_mask_updated() {
	update_cat_checkboxes();
	refresh();
	save_map_state();
}

export function toggle_category(id) {
	id|=0;
	if (id===0) return;
	cat_mask^=1<<(id-1);
	cat_mask_updated();
}

export function select_only_category(id) {
	id|=0;
	if (id===0) return;
	cat_mask=1<<(id-1);
	cat_mask_updated();
}

export function select_all_categories() {
	cat_mask=-1;
	cat_mask_updated();
}

export function deselect_all_categories() {
	cat_mask=0;
	cat_mask_updated();
}

function point_last_check_updated(id,age) {
	if (last_check_bitmap!==undefined) {
		const n=id-1;
		const i=n>>3;
		const m=1<<(n&7);
		let b=last_check_bitmap[i]|0;
		b=age<last_check_bitmap_min_age ? b|m : b & ~m;
		last_check_bitmap[i]=b;
	}
}

function set_last_check_min(min_age) {
	min_age|=0;
	if (min_age<=0) {
		return Promise.resolve().then(() => {
			last_check_bitmap=undefined;
			refresh();
		});
	}
	else {
		return tx_util.api_request_promise("get_points_by_last_check",min_age-1).then(res => {
			if (!last_check_enabled) return;
			last_check_bitmap_min_age=min_age;
			last_check_bitmap=base64js.toByteArray(res.points);
			refresh();
		},err => {
			toast(`Η ενεργοποίηση του φίλτρου last_check απέτειχε\n${JSON.stringify(err)}`);
		});
	}
}

export function toggle_last_check(value) {
	last_check_enabled=value==null ? !last_check_enabled : value;
	save_map_state();
	for (const elt of document.querySelectorAll(".last_check_filter"))
		elt.classList.toggle("checked",last_check_enabled);
	set_last_check_min(last_check_enabled ? last_check_min_age : null);
}

function last_check_min_age_updated() {
	for (const elt of document.querySelectorAll(".last_check_filter .value"))
		elt.textContent=last_check_min_age;
}

export function prompt_last_check_min_value() {
	const old_value=last_check_min_age;
	while (true) {
		const str=prompt("Ελάχιστη τιμή τελευταίου ελέγχου",old_value);
		if (str==null)
			return;
		else if (/^\d{1,8}$/.test(str)) {
			last_check_min_age=str|0;
			break;
		}
	}
	tx_util.set_value("last_check_min",last_check_min_age);
	last_check_min_age_updated();
	toggle_last_check(true);
}

export function toggle_admin_overlay() {
	show_admin_overlay=!show_admin_overlay;
	tx_util.set_value("admin_overlay",show_admin_overlay);
	show_admin_overlay_checkbox.classList.toggle("checked",show_admin_overlay);
	refresh();
}

export function refresh() {
	if (!init_is_done) return;
	flags_min=0;
	flags_max=0xffff;
	if (filters["24"]) flags_min|=2;
	if (filters["out"]) flags_min|=4;
	if (filters["del"]) flags_min|=8;
	if (filters["music"]) flags_min|=16;
	if (filters["amea"]) flags_min|=32;
	if (filters["nonsmoking"]) flags_min|=128;
	if (filters["closed"]) {flags_min|=1} else flags_max^=1;
	if (filters["approx"]) flags_min|=64;
	if (filters["has_data"]) flags_min|=512;
	if (filters["new"]) flags_min|=1024;
	if (filters["exact"]) flags_max^=64;

	tx_admin_overlay.refresh();
	return tx_overlay.tx_refresh_tiles();
}

export function set_current_point(lat,lon) {
	if (current_point) {
		current_point.setLatLng({lat,lng:lon});
		current_point.addTo(map);
	}
}

export function clear_current_point(lat,lon) {
	if (current_point) current_point.remove();
}

export function set_temporary_point(lat,lon) {
	map_must_be_initialized();
	temporary_point.setLatLng({lat,lng:lon});
	temporary_point.addTo(map);
}

export function clear_temporary_point(lat,lon) {
	if (temporary_point) temporary_point.remove();
}

export function show_on_map(lat,lon) {
	map_must_be_initialized();
	selected_point.setLatLng({lat,lng:lon});
	selected_point.addTo(map);
	tx_infobox.minimize();
	zoom_on_map(lat,lon);
}

export function unshow_on_map() {
	if (selected_point) selected_point.remove();
}

export function show_arrow(visible) {
	visible=visible===undefined || visible;
	if (visible) map_must_be_initialized();
	if (arrow) {
		if (visible)
			arrow.addTo(map);
		else
			arrow.remove();
	}
}

let street_view_service;

export function find_nearest_street_view(lat,lon,radius) {
	return load_gmaps().then(() => {
		const req={
			location:{lat,lng:lon},
			radius,
			preference:"nearest",
			//source:"default", // or "outdoor"
		};
		if (!street_view_service)
			street_view_service=new google.maps.StreetViewService();
		return new Promise((resolve,reject) => {
			street_view_service.getPanorama(req,(data,status) => {
				if (status==="ZERO_RESULTS")
					reject("Δεν βρέθηκε πανοραμική στο σημείο αυτό");
				else if (status!=="OK" || !data)
					reject("Παρουσιάσθηκε σφάλμα");
				else {
					const {latLng,pano}=data.location;
					resolve({
						lat:latLng.lat(),
						lon:latLng.lng(),
						pano_id:pano,
						heading:0,
						pitch:0,
						zoom:1,
					});
				}
			});
		});
	});
}

export function parse_street_view_value(value) {
	const m=value.match(/^([+-]?\d+(?:\.\d+)?)\|([+-]?\d+(?:\.\d+)?)\|([+-]?\d+(?:\.\d+)?)\|([+-]?\d+(?:\.\d+)?)\|(\d+(?:\.\d+)?)\|(.{1,32})/);
	if (m) {
		return {
			lat:parseFloat(m[1]),
			lon:parseFloat(m[2]),
			heading:parseFloat(m[3]),
			pitch:parseFloat(m[4]),
			zoom:parseFloat(m[5]),
			pano_id:m[6]
		};
	}
}

function serialize_street_view_value(sv_data) {
	return [
		sv_data.lat.toFixed(6),
		sv_data.lon.toFixed(6),
		sv_data.heading.toFixed(1),
		sv_data.pitch.toFixed(1),
		sv_data.zoom.toFixed(2),
		sv_data.pano_id,
	].join("|");
}

export function select_street_view_on_map() {
	if (street_view_control)
		street_view_control.select_on_map();
}

function load_street_view() {
	if (street_view)
		return Promise.resolve();
	else {
		return load_gmaps().then(() => {
			street_view=new google.maps.StreetViewPanorama(street_view_overlay.querySelector(".street-view"),{
				enableCloseButton:true,
				imageDateControl:true,
				visible:false,
				fullscreenControl:false,
				motionTracking:false,
				motionTrackingControlOptions:true,
			});
			const street_view_params_div=mke(".toolbar",
				{"data-req":"propose_entry_edits"},
				street_view_input,
				street_view_new_entry_btn,
				street_view_button,
			);
			street_view.controls[google.maps.ControlPosition.LEFT_TOP].push(street_view_params_div);
			const on_updated=tx_util.debounce(street_view_updated,50);
			street_view.addListener("pano_changed",on_updated);
			street_view.addListener("position_changed",on_updated);
			street_view.addListener("pov_changed",on_updated);
			street_view.addListener("zoom_changed",on_updated);
			street_view.addListener("status_changed",on_updated);
			street_view.addListener("closeclick",() => {
				tx_history.back();
			});
			street_view_button.addEventListener("click",() => {
				street_view_updated();
				const value=street_view_input.value;
				close_street_view();
				tx_infobox.on_street_view_new_value(value);
			});
			street_view_new_entry_btn.addEventListener("click",() => {
				const sv=serialize_street_view_value(get_street_view_data());
				const sv_hex=[...new TextEncoder().encode(sv)].map(c => c.toString(16).padStart(2,"0")).join("");
				hide_street_view();
				tx_infobox.browse(`new_entry_from_street_view_${sv_hex}_10`)
			});
		});
	}
}

function get_street_view_data() {
	const pos=street_view.getPosition();
	const pov=street_view.getPov();
	let zoom=street_view.getZoom();
	if (zoom==null) zoom=1;
	const pano_id=street_view.getPano();
	if (pos && pov && zoom!=null && pano_id) {
		let heading=pov.heading;
		heading=heading-Math.floor(heading/360)*360;
		if (heading>=359.95) heading=0;
		return {
			lat:pos.lat(),
			lon:pos.lng(),
			heading,
			pitch:pov.pitch,
			zoom,
			pano_id,
		};
	}
}

function street_view_updated() {
	const sv_data=get_street_view_data();
	if (sv_data) {
		const value=serialize_street_view_value(sv_data);
		if (street_view_open)
			tx_history.replace_history({sv:value},{street_view:sv_data});
		street_view_input.value=value;
		arrow.setLatLng({lat:sv_data.lat,lng:sv_data.lon});
		arrow_icon.style.transform=`rotate(${sv_data.heading}deg)`;
		street_view_new_entry_btn.disabled=false;
	}
}

export function show_street_view(sv_data) {
	map_must_be_initialized();
	load_street_view().then(() => {
		if (sv_data)
			tx_history.push_history({sv:serialize_street_view_value(sv_data)},{street_view:sv_data});
		else
			// add an invalid state for now, it will be amended later by street_view_updated
			tx_history.push_history({sv:null},{street_view:null});
		open_street_view(sv_data);
	});
}

function open_street_view(sv_data) {
	tx_counters.inc(11,1)
	if (sv_data) {
		//street_view.setPosition({lat:sv_data.lat,lng:sv_data.lon});
		street_view.setPano(sv_data.pano_id);
		street_view.setPov({heading:sv_data.heading,pitch:sv_data.pitch});
		street_view.setZoom(sv_data.zoom);
	}
	street_view_overlay.style.display="";
	getComputedStyle(street_view_overlay).width; // force the style
	street_view_open=true;
	street_view.setVisible(true);
}

export function hide_street_view() {
	if (!street_view_open) return;
	street_view_overlay.style.display="none";
	street_view.setVisible(false);
	street_view_open=false;
}

function close_street_view() {
	if (street_view && street_view.getVisible())
		tx_history.back();
}

export function show_street_view_get_value_button(state,label="",label_italic) {
	street_view_button.style.display=state ? "" : "none";
	street_view_button_label.textContent=label;
	street_view_button.classList.toggle("meta",label_italic);
}

let old_gps_tracks_is_empty=true;
const old_gps_tracks_layer=L.polyline([[]],{
	pane:gps_track_pane_name,
	color:"#ff8833",
	interactive:false,
});
const gps_track_layer=L.polyline([[]],{
	pane:gps_track_pane_name,
	interactive:false,
});
let gps_track_cur_ring;
gps_track_clear();

export function old_gps_tracks_clear() {
	old_gps_tracks_layer.setLatLngs([[]]);
	old_gps_tracks_is_empty=true;
}

export function old_gps_tracks_show_track(segments) {
	if (old_gps_tracks_is_empty) {
		old_gps_tracks_layer.setLatLngs(segments);
		map.fitBounds(old_gps_tracks_layer.getBounds());
	}
	else {
		const other_segments=old_gps_tracks_layer.getLatLngs();
		let bounds=L.latLngBounds();
		for (const segment of segments) {
			other_segments.push(segment);
			bounds.extend(segment);
		}
		old_gps_tracks_layer.setLatLngs(other_segments);
		map.fitBounds(bounds);
	}
	old_gps_tracks_is_empty=false;
}

export function gps_track_clear() {
	gps_track_layer.setLatLngs([[]]);
	gps_track_cur_ring=gps_track_layer.getLatLngs()[0];
}

export function gps_track_add_segment() {
	gps_track_cur_ring=[];
	gps_track_layer.getLatLngs().push(gps_track_cur_ring);
}

export function gps_track_add_point(lat,lon) {
	gps_track_layer.addLatLng([lat,lon],gps_track_cur_ring);
}

let view_changed_timeout=0;

function on_view_changed() {
	clearTimeout(view_changed_timeout);
	view_changed_timeout=setTimeout(save_map_state,1000);
}

function save_map_state() {
	const center=map.getCenter().wrap();
	tx_util.set_value_tab(map_state_key,{
		center,
		zoom:map.getZoom(),
		map_type,
		layers,
		cat_mask,
		filters,
		last_check_enabled,
		user_entry_status_mask,
	});
	tx_util.set_value("map",{
		center,
		zoom:map.getZoom(),
		map_type,
	});
}

export function on_history(params,state,is_initial,do_not_set_map_center) {
	if (tx_history.params.nomap && !map)
		return;
	if (is_initial) {
		map_state_key=state.map;
		if (!state.map) {
			map_state_key=tx_cache.new_key();
			tx_history.amend_state({map:map_state_key});
		}
		map_state_key="map:"+map_state_key;
		const map_state=Object.assign({},tx_util.get_value("map"),tx_util.get_value_tab(map_state_key));
		if (!do_not_set_map_center) {
			if (map_state.center && map_state.zoom!=null)
				set_view(map_state.center.lat,map_state.center.lng,map_state.zoom,true);
			else {
				tx_initial_location.get().then(p => {
					set_view(p.lat,p.lon,accuracy_to_zoom(p.accuracy),true);
				});
			}
		}
		let map_type_=default_map_type;
		if (map_state.map_type && base_layers[map_state.map_type])
			map_type_=map_state.map_type;
		_set_map_type(map_type_,true);
		layers=map_state.layers||{};
		for (const [layer_name,state] of Object.entries(layers))
			set_layer_state(layer_name,state);
		cat_mask=map_state.cat_mask!=null ? map_state.cat_mask : -1;
		update_cat_checkboxes();
		filters=map_state.filters||{};
		if (map_state.last_check_enabled)
			toggle_last_check(true);
		user_entry_status_mask=map_state.user_entry_status_mask|0;
		update_filter_checkboxes();
		update_layer_checkboxes();
		map.on("moveend",tx_util.debounce(save_map_state,250));
		marker_loaded.then(() => {
			init_is_done=true;
			refresh();
		});
	}

	let sv_data=state.street_view;
	if (!sv_data && params.sv) {
		sv_data=parse_street_view_value(params.sv);
		if (sv_data) tx_history.amend_state({street_view:sv_data});
	}
	if (!sv_data)
		hide_street_view();
	else {
		load_street_view().then(() => {
			open_street_view(sv_data);
		});
	}
}

export function init() {
	last_check_min_age=tx_util.get_value("last_check_min")||1;
	last_check_min_age_updated();

	show_admin_overlay=tx_util.get_value("admin_overlay")||true;
	if (show_admin_overlay)
		show_admin_overlay_checkbox.classList.add("checked");

	if (supports_custom_click)
		tx_util.listen_for_taps(map_div,{capture:true,passive:false,timeout_cb:on_map_long_tap},on_map_tap);

	map=L.map(map_div,{
		zoomControl:false,
		bounceAtZoomLimits:false,
		tap:false, // we use our own workaround for taps
	});
	control_container=map_div.querySelector(".leaflet-control-container");
	map.attributionControl.setPrefix();
	map.setView({lat:38.6,lng:23.6},7);
	tx_counters.inc(10,1);

	map.on("layeradd",(ev) => {
		const layer_maxZoom=ev.layer.options.maxZoom;
		if (layer_maxZoom!==undefined && layer_maxZoom<map.getMaxZoom())
			map.setMaxZoom(layer_maxZoom);
	});
	map.on("layerremove",(ev) => {
		const layer_maxZoom=ev.layer.options.maxZoom;
		if (layer_maxZoom!==undefined && layer_maxZoom===map.getMaxZoom()) {
			let max_zoom=Infinity;
			map.eachLayer((layer) => {
				const this_layer_maxZoom=layer.options.maxZoom;
				if (this_layer_maxZoom!==undefined && this_layer_maxZoom<max_zoom)
					max_zoom=this_layer_maxZoom;
			});
			map.setMaxZoom(max_zoom);
		}
	});

	map_create_pane(map,tx_overlay_pane_name,231);
	tx_overlay=new TXOverlay();
	tx_overlay.addTo(map);
	(new TXTooltipOverlay).addTo(map);
	tx_admin_overlay=new TXAdminOverlay();
	tx_admin_overlay.addTo(map);

	map_create_pane(map,gps_track_pane_name,217);
	old_gps_tracks_layer.addTo(map);
	gps_track_layer.addTo(map);
	//if (is_mobile)
		//(new GpsTrackControl()).addTo(map);

	map.on("click",map_click);
	map.on("dragstart",() => {
		select_coordinates_center_btns_div.style.display="";
	});
	L.control.zoom({
		position:"bottomright",
		zoomInTitle:"Μεγέθυνση",
		zoomOutTitle:"Σμίκρυνση",
	}).addTo(map);
	//L.control.scale({imperial:false}).addTo(map);

	street_view_control=new StreetViewControl();
	tx_accounts.on_auth_change(() => {
		if (tx_accounts.can("propose_entry_edits"))
			street_view_control.addTo(map);
		else
			street_view_control.remove();
	});

	for (const elt of map_type_menu_buttons)
		elt.addEventListener("click",set_map_type_button_listener);

	arrow=L.marker(zerozero,{
		icon:new CustomIcon(mke(arrow_icon),{
			iconAnchor:[9.5,15.428],
		}),
		zIndexOffset:4000,
		keyboard:false,
		interactive:false,
	});
	const selected_point_options={
		radius:9.5,
		color:"#7687de",
		weight:4,
		fill:false,
		interactive:false,
	};
	current_point=L.circleMarker(zerozero,Object.assign({zIndexOffset:2000},selected_point_options));
	selected_point=L.circleMarker(zerozero,Object.assign({zIndexOffset:3000},selected_point_options));
	temporary_point=L.marker(zerozero,{
		icon:L.icon({
			iconUrl:"graphics/marker-2x.png",
			iconSize:[27,43],
			iconAnchor:[13.5,43],
		}),
		zIndexOffset:5000,
		opacity:0.5,
		keyboard:false,
		interactive:false,
	});

	if (tx_geolocation.is_supported())
		geolocation_control=(new GeoLocationControl()).addTo(map);

	(new ToggleLabelsControl()).addTo(map);


	get_rg_marker=tx_util.load_image("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='9' height='9'%3e%3cdefs%3e%3cfilter id='a' x='-.132' width='1.264' y='-.132' height='1.264' color-interpolation-filters='sRGB'%3e%3cfeGaussianBlur stdDeviation='.418'/%3e%3c/filter%3e%3c/defs%3e%3ccircle style='marker:none' cx='858.786' cy='802.148' r='3.8' color='black' overflow='visible' fill='%23008000' stroke='%23501414' stroke-width='.6' stroke-linejoin='round' filter='url(%23a)' enable-background='accumulate' transform='translate(-854.286 -797.648)'/%3e%3c/svg%3e").then((img) => {
		img.width*=tile_scale;
		img.height*=tile_scale;
		return tx_util.image2canvas(img);
	});

	const marker_img=new Image();
	marker_loaded=new Promise(resolve => {
		marker_img.onload=() => {
			const img_canvas=tx_util.image2canvas(marker_img);
			// IE10 does not support CanvasContext.drawImage(other_canvas,...)
			// it only supports passing an image element
			// on other browsers, drawImage is faster with a canvas element
			try {
				mke("canvas").getContext("2d").drawImage(img_canvas,0,0);
				marker=img_canvas;
			}
			catch (exn) {
				marker=marker_img;
			}
			resolve();
		};
	});
	marker_img.src= // 18x18 px
		"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAACBElEQVR42q"+
		"1UA8ycQRB9NaJyf2MrfNffJ9W2bSNGzUhFVAtxGdVxbdu2bew7W0jmMpmHbzUDn185FRVbATXsQKP+QM"+
		"vhQJ+RwAgG896qRowccl0aBJpUtgBZitx+khDTZufkbFqSnb1vpZQHGcxZI9YXaEcuNX5mLHQHcgcDg2"+
		"dlZ69f16jRqZ2a9vRIYeHH8yUlXxjMWVursNnZ2euGAIOoAVDJbVLeCoiBQP/5OTlbtmvavQtK+Nhk+v"+
		"3Gav37yRXMWSNGzoKsrM3UUEsPGlXvBlimCbF6h6bdv6XXf//YvPnfn82b//vdsuW/P65gzhoxcsilhl"+
		"p6oAzIGCXElNVSnrpSUvL1s/oyhX/DBLHPyuyKXv+VmnFCTKYHugLN1Lms3ldY+PyV1frnNwVRghxyle"+
		"bZzJycVd0BHfoAtkVS7lMH+vVL8+YkxhTk8hIWSrm3H2CF+mu7VMrDd0ymnz9JijHIpWaZlIfokTKj1G"+
		"3Nfdj71cHFc9gvAw7b7/p5pbzaaNf/yXv9J3n9BiA9oQd50/kg702rU2dlZ8AMoJpfi/DZ8/lfLCn5/C"+
		"hEi7B2QWE7dLq75PYH+jUH6rpbBGw8NiAbkQ251tW0h32aljlr6xs1OjkzuGmDxwhHxAQhpgaMkQOOMZ"+
		"KVtZFYhDESPNj6AC3cg20UMHwE0Ju19kDDUIPtP7HO64eFkKEyAAAAAElFTkSuQmCC";
}

/*
z-order of different map layers

object             │ pane           │ pane z-index │ zIndexOffset
───────────────────┼────────────────┼──────────────┼─────────────
tx_tooltip_overlay │ tooltipPane    │ 650          │
tx_admin_overlay   │ tx_admin       │ 631          │
temporary_point    │ markerPane     │ 600          │ 5000
arrow (sv)         │ markerPane     │ 600          │ 4000
selected_point     │ markerPane     │ 600          │ 3000
current_point      │ markerPane     │ 600          │ 2000
gps marker         │ tx_geolocation │ 250          │
gps circle         │ tx_geolocation │ 250          │
tx_overlay         │ tx_overlay     │ 231          │
gps_track_layer    │ tx_gps_track   │ 217          │
rg_layer           │ tilePane       │ 200          │
base map layer     │ tilePane       │ 200          │
*/
