import * as tx_geolocation from "./geolocation";
import * as tx_map from "./map";
import * as tx_infobox from "./infobox";
import * as tx_util from "./util";
import * as tx_upload from "./upload";
import {toast} from "./toast";
import {mke} from "./mke";

// 0 have not recorded any points yet
// 1 recording
// 2 lost signal
let state=0;

const queue=[];
let queue_timeout=0;
let last_track_id;
let last_seg_id;
const wait_time=30*1000;

async function process_queue() {
	let failed=false;
	queue_timeout=-1;
	if (queue.length>0) {
		const [st,tuple]=queue[0];
		const tuples=[tuple];
		let j=1;
		while (j<queue.length && queue[j][0]===1) {
			tuples.push(queue[j][1]);
			j++;
		}
		const args=
			st===0 ? ["add_gps_track"] :
			st===1 ? ["append_gps_track",last_track_id,last_seg_id] :
				["append_gps_track",last_track_id,null];
		try {
			const {segment,track}=await tx_util.api_request_promise(...args,tuples);
			last_track_id=track.id;
			last_seg_id=segment.id;
			queue.splice(0,j)
		}
		catch (err) {
			if (err.code===0)
				await tx_util.sleep(wait_time);
			else {
				toast(`Απέτυχε ${args[0]}: ${JSON.stringify(err)}`);
				failed=true;
			}
		}
	}
	if (queue.length>0 || failed)
		queue_timeout=setTimeout(process_queue,wait_time);
	else
		queue_timeout=0;
}

const geolocation=new tx_geolocation.GeoLocation(
	({latitude,longitude,accuracy,altitude,altitudeAccuracy}) => {
		const time=Date.now();
		tx_map.gps_track_add_point(latitude,longitude);
		const tuple=[time/1000,latitude,longitude,accuracy,altitude,altitudeAccuracy];
		const last_task=queue[queue.length-1];
		queue.push([state,tuple]);
		if (queue_timeout===0)
			queue_timeout=setTimeout(process_queue,wait_time);
		state=1;
	},
	() => {
		tx_map.gps_track_add_segment();
		state=2;
	},
	(err) => {
		stop();
		toast(`Παρουσιάστηκε σφάλμα κατά τον εντοπισμό της τοποθεσίας: ${err.message} (${err.code})`);
	},
	() => {
		stop();
		toast("Δεν υπάρχουν τα απαραίτητα δικαιώματα για τον εντοπισμό της τοποθεσίας",undefined,"no-geolocation");
	},
	{strict_lost:true}
);

export function start() {
	state=0;
	geolocation.get();
}

export function resume() {
	state=2;
	geolocation.get();
}

export function pause() {
	geolocation.stop();
}

export function stop() {
	geolocation.stop();
	tx_map.gps_track_clear();
	if (queue_timeout>0) {
		clearTimeout(queue_timeout);
		queue_timeout=setTimeout(process_queue,0);
	}
}

export function clear_gps_tracks() {
	tx_map.old_gps_tracks_clear();
}

export function import_gps_tracks_prompt(btn) {
	const files_input=mke("input",{type:"file",multiple:"multiple",style:{display:"none"}});
	files_input.addEventListener("change",async () => {
		const loading_div=btn.closest(".import").querySelector(".loading");
		loading_div.style.display="";
		btn.disabled=true;
		for (const file of files_input.files) {
			try {
				await tx_upload.upload("gpx_file",file);
			}
			catch (err) {
				const msg=err instanceof Error ? err.message : JSON.stringify(err);
				toast(`Η εισαγωγή του αρχείου ${file.name} απέτυχε: ${msg}`);
				console.error(err);
			}
		}
		if (document.contains(btn) && files_input.files.length>0)
			tx_infobox.reload();
	});
	btn.after(files_input);
	files_input.click();
}

export async function show_gps_track(track_id) {
	try {
		const track_points=await tx.api_request_promise("get_gps_track_points",track_id);
		tx_map.old_gps_tracks_show_track(track_points);
		tx_infobox.minimize();
	}
	catch (err) {
		toast(`Απέτυχε: ${JSON.stringify(err)}`);
	}
}

export async function delete_gps_track(elt,track_id) {
	if (!confirm("Σίγουρα;")) return;
	try {
		await tx_util.api_request_promise("delete_gps_track",track_id);
	}
	catch (err) {
		toast(`Απέτυχε: ${JSON.stringify(err)}`);
		return;
	}
	const track_elt=elt.closest(".gps-track");
	$(track_elt).slideUp();
	setTimeout(() => {
		track_elt.remove();
	},500);
}

export async function set_gps_track_title(elt,track_id,old_title) {
	let new_title=prompt("Νέος τίτλος",old_title);
	if (new_title==null)
		return;
	if (!new_title)
		new_title=null;
	try {
		await tx_util.api_request_promise("set_gps_track_title",track_id,new_title);
	}
	catch (err) {
		toast(`Απέτυχε: ${JSON.stringify(err)}`);
		return;
	}
	const title_elt=elt.closest(".gps-track").querySelector(".title");
	title_elt.style.fontStyle="";
	title_elt.textContent=new_title;
}
