const PERMISSION_DENIED=1;
const watch_opts={
	enableHighAccuracy:true,
	maximumAge:0,
};
const DEFAULT_LOST_TIMEOUT_INTERVAL=15*60*1000;

const EARTH_RADIUS=6371*1000;

// haversine formula:
function distance(coords1,coords2) {
	const lat1=coords1.latitude*Math.PI/180;
	const lat2=coords2.latitude*Math.PI/180;
	const sinA=Math.sin((lat2-lat1)/2);
	const sinB=Math.sin((coords2.longitude-coords1.longitude)*Math.PI/360);
	return 2*EARTH_RADIUS*Math.asin(Math.sqrt(sinA*sinA+Math.cos(lat1)*Math.cos(lat2)*sinB*sinB));
}

export function is_supported() {
	return "geolocation" in navigator;
}

// on_position_lost will be called only after on_position_found has been called at least once
// on_position_lost will never be called 2 consecutive times

export function GeoLocation(on_position_found,on_position_lost,on_error,on_denied,{strict_lost=false}={}) {
	let watch_id;
	let last_position;
	let lost_timeout_id=0;
	let running=false;
	let last_get_seq_no=0;

	this.stop=() => {
		running=false;
		if (watch_id!==undefined) {
			navigator.geolocation.clearWatch(watch_id);
			watch_id=undefined;
		}
		clearTimeout(lost_timeout_id);
		lost_timeout_id=0;
	};

	const on_error_ext=(err) => {
		if (err.code!==PERMISSION_DENIED)
			on_error(err);
		else
			on_denied();
		this.stop();
	};

	const on_position_found_ext=(pos) => {
		let lost_timeout_interval;
		if (strict_lost)
			lost_timeout_interval=30*1000;
		else if (last_position===undefined)
			lost_timeout_interval=DEFAULT_LOST_TIMEOUT_INTERVAL;
		else {
			const speed=distance(last_position.coords,pos.coords)/(pos.timestamp-last_position.timestamp);
			lost_timeout_interval=Math.min(Math.max((100/speed)||0,60*1000),3600*1000);
		}
		last_position=pos;
		clearTimeout(lost_timeout_id);
		lost_timeout_id=setTimeout(on_position_lost,lost_timeout_interval);
		on_position_found(pos.coords,pos.timestamp);
	};

	this.get=() => {
		running=true;
		const this_seq_no=++last_get_seq_no;
		navigator.geolocation.getCurrentPosition(pos => {
			if (!running || this_seq_no!==last_get_seq_no) return;
			if (watch_id===undefined)
				setTimeout(() => {
					if (watch_id===undefined)
						watch_id=navigator.geolocation.watchPosition(on_position_found_ext,on_error_ext,watch_opts);
				},5000);
			on_position_found_ext(pos);
		},on_error_ext,watch_opts);
	};
}
