import * as tx_util from "./util";

// counter_id ranges:
//   0-100: map
//     0-9: tile of map type N
//     10: map load
//     11: street view load
//     12: the traffic layer was enabled by the user
//     50-100: tile of map type N (from misc types)
//   46000-46100: signup_widget

const counters={};
const on_unload_cbs=[];

export function inc(counter_id,value) {
	counters[counter_id]=(counters[counter_id]|0)+value;
}

export function register_on_unload(cb) {
	on_unload_cbs.push(cb);
}

function send_stats(synchronous) {
	const s=[];
	for (const counter_id in counters) {
		if (counters[counter_id])
			s.push(counter_id|0,counters[counter_id]);
		delete counters[counter_id];
	}
	if (s.length>0)
		tx_util.api_request_beacon(synchronous,"tile_stats",s);
}

export function init() {
	setInterval(send_stats,60*60*1000);
	addEventListener("beforeunload",() => {
		for (const cb of on_unload_cbs)
			cb();
		send_stats(true);
	});
}
