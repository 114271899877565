import * as tx_util from "./util";
import * as tx_accounts from "./accounts";
import * as tx_img_overlay from "./img_overlay";
import * as tx_upload_overlay from "./upload_overlay";
import * as tx_infobox from "./infobox";
import {toast} from "./toast";
import {Retrier} from "./retrier";

// for cache busting purposes
const edited_images={};

// === ImageCollection =========================

// An ImageCollection is a "lazy" list of images that supports linear access and removal of images.
// Each image has an index which is persistent, i.e. if a previous image is deleted or new image
// is prepended in the collection, the index will not change.
// All methods that expect an idx, can only use an idx that was returned by a collection method. The first idx
// must be obtained by calling find_next_image(undefined,function() {...})
class ImageCollection {
	constructor() {
		this.batch_size=12;
		this.images=[];
		this.offset=0;
		this.marker_end=null;
		this.marker_begin=null;
		this.reached_end=false;
		this.reached_begin=true;
		this.lock_end=new tx_util.Lock();
		this.lock_begin=new tx_util.Lock();
	}

	// can only be called on a "found" idx
	get_image(idx) {
		return this.images[idx+this.offset];
	}

	static get context() {
		return null;
	}

	get_url_params(idx) {
		return {
			i:this.images[idx+this.offset].name,
			ic:this.constructor.context||null
		};
	}

	get_permalink(idx) {
		var params=this.get_url_params(idx);
		return "?i="+encodeURIComponent(params.i)+(params.ic ? "&ic="+encodeURIComponent(params.ic) : "");
	}

	get_image_url(idx) {
		const image=this.images[idx+this.offset];
		return "img/l/"+image.name+(edited_images[image.name]||"");
	}

	get_thumb_url(idx) {
		const image=this.images[idx+this.offset];
		return "img/i/"+image.name+(edited_images[image.name]||"");
	}

	get_entry(idx) {
		if (idx!==undefined) return this.images[idx+this.offset].entry;
	}

	_prepare_image(image) {
		image._on_update=[];
		image._on_remove=[];
		image._updated=() => {
			for (const cb of image._on_update)
				cb();
		};
		image._edited=() => {
			edited_images[image.name]=`?t=${Date.now()}`;
		};
		return image;
	}

	prepend_image(image) {
		this.images.unshift(this._prepare_image(image));
		return -(++this.offset);
	}

	remove_image(idx) {
		var i=idx+this.offset;
		if (0<=i && i<this.images.length && this.images[i]!==undefined) {
			var on_remove=this.images[i]._on_remove;
			this.images[i]=undefined;
			for (const cb of on_remove)
				cb();
		}
	}

	// abstract method:
	//_download(marker,count,cb) {}

	_load_more_end(count,cb_ok,cb_err) {
		var that=this;
		if (that.reached_end) return cb_ok();
		that.lock_end.acquire(function() {
			that._download(that.marker_end,count,function(res,err) {
				if (err) {
					that.lock_end.release();
					if (cb_err!==undefined) cb_err();
					return;
				}
				var l=res.length;
				for (var i=0; i<l; i++)
					that.images.push(that._prepare_image(res[i]));
				if (l>0) that.marker_end=res[l-1].name;
				if (l<count) that.reached_end=true;
				that.lock_end.release();
				cb_ok();
			});
		});
	}

	_load_more_begin(count,cb_ok,cb_err) {
		var that=this;
		if (that.reached_begin) return cb_ok();
		that.lock_begin.acquire(function() {
			that._download(that.marker_begin,-count,function(res,err) {
				if (err) {
					that.lock_begin.release();
					if (cb_err!==undefined) cb_err();
					return;
				}
				var l=res.length;
				for (var i=0; i<l; i++)
					that.images.unshift(that._prepare_image(res[i]));
				if (l>0) that.marker_begin=res[l-1].name;
				that.offset+=l;
				if (l<count) that.reached_begin=true;
				that.lock_begin.release();
				cb_ok();
			});
		});
	}

	has_previous(idx,cb_ok,cb_err) {
		this.find_previous_image(idx,function(idx_next) {cb_ok(idx_next!==undefined)},cb_err);
	}

	has_next(idx,cb_ok,cb_err) {
		this.find_next_image(idx,function(idx_next) {cb_ok(idx_next!==undefined)},cb_err);
	}

	find_previous_image(idx,cb_ok,cb_err) {
		var that=this;
		idx--;
		while (idx+that.offset>=0 && that.images[idx+that.offset]===undefined) idx--;
		if (idx+that.offset>=0)
			cb_ok(idx,that.images[idx+that.offset]);
		else if (that.reached_begin)
			cb_ok();
		else {
			that._load_more_begin(that.batch_size,function() {
				that.find_previous_image(idx+1,cb_ok,cb_err);
			},cb_err);
		}
	}

	find_next_image(idx,cb_ok,cb_err) {
		var that=this;
		if (idx===undefined) idx=-that.offset; else idx++;
		var l=that.images.length;
		while (idx+that.offset<l && that.images[idx+that.offset]===undefined) idx++;
		if (idx+that.offset<l)
			cb_ok(idx,that.images[idx+that.offset]);
		else if (that.reached_end)
			cb_ok();
		else
			that._load_more_end(that.batch_size,function() {
				that.find_next_image(idx-1,cb_ok,cb_err);
			},cb_err);
	}

	_append_images(idx,count,array,cb_ok,cb_err) {
		var that=this;
		if (count<=0)
			that.has_next(idx,function(has_next) {
				cb_ok(idx,array,!has_next);
			},cb_err);
		else
			that.find_next_image(idx,function(idx_next,image) {
				if (idx_next===undefined)
					cb_ok(idx,array,true);
				else {
					array.push([idx_next,image]);
					that._append_images(idx_next,count-1,array,cb_ok,cb_err);
				}
			},cb_err);
	}

	find_many_images(idx,count,cb_ok,cb_err) {
		this._append_images(idx,count,[],cb_ok,cb_err);
	}
}

// === SingletonLazyLoad (mixin) =========================

const SingletonLazyLoad=(superclass) => class extends superclass {
	constructor(marker) {
		super(); // all arguments are set to undefined
		this.reached_begin=false;
		this.marker_begin=marker;
	}

	find_next_image(idx,cb_ok,cb_err) {
		if (this.marker_begin===null || this.marker_end!==null)
			super.find_next_image(idx,cb_ok,cb_err);
		else
			tx_util.api_request("get_image",this.marker_begin,(res,err) => {
				if (err) {
					if (err.code===20) toast("Η εικόνα δεν υπάρχει");
					if (cb_err!==undefined) cb_err();
					return;
				}
				if ("entry" in this) this.entry=res.entry;
				this._init_from_image(res);
				this.marker_end=this.marker_begin;
				this.images.push(this._prepare_image(res));
				cb_ok(0,res);
			});
	}

	_init_from_image(image) {
		this.foodmenu=image.foodmenu;
	}
}

// === ImageCollectionSingleton =========================

export class ImageCollectionSingleton extends SingletonLazyLoad(ImageCollection) {
	_download(marker,count,cb) {
		cb([],null);
	}
}

// === ImageCollectionEntry =========================

export class ImageCollectionEntry extends ImageCollection {
	constructor(entry,foodmenu) {
		super();
		this.entry=entry;
		this.foodmenu=!!foodmenu;
	}

	static get context() {
		return "entry";
	}

	get_entry(idx) {
		if (idx!==undefined) {
			var image=this.images[idx+this.offset];
			if (image!==undefined) {
				var entry=image.entry;
				if (entry!==undefined && entry.name!=undefined)
					return entry;
			}
		}
		return this.entry;
	};

	_download(marker,count,cb) {
		tx_util.api_request("get_images",this.entry.eid,this.entry.etype,this.foodmenu,marker,count,cb);
	}
}

// === ImageCollectionEntryMarker =========================

export class ImageCollectionEntryMarker extends SingletonLazyLoad(ImageCollectionEntry) {
}

// === ImageCollectionRecent =========================

export class ImageCollectionRecent extends ImageCollection{
	constructor(foodmenu) {
		super();
		this.foodmenu=!!foodmenu;
	}

	static get context() {
		return "recent";
	}

	_download(marker,count,cb) {
		tx_util.api_request("get_recent_images",this.foodmenu,marker,count,cb);
	}
}

// === ImageCollectionRecentMarker =========================

export class ImageCollectionRecentMarker extends SingletonLazyLoad(ImageCollectionRecent) {
}

// === ImageCollectionPending =========================

export class ImageCollectionPending extends ImageCollection{
	constructor(foodmenu) {
		super();
		this.foodmenu=!!foodmenu;
	}

	static get context() {
		return "pending";
	}

	_download(marker,count,cb) {
		tx_util.api_request("get_pending_images",this.foodmenu,marker,count,cb);
	}
}

// === ImageCollectionPendingMarker =========================

export class ImageCollectionPendingMarker extends SingletonLazyLoad(ImageCollectionPending) {
}

// === ImageCollectionUser =========================

export class ImageCollectionUser extends ImageCollection{
	constructor(user_id,foodmenu) {
		super();
		this.user_id=user_id;
		this.foodmenu=!!foodmenu;
	}

	static get context() {
		return "user";
	}

	_download(marker,count,cb) {
		if (this.user_id==null)
			cb([],null);
		else
			tx_util.api_request("get_images_by_user",this.user_id,this.foodmenu,marker,count,cb);
	}
}

// === ImageCollectionUserMarker =========================

export class ImageCollectionUserMarker extends SingletonLazyLoad(ImageCollectionUser) {
	_init_from_image(image) {
		super._init_from_image(image);
		this.user_id=(image.user||{}).id;
	}
}

// === ImageCollectionRandom =========================

export class ImageCollectionRandom extends ImageCollection {
	constructor(foodmenu) {
		super();
		this.foodmenu=!!foodmenu;
	}

	static get context() {
		return "random";
	}

	_download(marker,count,cb) {
		tx_util.api_request("get_random_images",this.foodmenu,Math.abs(count),cb);
	}

}

// === ImageCollectionRandomMarker =========================

export class ImageCollectionRandomMarker extends SingletonLazyLoad(ImageCollectionRandom) {
}

// === collection_from_name =========================

const collection_classes_by_context={};
for (const collection_constructor of [
	ImageCollectionEntryMarker,
	ImageCollectionRecentMarker,
	ImageCollectionPendingMarker,
	ImageCollectionUserMarker,
	ImageCollectionRandomMarker,
]) {
	collection_classes_by_context[collection_constructor.context]=collection_constructor;
}

export function collection_from_name(name,context,cb,cb_err) {
	const collection=new (collection_classes_by_context[context]||ImageCollectionSingleton)(name);
	collection.find_next_image(undefined,(idx,image) => {
		cb(collection,idx,image);
	},cb_err);
}

// === Gallery =========================

var default_page_size=12;

var thumb_template=
	'<div class="thumb">'+
		'<div class="thumb-inner">'+
			'<a class="image-container"></a>'+
			'<div class="info">'+
				'<div class="caption-outer">'+
					'<div class="caption"></div>'+
					'<div class="caption-fade"></div>'+
				'</div>'+
				'<div class="user"><a></a></div>'+
				'<div class="entry-name"><a></a></div>'+
				'<div class="moderation">'+
					'<button class="approve"></button>'+
					'<button class="reject"></button>'+
				'</div>'+
			'</div>'+
		'</div>'+
	'</div>';

// the node will be replaced with a new one
export function Gallery(node,collection) {
	var that=this;
	this.$thumbs_container=$("<div>").addClass("thumbs-container");
	const intersection_target_div=$("<div>").addClass("intersection-target")[0];
	this.$loading_div=$("<div>").addClass("loading").css("display","none");
	var $gallery_div=$("<div>").addClass("gallery").append(this.$thumbs_container,intersection_target_div,this.$loading_div);
	this.gallery_div=$gallery_div[0];
	this.collection=collection;
	this.last_idx=undefined;
	this.is_finished=false;
	this.retrier=new Retrier();
	var page_size=default_page_size;
	var entry=collection.get_entry();
	if (entry) $gallery_div.addClass(".single-entry");
	if (entry!==undefined && tx_accounts.can("upload_images")) {
		const on_new_image_uploaded=new_image => {
			const idx=collection.prepend_image(new_image);
			that._add_image(idx,new_image);
		};
		const uploader=() => new tx_upload_overlay.Uploader(collection.entry.eid,collection.entry.etype,collection.foodmenu,on_new_image_uploaded);
		const $plus_div=$("<div>").addClass("plus").attr("title","Προσθήκη εικόνων")
		.click(function() {
			uploader().select_files();
		})
		.on("dragenter dragover",ev => {
			ev.preventDefault();
			ev.stopPropagation();
			const dt=ev.originalEvent.dataTransfer;
			if (Array.from(dt.types).some(t => t===tx_upload_overlay.drag_mime_type || t==="Files")) {
				$plus_div.addClass("dragover");
				dt.dropEffect="copy";
			}
			else
				dt.dropEffect="none";
		})
		.on("dragleave",ev => {
			ev.preventDefault();
			$plus_div.removeClass("dragover");
		})
		.on('drop',ev => {
			ev.preventDefault();
			$plus_div.removeClass("dragover");
			const dt=ev.originalEvent.dataTransfer;
			if (Array.from(dt.types).some(t => t===tx_upload_overlay.drag_mime_type || t==="Files"))
				uploader().add_files(dt.files);
		})

		.appendTo($("<div>").addClass("thumb add-files").prependTo(this.$thumbs_container));
		page_size--;
	}
	$gallery_div.replaceAll(node);
	$("<div>").addClass("tabs-script onready").appendTo($gallery_div)[0]._callback=tabs_div => {
		const observer=new IntersectionObserver(entries => {
			for (const entry of entries) {
				if (entry.isIntersecting && !that.is_finished)
					that.show_next_page(default_page_size);
			}
		},{root:tabs_div.querySelector(".tabs_body")});
		that._enable_inf_scroll=() => {observer.observe(intersection_target_div)};
		that._disable_inf_scroll=() => {observer.unobserve(intersection_target_div)};
		that.show_next_page(page_size);
	};
}

Gallery.prototype.show_next_page=function(page_size) {
	this._disable_inf_scroll();
	this.$loading_div.show();
	this.collection.find_many_images(this.last_idx,page_size,(idx,images,is_last) => {
		this.retrier.success();
		this.last_idx=idx;
		for (const image of images)
			this._add_image(image[0],image[1],true);
		this.$loading_div.hide();
		if (is_last)
			this.is_finished=true;
		else
			this._enable_inf_scroll();
	},() => {
		this.retrier.retry().then(() => {
			this._enable_inf_scroll();
		});
	});
}

Gallery.prototype._add_image=function(idx,image,append) {
	var that=this;
	var $thumb_div=$(thumb_template);
	var thumb_div=$thumb_div[0];
	var $thumb_inner_div=$(".thumb-inner",thumb_div);
	var image_container=$(".image-container",thumb_div)[0];
	image_container.href=that.collection.get_permalink(idx);
	const img=new Image();
	img.addEventListener("load",() => {
		image_container.append(img);
		getComputedStyle(img).opacity; // force the value before the transition
		img.style.opacity="";
	},{once:true});
	img.style.opacity=0;
	var $caption_outer=$(".caption-outer",thumb_div);
	var $caption=$(".caption",thumb_div);
	var $user_a=$(".user a",thumb_div);
	var $entry_a=$(".entry-name a",thumb_div);
	const moderation_div=$(".moderation",thumb_div)[0];
	var btn_approve=$(".approve",moderation_div)[0];
	var btn_reject=$(".reject",moderation_div)[0];
	var forced_hover_timeout_id;
	function activate_hover_effect() {
		if (forced_hover_timeout_id!==undefined)
			clearTimeout(forced_hover_timeout_id);
		forced_hover_timeout_id=undefined;
		$caption_outer.toggleClass("overflown",$caption_outer.height()<$caption.height());
		$thumb_inner_div.css("top",$thumb_div.height()-$thumb_inner_div.height());
		$thumb_div.addClass("open");
	}
	function stop_hover_effect() {
		if (forced_hover_timeout_id===undefined) {
			$thumb_inner_div.css("top","0");
			$thumb_div.removeClass("open");
		}
	}
	function scheduled_hover_stop(time) {
		forced_hover_timeout_id=setTimeout(function() {
			forced_hover_timeout_id=undefined;
			stop_hover_effect();
		},time);
	}
	thumb_div.addEventListener("mouseenter",activate_hover_effect);
	thumb_div.addEventListener("mouseleave",stop_hover_effect);
	thumb_div.addEventListener("click",function(ev) {
		ev.preventDefault();
		if (ev.target.matches(".caption a,.caption a *"))
			return;
		if (forced_hover_timeout_id!==undefined)
			clearTimeout(forced_hover_timeout_id);
		tx_img_overlay.show(that.collection,idx,image_container);
		scheduled_hover_stop(150);
	});
	tx_util.listen_for_taps(thumb_div,{passive:false},function(ev,touch) {
		ev.preventDefault();
		if (ev.target.matches(".moderation button,.moderation button *,.user a,.user a *,.entry-name a,.entry-name a *,.caption a,.caption a *"))
			ev.target.click();
		else if (forced_hover_timeout_id===undefined) {
			activate_hover_effect();
			scheduled_hover_stop(5000);
		}
		else {
			activate_hover_effect();
			tx_img_overlay.show(that.collection,idx,image_container);
			scheduled_hover_stop(150);
		}
	});
	const set_approval=(approval,ev) => {
		if (ev) {
			ev.stopPropagation();
			ev.preventDefault();
		}
		$("button",moderation_div).prop("disabled",true);
		tx_util.api_request("set_image_approval",image.name,approval,function(res,err) {
			if (err)
				toast("Η αλλαγή απέτυχε\n"+JSON.stringify(err));
			else {
				image.approval=approval;
				image._updated();
			}
		});
	};
	$(btn_approve).click(ev => {set_approval(1,ev)});
	$(btn_reject).click(ev => {set_approval(-1,ev)});
	image._on_update.push(() => {
		const thumb_url=that.collection.get_thumb_url(idx);
		if (img.src!==thumb_url) {
			img.src=thumb_url;
			const image_rect=tx_util.fit_rect(200,200,image.resolution.l[0],image.resolution.l[1],true);
			delete image_rect.scale;
			$(img).css(image_rect);
		}
		$caption.html(image.caption_html);
		$user_a.attr("data-username",(image.user||{}).name||"").prop("href",image.user ? "?p=u"+image.user.id+"&t=img" : "");
		$user_a[0].onclick=ev => {
			ev.preventDefault();
			ev.stopPropagation();
			if (image.user)
				tx_infobox.browse("u"+image.user.id,"img");
		};
		if (image.entry) {
			const entry_page=(image.entry.etype===0 ? "t" : "c")+image.entry.eid;
			$entry_a.attr("data-entry",image.entry.name).prop("href",`?p=${entry_page}`);
			$entry_a[0].onclick=ev => {
				ev.preventDefault();
				ev.stopPropagation();
				tx_infobox.browse(entry_page);
			};
		}
		btn_approve.disabled=image.approval===1;
		btn_reject.disabled=image.approval===-1;
		if (image.approval==null)
			thumb_div.removeAttribute("data-approval");
		else
			thumb_div.setAttribute("data-approval",image.approval<=-1 ? "rejected" : image.approval===0 ? "pending" : "approved");
	});
	image._updated();
	image._on_remove.push(() => {
		$thumb_div.css("width"); // force the value before the transition
		$thumb_div.css("width",0);
		setTimeout(function() {
			$thumb_div.remove();
		},250);
	});
	if (append)
		this.$thumbs_container.append(thumb_div);
	else {
		$thumb_div.css("width",0);
		var first_thumb_div=this.$thumbs_container[0].firstChild;
		if (first_thumb_div && $(first_thumb_div).hasClass("add-files"))
			$thumb_div.insertAfter(first_thumb_div);
		else
			$thumb_div.prependTo(this.$thumbs_container);
		$thumb_div.css("width"); // force the value before the transition
		$thumb_div.css("width","");
	}
	return thumb_div;
}
