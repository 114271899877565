import * as tx_util from "./util";
import * as tx_accounts from "./accounts";
import * as tx_map from "./map";
import * as tx_geo from "./geo";
import {toast} from "./toast";

let points_by_id=[];
let points_by_z=[];
let points_have_statuses;
const user_entry_status_values=[];
let points_ready;
let db;
let updating_indexedDB=Promise.resolve();
let notify_others=tx_util.do_nothing;
const update_interval=15*60*1000;
const supports_BroadcastChannel="BroadcastChannel" in window;
const bcast_admin_counts=supports_BroadcastChannel ? new BroadcastChannel("admin_counts") : undefined;

export function add_point(point) {
	return points_ready.then(() => {
		if (point.x!=null)
			point.z=tx_geo.zcoords_from_xy(point.x,point.y);
		if (db) {
			const points_store=db.transaction("points","readwrite").objectStore("points");
			if (point.deleted)
				points_store.delete(point.id);
			else
				points_store.put(point);
		}
		else {
			const id=point.id;
			const len=points_by_id.length;
			const idx=tx_util.binary_search(points_by_id,"id",id);
			if (idx<len && points_by_id[idx].id===id) {
				let idx_z=tx_util.binary_search(points_by_z,"z",points_by_id[idx].z);
				while (idx_z<len && points_by_z[idx_z].id!==id)
					idx_z++;
				if (idx_z>=len) {
					toast("Κάτι πήγε στραβά, ξαναφορτώστε τη σελίδα",Infinity);
					throw new Error("Not found");
				}
				if (point.deleted) {
					points_by_id.splice(idx,1);
					points_by_z.splice(idx_z,1);
				}
				else {
					points_by_id[idx]=point;
					points_by_z[idx_z]=point;
				}
			}
			else if (!point.deleted) {
				points_by_id.splice(idx,0,point);
				points_by_z[len]=point;
			}
			if (!point.deleted)
				points_by_z.sort((p1,p2) => p1.z-p2.z);
		}
		tx_map.refresh();
		notify_others();
	});
}

export function refresh_point(id) {
	tx_util.api_request("get_point",id,0,function(res,err) {
		if (res)
			add_point(res);
		else if (err.code===17)
			add_point({id,deleted:true});
		else
			toast("refresh_point failed: "+JSON.stringify(err));
	});
}

function download_points(from_time) {
	return new Promise((resolve,reject) => {
		let form_data;
		if (from_time!=null) form_data={from_time};
		tx_util.http_post_form("action/points",form_data,function(doc,code) {
			if (code!=200) return reject("Could not download points");
			const rows=doc.split("\n");
			const marker=rows.shift();
			if (rows.length>0 && rows[rows.length-1]==="") rows.pop();
			const len=rows.length;
			const new_points=new Array(len);
			for (let i=0; i<len; i++) {
				const match=/^([A-Za-z0-9+\/]*)\|([A-Za-z0-9+\/]*)\|([A-Za-z0-9+\/]*)\|([A-Za-z0-9+\/]*)\|([A-Za-z0-9+\/]*)\|(.+)$/.exec(rows[i])
					?? /^([A-Za-z0-9+\/]*)$/.exec(rows[i]);
				if (!match)
					return reject("Cannot parse points file");
				const fields=match.slice(1);
				if (fields.length===1)
					new_points[i]={id:decode_int(fields[0]),deleted:true};
				else if (fields.length!==6)
					return reject("Cannot parse points file");
				else {
					const x=decode_int(fields[1]);
					const y=decode_int(fields[2]);
					new_points[i]={
						id:decode_int(fields[0]),
						x,y,
						z:tx_geo.zcoords_from_xy(x,y),
						categories:decode_int(fields[3]),
						flags:decode_int(fields[4]),
						tname:fields[5]
					};
				}
			}
			resolve([new_points,marker]);
		});
	});
}

function update_indexedDB_now() {
	return new Promise((resolve,reject) => {
		db.transaction("metadata").objectStore("metadata").get("points").onsuccess=ev => {
			download_points((ev.target.result||{}).marker).then(([modified_points,marker]) => {
				const transaction=db.transaction(["points","statuses","metadata"],"readwrite");
				transaction.onerror=transaction.onabort=ev => {
					reject(ev.target.error);
				};
				transaction.oncomplete=() => {
					tx_map.refresh();
					resolve();
					notify_others();
				};
				const points_store=transaction.objectStore("points");
				const statuses_store=transaction.objectStore("statuses");
				const len=modified_points.length;
				let points_left=modified_points.length;
				const done_processing_point=() => {
					if (--points_left<=0) {
						transaction.objectStore("metadata").put({marker},"points");
					}
				};
				for (const point of modified_points) {
					if (point.deleted) {
						points_store.delete(point.id);
						done_processing_point();
					}
					else {
						statuses_store.get(point.id).onsuccess=ev => {
							const status=ev.target.result;
							if (status) point.status=status;
							try {
								points_store.put(point).onerror=ev => {
									reject(ev.target.error);
									transaction.abort();
								};
							}
							catch (err) {
								reject(err);
								transaction.abort();
							}
							done_processing_point();
						};
					}
				}
			},reject);
		};
	});
}

function update_indexedDB() {
	return updating_indexedDB=updating_indexedDB.finally(() => {
		const p=update_indexedDB_now();
		p.catch(err => {
			let err_msg;
			try {
				err_msg=err.toString();
			}
			catch (e) {
			}
			tx_util.api_request_promise("debug_report",JSON.stringify({
				where:"update_indexedDB",
				error:err_msg,
			}));
		});
		return p;
	});
}

export function reset_points_db(no_reload) {
	if (db) {
		const transaction=db.transaction(["metadata","points","statuses"],"readwrite");
		transaction.oncomplete=() => {
			tx_map.refresh();
			notify_others();
			if (!no_reload)
				update_indexedDB().then(() => load_user_entry_statuses());
		};
		for (const store_name of transaction.objectStoreNames)
			transaction.objectStore(store_name).clear();
	}
}

function use_indexedDB() {
	update_indexedDB();
}

function use_array() {
	download_points().then(([points_new]) => {
		points_by_id=points_new.filter(p => !p.deleted).slice().sort((p1,p2) => p1.id-p2.id);
		points_by_z=points_new.sort((p1,p2) => p1.z-p2.z);
		points_have_statuses=false;
		tx_map.refresh();
	});

	// remove this after 2018-06-01:
	if ("indexedDB" in window) {
		try {
			indexedDB.deleteDatabase("points").onerror=ev => {
				ev.preventDefault();
			};
		}
		catch(err) {}
	}
}

function db_delete_statuses(transaction,cb) {
	transaction.objectStore("metadata").delete("status");
	transaction.objectStore("statuses").clear();
	transaction.objectStore("points").index("status").openCursor().onsuccess=ev => {
		const cursor=ev.target.result;
		if (!cursor)
			cb();
		else {
			delete cursor.value.status;
			cursor.update(cursor.value);
			cursor.continue();
		}
	};
}

function load_user_entry_statuses() {
	return new Promise((resolve,reject) => {
		if (db) {
			if (!tx_accounts.is_logged_in()) {
				const transaction=db.transaction(["points","statuses","metadata"],"readwrite");
				transaction.onerror=transaction.onabort=() => {reject()};
				db_delete_statuses(transaction,() => {
					resolve();
					tx_map.refresh();
				});
			}
			else {
				const user_id=tx_accounts.get_user_id();
				db.transaction("metadata").objectStore("metadata").get("status").onsuccess=ev => {
					const metadata=ev.target.result;
					const marker=(metadata && metadata.user===user_id && metadata.marker)||null;
					tx_util.api_request("get_user_entry_statuses",marker,res => {
						if (!res) return reject();
						const transaction=db.transaction(["points","statuses","metadata"],"readwrite");
						transaction.onerror=transaction.onabort=() => {reject()};
						transaction.oncomplete=() => {
							resolve();
							tx_map.refresh();
						};
						const write_new_values=() => {
							const points_store=transaction.objectStore("points");
							const statuses_store=transaction.objectStore("statuses");
							for (const t of res.data) {
								if (t.etype===0 && t.value!==0) {
									statuses_store.put(t.value,t.eid);
									points_store.get(t.eid).onsuccess=ev => {
										const point=ev.target.result;
										if (point) {
											point.status=t.value;
											points_store.put(point);
										}
									};
								}
							}
							transaction.objectStore("metadata").put({user:user_id,marker:res.marker},"status");
						};
						if (metadata && metadata.user!==user_id)
							db_delete_statuses(transaction,write_new_values);
						write_new_values();
					});
				};
			}
		}
		else {
			if (!tx_accounts.is_logged_in()) {
				if (points_have_statuses) {
					for (const point of points_by_id)
						delete point.status;
					points_have_statuses=false;
					tx_map.refresh();
				}
				resolve();
			}
			else {
				tx_util.api_request("get_user_entry_statuses",null,res => {
					if (points_have_statuses) {
						for (const point of points_by_id)
							delete point.status;
						points_have_statuses=false;
					}
					if (!res)
						reject();
					else {
						for (const t of res.data) {
							if (t.etype===0 && t.value!==0) {
								const point=array_get_point(t.eid);
								if (point) point.status=t.value;
							}
						}
						points_have_statuses=true;
					}
					resolve();
					tx_map.refresh();
				});
			}
		}
	});
}

export function add_check(eid,etype,entry_check,hours_check) {
	return tx_util.api_request_promise("add_check",eid,etype,entry_check,hours_check);
}

export function delete_entry_check(id,eid,etype) {
	return tx_util.api_request_promise("delete_entry_check",id);
}

export function delete_hours_check(id) {
	return tx_util.api_request_promise("delete_hours_check",id);
}

let pending_entry_check_requests=new Map();
let pending_entry_check_requests_timeout;

export function get_last_entry_check(id) {
	return new Promise((resolve) => {
		const resolves=pending_entry_check_requests.get(id);
		if (resolves!==undefined)
			resolves.push(resolve);
		else
			pending_entry_check_requests.set(id,[resolve]);
		if (pending_entry_check_requests_timeout===undefined) {
			pending_entry_check_requests_timeout=setTimeout(() => {
				const requests=pending_entry_check_requests;
				pending_entry_check_requests=new Map();
				pending_entry_check_requests_timeout=undefined;
				tx_util.api_request_retry("get_last_entry_check_many",Array.from(requests.keys())).then((res) => {
					for (const r of res) {
						const resolves=requests.get(r.eid);
						if (resolves) {
							for (const resolve of resolves)
								resolve(r);
						}
						requests.delete(r.eid);
					}
					for (const resolves of requests.values()) {
						for (const resolve of resolves)
							resolve(null);
					}
				});
			},10);
		}
	});
}

export function set_user_entry_status(eid,etype,value,node) {
	tx_util.api_request("set_user_entry_status",eid,etype,value,function(res,err) {
		if (err)
			toast("Απέτυχε: "+JSON.stringify(err),Infinity);
		else if (node) {
			var $tr=$(node).closest(".tabs_page_content").find(".user-entry-status");
			$(".status",$tr).text(user_entry_status_values[value]);
			$tr.toggleClass(function(i,classes) {
				var r=[];
				for (var c of classes.split(" "))
					if (/^status-/.test(c)) r.push(c);
				return r.join(" ");
			},false).addClass("status-"+value);
			if (etype===0) {
				if (db) {
					const transaction=db.transaction(["points","statuses"],"readwrite");
					const statuses_store=transaction.objectStore("statuses");
					if (value===0)
						statuses_store.delete(eid);
					else
						statuses_store.put(value,eid);
					const points_store=transaction.objectStore("points");
					points_store.get(eid).onsuccess=ev => {
						const point=ev.target.result;
						if (point) {
							if (value===0)
								delete point.status;
							else
								point.status=value;
							points_store.put(point);
							tx_map.refresh();
						}
					};
				}
				else {
					const point=array_get_point(eid);
					if (point) {
						if (value===0)
							delete point.status;
						else {
							point.status=value;
							points_have_statuses=true;
						}
						tx_map.refresh();
					}
				}
			}
		}
	});
}

export function set_billable(eid,etype,value,node) {
	tx_util.api_request("set_billable",eid,etype,value,function(res,err) {
		if (err)
			toast("failed: "+JSON.stringify(err),Infinity);
		else if (node)
			$(node).closest(".tabs_page_content").find(".billable").text(JSON.stringify(value));
	});
}

function decode_int(s) {
	var res=0;
	var l=s.length;
	for (var i=0; i<l; i++) {
		var c=s.charCodeAt(i);
		var d=
			c>=65 && c<=90 ? c-65 :
			c>=97 && c<=122 ? c-71 :
			c>=48 && c<=57 ? c+4 :
			c==43 ? 62 :
			c==47 ? 63 :
			64;
		if (d===64) throw "Invalid int";
		res=(res<<6)|d;
	}
	return res;
}

export function range_search([z_min,z_max]) {
	if (db) {
		return new Promise(resolve => {
			db.transaction("points").objectStore("points").index("z").getAll(IDBKeyRange.bound(z_min,z_max)).onsuccess=ev => {
				resolve(ev.target.result);
			};
		});
	}
	else {
		const imin=tx_util.binary_search(points_by_z,"z",z_min);
		let imax=imin;
		const len=points_by_z.length;
		while (imax<len && points_by_z[imax].z<=z_max) imax++;
		return Promise.resolve(points_by_z.slice(imin,imax));
	}
}

export function rect_search(west,north,east,south,predicate=() => true,count=1<<20) {
	const height=south-north;
	if (height<0) return Promise.resolve([]);
	let width=east-west;
	if (width<0) width+=tx_geo.xy_map_width;
	const level_adjustment=Math.max(
		tx_geo.xy_bit_width-tx_geo.max_z_level,
		(Math.log(Math.min(width,height))/Math.LN2|0)-1
	);
	const level=tx_geo.xy_bit_width-level_adjustment;
	const x_bnd=1 << level;
	const x_min=west  >> level_adjustment;
	const x_max=east  >> level_adjustment;
	const y_min=north >> level_adjustment;
	const y_max=south >> level_adjustment;
	const result=[];
	const regular=west<=east;
	return new Promise(resolve => {
		let x_cur=x_min;
		let y_cur=y_min;
		(function loop() {
			range_search(tx_geo.zrange_from_xy(x_cur,y_cur,level)).then(ps => {
				const n=ps.length;
				for (let i=0; i<n; i++) {
					const p=ps[i];
					if (((west<=p.x && p.x<=east) || (!regular && (west<=p.x || p.x<=east))) && north<=p.y && p.y<=south && predicate(p)) {
						result.push(p);
						if (--count<=0)
							return resolve(result);
					}
				}
				if (y_cur<y_max)
					y_cur++;
				else if (x_cur!==x_max) {
					x_cur=(x_cur+1)%x_bnd;
					y_cur=y_min;
				}
				else
					return resolve(result);
				loop();
			});
		})();
	});
}

export function radius_search(x,y,radius,predicate,count) {
	const west=(x-radius+tx_geo.xy_map_width)%tx_geo.xy_map_width;
	const east=(x+radius)%tx_geo.xy_map_width;
	const north=Math.max(0,y-radius);
	const south=Math.min(tx_geo.xy_map_width-1,y+radius);
	const radius_sq=radius*radius;
	const predicate2=p => {
		const dx=p.x-x;
		const dy=p.y-y;
		return dx*dx+dy*dy<radius_sq && predicate(p);
	};
	return rect_search(west,north,east,south,predicate2);
}

// only works if the array backend is being used
function array_get_point(id) {
	const idx=tx_util.binary_search(points_by_id,"id",id);
	const point=points_by_id[idx];
	if (point && point.id===id)
		return point;
}

const counters = [
	["comments_moderation"],
	["images_moderation"],
	["pending_edits"],
	["entries_pending_closure"],
	["street_view_unavailable"],
	["pending_entries",true],
	["audio_notes"],
	/*["etable",*/
];

function update_admin_task_counts(counts) {
	let total_count=0;
	for (const [counter,do_not_include_in_total] of counters) {
		const count=counts[counter];
		if (count!=null) {
			if (!do_not_include_in_total)
				total_count+=count;
			for (const elt of document.querySelectorAll('[data-counter="'+counter+'"]'))
				elt.setAttribute("data-count",count);
		}
	}
	for (const elt of document.querySelectorAll('[data-counter="total"]'))
		elt.setAttribute("data-count",total_count);
}

function refresh_admin_task_counts() {
	return (
		(tx_accounts.can("edit_comments") || tx_accounts.can("edit_images") || tx_accounts.can("edit_entries"))
			? tx_util.api_request_promise("get_notification_counts")
				.then(counts => {
					if (bcast_admin_counts) bcast_admin_counts.postMessage(counts);
					return counts;
				})
				.then(update_admin_task_counts)
			: Promise.resolve()
	);
}

function start_periodic_updates() {
	let timeout_id;
	let idle=true;
	const bcast_chan=supports_BroadcastChannel ? new BroadcastChannel("periodic_update") : undefined;
	let last_update_time=performance.now();
	const reset_timer=() => {
		if (timeout_id!==undefined) {
			clearTimeout(timeout_id);
			timeout_id=undefined;
		}
		if (!document.hidden && idle) {
			const time=Math.max(10*1000,last_update_time+update_interval-performance.now());
			timeout_id=setTimeout(() => {
				timeout_id=undefined;
				idle=false;
				if (bcast_chan) bcast_chan.postMessage(null);
				update_indexedDB().catch(() => undefined)
					.then(() => load_user_entry_statuses()).catch(() => undefined)
					.then(() => refresh_admin_task_counts()).catch(() => undefined)
					.then(() => {
						last_update_time=Math.max(last_update_time,performance.now());
						idle=true;
						reset_timer();
					});
			},time);
		}
	};
	document.addEventListener("visibilitychange",reset_timer);
	if (bcast_chan) {
		bcast_chan.onmessage=() => {
			last_update_time=performance.now()+30000;
			reset_timer();
		};
	}
	reset_timer();
}

export function init() {
	for (let x of _user_entry_status_values)
		user_entry_status_values[x.id]=x.name;
	delete window._user_entry_status_values;

	points_ready=new Promise(resolve => {
		if ("IDBIndex" in window && IDBIndex.prototype.getAll) {
			const open_req=indexedDB.open("points",2);
			open_req.onerror=ev => {
				ev.preventDefault();
				use_array();
				resolve();
			};
			open_req.onupgradeneeded=() => {
				const db=open_req.result;
				for (let i=0; i<db.objectStoreNames.length; i++)
					db.deleteObjectStore(db.objectStoreNames[i]);
				db.createObjectStore("metadata");
				const points_store=db.createObjectStore("points",{keyPath:"id"});
				points_store.createIndex("z","z");
				points_store.createIndex("status","status");
				db.createObjectStore("statuses");
			};
			open_req.onsuccess=() => {
				db=open_req.result;
				db.onversionchange=() => {
					// TODO: notify the user that they have to reload this page
					db.close();
				};
				resolve(true);
				use_indexedDB();
			};
		}
		else {
			use_array();
			resolve();
		}
	});

	points_ready.then(uses_idb => {
		if (uses_idb) {
			if (supports_BroadcastChannel) {
				const chan=new BroadcastChannel("points_updated");
				chan.onmessage=() => {tx_map.refresh()};
				notify_others=() => {chan.postMessage(null)};
			}
			if ("hidden" in document)
				start_periodic_updates();
		}

		load_user_entry_statuses();
		tx_accounts.on_auth_change(load_user_entry_statuses);
	});

	if (bcast_admin_counts) {
		bcast_admin_counts.onmessage=ev => {
			update_admin_task_counts(ev.data);
		};
	}
	tx_accounts.on_auth_change(() => {
		// set total count to 0:
		update_admin_task_counts({});
		refresh_admin_task_counts();
	});
}
