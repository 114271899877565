// for newer engines:
//const match_unsafe_chars = /[^-_.0-9\p{Letter}]+/ug;
// for older engines (contains letters for a few basic unicode blocks):
const match_unsafe_chars = /[^-_.0-9A-Za-z\u00aa-\u00aa\u00b5-\u00b5\u00ba-\u00ba\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u0374\u0376-\u0377\u037a-\u037d\u037f-\u037f\u0386-\u0386\u0388-\u038a\u038c-\u038c\u038e-\u03a1\u03a3-\u03f5\u03f7-\u0481\u048a-\u1f15\u1f18-\u1f1d\u1f20-\u1f45\u1f48-\u1f4d\u1f50-\u1f57\u1f59-\u1f59\u1f5b-\u1f5b\u1f5d-\u1f5d\u1f5f-\u1f7d\u1f80-\u1fb4\u1fb6-\u1fbc\u1fbe-\u1fbe\u1fc2-\u1fc4\u1fc6-\u1fcc\u1fd0-\u1fd3\u1fd6-\u1fdb\u1fe0-\u1fec\u1ff2-\u1ff4\u1ff6-\u1ffc]+/g;

export function normalize_uri_frag(str) {
	try {
		return decodeURIComponent(str).replace(match_unsafe_chars, encodeURIComponent);
	}
	catch {
		return str;
	}
}

function normalize_uri_path(str) {
	return str.split("/").map(normalize_uri_frag).join("/");
}

function normalize_facebook_url(url) {
	const m = url.match(/^https?:\/\/(?:[^/.?#]+\.)?facebook\.com\/(.+)/);
	if (!m)
		return;
	let tail = m[1];
	const profile_id = (tail.match(/^profile\.php\?.*?\bid=(\d+)/) || [])[1];
	if (profile_id)
		return `https://www.facebook.com/profile.php?id=${profile_id}`;
	else {
		let path = normalize_uri_path(tail.replace(/#.*$/, "").replace(/\?.*$/, "")).replace(/\/$/, "");
		if (path) path += "/";
		return `https://www.facebook.com/${path}`;
	}
}

function normalize_instagram_url(url) {
	const m = url.match(/^https?:\/\/(?:www\.)?instagram\.com\/(.*)/);
	if (!m)
		return;
	let tail = m[1];
	const m2 = tail.match(/^([^/?#]+)\/?(?:$|[?#])/);
	if (m2)
		return `https://www.instagram.com/${normalize_uri_frag(m2[1])}/`;
	else
		return `https://www.instagram.com/${tail}`;
}

function normalize_business_site_url(url) {
	const m = url.match(/^https?:\/\/([^/?#]+)\.business\.site\/([^?#]*)((?:\?.*)?(?:#.*)?)$/);
	if (!m)
		return;
	return `https://${m[1]}.business.site/${normalize_uri_path(m[2] || "")}`;
}

function normalize_twitter_url(url) {
	const m = url.match(/^https?:\/\/(?:www\.)?twitter\.com\/(.*)/);
	if (!m)
		return;
	let tail = m[1];
	const m2 = tail.match(/^(?:#!\/)?([^/?#]+)\/?$/);
	if (m2)
		return `https://twitter.com/${normalize_uri_frag(m2[1])}`;
	else
		return `https://twitter.com/${tail}`;
}

function normalize_blogspot_url(url) {
	const m = url.match(/^https?:\/\/(?:www\.)?([^/?#]+)\.blogspot\.(?:com|gr|co\.uk)\/([^?#]*)((?:\?.*)?(?:#.*)?)$/);
	if (!m)
		return;
	return `https://${m[1]}.blogspot.com/${m[2]}${m[3] || ""}`;
}

export function normalize_url(url) {
	const m = url.match(/^(?:http(s)?:\/\/)?([^/?#]*)\/?([^?#]*)((?:\?.*)?(?:#.*)?)$/i);
	if (!m) return url;
	url = `http${m[1] ? "s" : ""}://${m[2]}/${m[3]}${m[4]}`;
	return normalize_facebook_url(url) ||
		normalize_instagram_url(url) ||
		normalize_business_site_url(url) ||
		normalize_twitter_url(url) ||
		normalize_blogspot_url(url) ||
		url;
}
