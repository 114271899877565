import * as tx_util from "./util";
import {mke} from "./mke";

const snackbar_div=document.querySelector("#snackbar");
const msg_div=snackbar_div.querySelector(".msg");
let queue=Promise.resolve();

// -----------------------------------------------------------------------------

let dismissed_cb;

snackbar_div.addEventListener("click",() => {
	if (dismissed_cb)
		dismissed_cb();
});

function add_action_btn(caption,cb) {
	const div=mke(".action",{"data-caption":caption});
	div.addEventListener("click",() => {
		if (cb) cb();
	});

	let mousedown=false;
	let touched=false;
	const update_touched=() => {
		div.classList.toggle("touched",mousedown||touched);
	};
	div.addEventListener("mousedown",ev => {
		if (ev.button===0) {
			mousedown=true;
			update_touched();
		}
	});

	div.addEventListener("mouseup",ev => {
		if (ev.button===0) {
			mousedown=false;
			update_touched();
		}
	});
	const on_touch=(ev) => {
		touched=ev.touches.length>0;
		update_touched();
	};
	div.addEventListener("touchstart",on_touch);
	div.addEventListener("touchend",on_touch);
	div.addEventListener("touchcancel",on_touch);
	snackbar_div.append(div);
}

// -----------------------------------------------------------------------------

export function snackbar(msg,{timeout=2500,actions=[],on_done}={}) {
	const snackbar_started=queue;
	queue=queue.catch(tx_util.do_nothing).then(() => {
		msg_div.textContent=msg;
		for (const div of snackbar_div.querySelectorAll(".action"))
			div.remove();
		const action_btns=actions.map(({caption,cb}) => {add_action_btn(caption,cb)});
		snackbar_div.style.display="";
		getComputedStyle(snackbar_div).height;
		snackbar_div.classList.add("shown");
		return new Promise(resolve => {
			dismissed_cb=resolve;
			if (isFinite(timeout))
				tx_util.sleep(200).then(() => tx_util.sleep_visible(timeout)).then(resolve);
		}).then(() => {
			dismissed_cb=undefined;
			snackbar_div.classList.remove("shown");
			return tx_util.sleep(200);
		}).then(() => {
			snackbar_div.style.display="none";
			if (on_done) on_done();
		});
	});
	return snackbar_started;
}
