import * as tx_util from "./util";
import {Retrier} from "./retrier";

//const supports_dragndrop=
//	"ondragstart" in document.createElement("div") && "ondrop" in document.createElement("div");

//const supports_html5_upload=
//	'File' in window && 'Blob' in window && 'slice' in File.prototype && 'onchange' in document.createElement("input");

export class UploadError extends Error {}

export class UploadHttpError extends UploadError {
	constructor(status) {
		super(`HTTP request failed with status: ${status}`);
		this.status=status;
	}
}

const _curr_job=Symbol();
const _retrier=Symbol();

class UploadQueue {
	constructor() {
		this[_curr_job]=Promise.resolve();
		this[_retrier]=new Retrier();
	}

	upload(blob,uri,aborter,on_progress=tx_util.do_nothing) {
		return this[_curr_job]=this[_curr_job].catch(() => null).then(() => new Promise((resolve,reject) => {
			const upload_data=(chunk_size,offset) => {
				if (aborter && aborter.aborted)
					return reject(new tx_util.RequestAborted());
				let chunk;
				let http_method;
				if (offset==null)
					http_method="GET";
				else if (offset>=blob.size)
					return resolve();
				else {
					http_method="POST";
					chunk=blob.slice(offset,offset+chunk_size,blob.type);
				}
				const st=performance.now();
				const xhr=tx_util.http_request(http_method,uri,chunk,null,(responseText,status) => {
					const en=performance.now();
					if (status===0 || (status>500 && status<600)) {
						this[_retrier].retry().then(() => {
							upload_data(chunk_size);
						});
					}
					else if (status!==200)
						reject(new UploadHttpError(status));
					else {
						if (http_method==="POST")
							this[_retrier].success();
						const res=JSON.parse(responseText);
						const time=en-st;
						const bytes_sent=res.bytes_received;
						on_progress(bytes_sent,bytes_sent/blob.size);
						chunk_size=Math.clamp(Math.round(chunk_size*3000/time),1024,1<<20);
						upload_data(chunk_size,bytes_sent);
					}
				},xhr => {
					xhr.timeout=48000;
					if (offset!=undefined) {
						xhr.setRequestHeader("X-TX-Upload-Offset",offset);
						if(xhr.upload) {
							xhr.upload.onprogress=ev => {
								const bytes_sent=offset+ev.loaded;
								on_progress(bytes_sent,bytes_sent/blob.size);
							}
						}
					}
				});
			};
			upload_data(65536,0);
		}));
	}
}

const upload_queue=new UploadQueue();

export async function upload(type,blob,...args/*,aborter,on_progress?*/) {
	const on_progress=args.length>0 && typeof args[args.length-1]==="function"
		? args.pop()
		: tx_util.do_nothing;
	const aborter=args.length>0 && args[args.length-1] instanceof tx_util.Aborter
		? args.pop()
		: new tx_util.Aborter();
	const upl=await tx_util.api_request_retry(`start_${type}_upload`,blob.size,aborter);
	await upload_queue.upload(blob,upl.location,aborter,on_progress);
	return await tx_util.api_request_retry(`finish_${type}_upload`,upl.id,...args,aborter);
}
