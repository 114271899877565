import * as tx_history from "./history";
import * as tx_menu from "./menu";
import * as tx_accounts from "./accounts";
import * as tx_tabs from "./tabs";
import * as tx_input_validation from "./input_validation";
import * as tx_autocomplete from "./autocomplete";
import {Retrier} from "./retrier";

export const ie_version=
	(function() {
		var s=navigator.userAgent;
		var m=/ Edge\/(\d+)(\.\d+)*( |$)/.exec(s) || /; Trident\/7\.0;.* rv:(11)\.0/.exec(s) || /; MSIE (\d+).0; /.exec(s);
		if (m) return parseInt(m[1]);
	})();

export const flexBasis=(() => {
	const style=document.createElement("div").style;
	for (const s of ["flexBasis","msFlexPreferredSize","webkitFlexBasis"])
		if (s in style) return s;
})();

const supports_localStorage="localStorage" in window && (() => {
	try {
		sessionStorage.setItem("0","");
		sessionStorage.removeItem("0");
	}
	catch (e) {
		return false;
	}
	return true;
})();

const storage_tab=supports_localStorage ? sessionStorage : create_memory_storage();
let storage;

function create_memory_storage() {
	const data={};
	return {
		getItem:(name) =>
			data.hasOwnProperty(name) ? data[name] : undefined,
		setItem(name,value) {data[name]=value},
		removeItem(name) {delete data[name]}
	};
}

export function get_value(name) {
	const value=storage.getItem(name);
	return value===undefined ? value : JSON.parse(value);
}

export function set_value(name,value) {
	storage.setItem(name,JSON.stringify(value));
};

export function delete_value(name) {
	storage.removeItem(name);
}

export function get_value_tab(name) {
	const value=storage_tab.getItem(name);
	return value===undefined ? value : JSON.parse(value);
}

export function set_value_tab(name,value) {
	storage_tab.setItem(name,JSON.stringify(value));
};

export function delete_value_tab(name) {
	storage_tab.removeItem(name);
}

export function image2canvas(img) {
	var canvas=document.createElement("canvas");
	canvas.width=img.width;
	canvas.height=img.height;
	canvas.getContext("2d").drawImage(img,0,0,canvas.width,canvas.height);
	return canvas;
}

export function load_image(url) {
	return new Promise((resolve,reject) => {
		const img=new Image();
		img.onerror=(ev) => {
			reject();
		};
		img.onload=() => {
			img.onload=null;
			resolve(img);
		};
		img.src=url;
	});
}

const _aborted=Symbol();
const _on_abort=Symbol();

export class Aborter {
	constructor() {
		this[_aborted]=false;
		this[_on_abort]=new Set();
	}

	abort() {
		if (!this[_aborted]) {
			this[_aborted]=true;
			for (const cb of this[_on_abort]) {
				this[_on_abort].delete(cb);
				try {
					cb();
				}
				catch (err) {
					console.error(err);
				}
			}
		}
	}

	get aborted() {
		return this[_aborted];
	}

	notify(cb) {
		this[_on_abort].add(cb);
		if (this[_aborted]) {
			Promise.resolve().then(() => {
				if (this[_on_abort].delete(cb))
					cb();
			});
		}
	}

	unnotify(cb) {
		this[_on_abort].delete(cb);
	}
}

export const select_none=
	window.getSelection ? function() {getSelection().removeAllRanges()} :
	document.selection ? function() {document.selection.empty()} :
	do_nothing;

export function blur() {
	this.focus();
	this.blur();
}

export function select_all_on_focus() {
	this.select();
}

export function filesize(x) {
	if (x===1)
		return "1 byte"
	else if (x<1024)
		return x+" bytes"
	else if (x<1048576)
		return (x/1024).toFixed(1)+" KiB"
	else
		return (x/1048576).toFixed(1)+" MiB"
}

// 1970-01-01 -> 0
// 1970-01-02 -> 1
// does not work correctly if year is between 0 and 99
function extract_day_number(date) {
	return Math.round(Date.UTC(date.getFullYear(),date.getMonth(),date.getDate())/86400000);
}

// returns t2-t1 in days
export function diff_days(t1,t2) {
	return extract_day_number(t2)-extract_day_number(t1);
}

function convert_timestamps(node) {
	for (const elt of node.querySelectorAll("time.timestamp")) {
		const timestamp=new Date(elt.getAttribute("datetime"));
		const days=diff_days(new Date(),timestamp);
		const d0=timestamp.getDate()+"/"+(timestamp.getMonth()+1)+"/"+timestamp.getFullYear();
		const d=days===0 ? "Σήμερα" : days===-1 ? "Χθες" : days===1 ? "Αύριο" : d0;
		const h=timestamp.getHours();
		const m=timestamp.getMinutes();
		const t=(h===1 ? " στη " : " στις " )+(h+"").padStart(2,"0")+":"+(m+"").padStart(2,"0");
		elt.textContent=d+t;
		if (Math.abs(days)<=1) elt.title=d0+t;
	}
}

function create_popup_menus(node) {
	$(".menu_bar.auto",node).each(function() {
		var targets=$(".menu_target",this);
		var menus=$(".menu",this);
		new tx_menu.Menu(targets[0],menus[0],{right_aligned:true});
	});
}

export function http_request(method,url,pdata,content_type,callback,before_send) {
	var req=new XMLHttpRequest();
	req.onreadystatechange=function() {
		if (req.readyState===XMLHttpRequest.DONE) {
			if (req._aborted) return;
			if (req.getResponseHeader("X-TX-Logout")) tx_accounts.forget_session();
			if (callback) callback(req.response,req.status);
		}
	}
	req.open(method,url,true);
	req.timeout=30000;
	if (content_type) req.setRequestHeader("Content-Type",content_type);
	var auth_token=tx_accounts.get_auth_token();
	if (auth_token) req.setRequestHeader("X-TX-Auth",auth_token);
	if (sid) req.setRequestHeader("X-TX-Sid",sid);
	if (before_send) before_send(req);
	req.send(pdata||null);
	return req;
}

export function http_post_form(url,vars,callback) {
	var pdata;
	if (vars) pdata=$.param(vars);
	return http_request("POST",url,pdata,"application/x-www-form-urlencoded",callback);
}

function create_api_request_object(method,...params) {
	return {
		method:method,
		params:params,
		jsonrpc:"2.0"
	};
}

const api_url="action/api";

export function api_request(method,...params/*,callback*/) {
	var callback=params.pop();
	var pdata=create_api_request_object(method,...params);
	return http_request("POST",api_url,JSON.stringify(pdata),"application/json",function(responseText,status) {
		var response=status===200 ? JSON.parse(responseText) : {error:{code:0}};
		if (callback) callback(response.result,response.error,status,response);
	});
}

export class RequestAborted extends Error {
	constructor() {
		super("Request aborted");
	}
}

export function api_request_promise(method,...params/*,aborter?*/) {
	return new Promise((resolve,reject) => {
		let aborter;
		if (params.length>0 && params[params.length-1] instanceof Aborter)
			aborter=params.pop();
		const abort=() => {
			req._aborted=true;
			req.abort();
			reject(new RequestAborted());
		};
		const req=api_request(method,...params,(res,err) => {
			aborter?.unnotify(abort);
			if (err)
				reject(err);
			else
				resolve(res);
		});
		aborter?.notify(abort);
	});
}

export function api_request_retry(method,...params/*,aborter?*/) {
	return new Promise((resolve,reject) => {
		const retrier=new Retrier();
		let aborter;
		if (params.length>0 && params[params.length-1] instanceof Aborter)
			aborter=params.pop();
		(function loop() {
			const req=api_request(method,...params,(res,err,status) => {
				if (!err)
					resolve(res);
				else if (status===0 || (status>=500 && status<600))
					retrier.retry().then(loop);
				else
					reject(err);
			});
			if (aborter) {
				aborter.notify(() => {
					req._aborted=true;
					req.abort();
					reject(new RequestAborted());
				});
			}
		})();
	});
}

export function api_request_beacon(synchronous,method,...params) {
	const pdata=create_api_request_object(method,...params);
	const body=JSON.stringify(pdata);
	if (navigator.sendBeacon)
		navigator.sendBeacon(api_url,body);
	else {
		// fallback to a (posibly) synchronous XMLHttpRequest
		const xhr=new XMLHttpRequest();
		xhr.open("POST",api_url,!synchronous);
		xhr.setRequestHeader("Content-Type","application/json");
		xhr.send(body);
	}
}

export function extract_argno_from_err(err) {
	let m;
	if (err && err.code===-32602 && (m=err.data.match(/\(argument (\d+)\)$/)))
		return parseInt(m[1]);
}

var error_page=
	'<script class="metadata error_page_metadata" type="application/json"></script>'+
	'<script class="metadata" type="application/json">{&quot;title&quot;:&quot;Σφάλμα&quot;}</script>'+
	'<div class="tabs_noheader">'+
		'<div class="tabs">'+
			'<div class="tabs_page">'+
				'<div class="error_page">'+
					'<div>Παρουσιάστηκε κάποιο πρόβλημα κατά την επεξεργασία του αιτήματος.</div>'+
					'<div><button>Προσπάθησε ξανά</button></div>'+
				'</div>'+
			'</div>'+
		'</div>'+
	'</div>';

export function HtmlDownloader() {
	this.r=undefined;
}

HtmlDownloader.prototype.abort=function() {
	if (this.r) {
		this.r._aborted=true;
		this.r.abort();
	}
}

HtmlDownloader.prototype.get=function(mode,vars,cb_done,cb_new_content) {
	this.abort();
	var div=document.createElement("div");
	var do_request=function(cb) {
		return http_post_form("action/"+mode,vars,function(html_fragment,code) {
			var success=code===200 || code===400 || code===403 || code===404 || code===500;
			div.innerHTML=success ? html_fragment : error_page;
			cb(success);
		});
	}
	var req=do_request(function(success) {
		// do not call any of the callbacks if the request was aborted
		if (req._aborted) return;
		prepare_page(div);
		if (!success)
			(function prepare_error_page() {
				div.querySelector(".error_page_metadata").textContent=JSON.stringify({page:vars.p});
				div.querySelector("button").onclick=function() {
					div.innerHTML='<div class="loading-page"></div>';
					do_request(function(success) {
						prepare_page(div);
						if (!success)
							prepare_error_page();
						if (cb_new_content) cb_new_content(div);
					});
				}
			})();
		if (cb_done) cb_done(div,success);
		if (cb_new_content) cb_new_content(div);
	});
	this.r=req;
}

function prepare_page(div) {
	tx_input_validation.enable_validation_recursive(div);
	$("div.script",div).each(function(i,node) {
		(new Function(node.textContent)).call(node);
	}).remove();
	convert_timestamps(div);
	create_popup_menus(div);
	tx_autocomplete.enable(div);
	tx_tabs.recursive_tabify(div);
}

export function css_url(url) {
	return 'url("'+url.replace(/(["\\])/g,"\\$1").replace(/\n/g,"\\n")+'")';
}

export function prevent_default(ev) {
	ev.preventDefault();
}

export function stop_propagation(ev) {
	ev.stopPropagation();
}

export function do_nothing() {
}

// not necessarily cryptographically secure
export var random_letters=
	window.crypto && window.crypto.getRandomValues && window.Uint16Array
	? function(count) {
		var arr=window.crypto.getRandomValues(new Uint16Array(count));
		var res=[];
		for (var i=0; i<count; i++) res.push(String.fromCharCode(97+arr[i] % 26));
		return res.join("");
	}
	: function(count) {
		var res=[];
		for (var i=0; i<count; i++) res.push(String.fromCharCode(97+Math.floor(Math.random()*26)));
		return res.join("");
	};

export function fit_rect(container_width,container_height,width,height,cover) {
	var scale_width=Math.min(1,container_width/width);
	var scale_height=Math.min(1,container_height/height);
	var scale=cover ? Math.max(scale_width,scale_height) : Math.min(scale_width,scale_height);
	var result_width=width*scale;
	var result_height=height*scale;
	return {
		width:result_width,
		height:result_height,
		left:(container_width-result_width)/2,
		top:(container_height-result_height)/2,
		scale:scale
	};
}

// returns the smallest idx such that
// array[idx][property_name]>=value
// if no such idx exists, it returns array.length
export function binary_search(array,property_name,value) {
	const len=array.length;
	if (len===0) return 0;
	let imin=0;
	let imax=len-1;
	if (array[imax][property_name]<value)
		return len;
	while (imin<imax) {
		const imid=(imin+imax)>>1;
		if (array[imid][property_name]<value)
			imin=imid+1;
		else
			imax=imid;
	}
	return imin;
}

export function coalesce(...args) {
	for (const x of args) {
		if (x!=null) return x;
	}
	return null;
}

export function wait_for_events(obj,...event_types) {
	return new Promise((resolve) => {
		const listeners=[];
		const remove_all=() => {
			for (const [event_type,listener] of listeners)
				obj.removeEventListener(event_type,listener);
		};
		for (const event_type of event_types) {
			const listener=(ev) => {
				remove_all();
				resolve([event_type,ev]);
			};
			obj.addEventListener(event_type,listener);
		}
	});
}

export function Lock() {
	this.l=false;
	this.q=[];
}

Lock.prototype.acquire=function(cb) {
	if (this.l) {
		this.q.push(cb);
		return false;
	}
	else {
		this.l=true;
		cb();
		return true;
	}
}

Lock.prototype.release=function() {
	if (!this.l)
		throw "not locked";
	else if (this.q.length===0)
		this.l=false;
	else
		this.q.shift()();
}

export function debounce(fn,time) {
	let timeout=0;
	return () => {
		clearTimeout(timeout);
		timeout=setTimeout(fn,time);
	};
}

const drag_overlay=document.getElementById("drag-overlay");
let mouse_drag_active;
const default_draggable_opts={
	mouse:true,
	touch:true,
	//cursor:undefined,
	capture:true,
	passive:true,
};

// make_draggable:
// 	supported options:
// 		mouse: support draging with mouse (default: true)
// 		touch: support draging with touch (default: true)
// 		cursor: set the cursor to this value when draging with mouse only (default: undefined)
// 		capture: listen for events in the capturing phase (default: true)
// 		passive: make all event listeners passive (default: true)
// 	on_start,on_move,on_end:
// 		this callbacks will be called with a single argument that has the following members:
//			data: an initially empty object for the callbacks to store any data they like
//			type: "mouse" or "touch"
//			event: the original DOM event
//			x: the pointer's x coordinate
//			y: the pointer's y coordinate
//			dx: the amount of space that the pointer has moved in the horizontal axis in pixels since the last on_move
//			dy: the amount of space that the pointer has moved in the vertical axis in pixels since the last on_move
//			cdx: the total amount of space that the pointer has moved in the horizontal axis in pixels
//			cdy: the total amount of space that the pointer has moved in the vertical axis in pixels
// 		on_end is optional
// 		dx and dy are 0 in on_start
// 		dx and dy in on_end have the same value as in the last on_move
export function make_draggable(element,options,on_start,on_move,on_end) {
	options=$.extend({},default_draggable_opts,options);
	const l_opts={
		capture:options.capture,
		passive:options.passive,
	};
	const l_opts_non_passive={capture:options.capture};
	let touch_id;
	let drag_event;
	let initial_x;
	let initial_y;
	let last_x;
	let last_y;
	if (options.mouse) {
		let which;
		const on_mousemove=function(ev) {
			if (!drag_event) return;
			drag_event.event=ev;
			drag_event.x=ev.pageX;
			drag_event.y=ev.pageY;
			drag_event.dx=ev.pageX-last_x;
			drag_event.dy=ev.pageY-last_y;
			drag_event.cdx=ev.pageX-initial_x;
			drag_event.cdy=ev.pageY-initial_y;
			last_x=ev.pageX;
			last_y=ev.pageY;
			on_move(drag_event);
		};
		const on_mouseup=function(ev) {
			if (!drag_event || which!==ev.which) return;
			try {
				if (on_end!==undefined) {
					drag_event.event=ev;
					drag_event.dx=0;
					drag_event.dy=0;
					on_end(drag_event);
				}
			}
			finally {
				drag_overlay.style.display="none";
				removeEventListener("mousemove",on_mousemove,l_opts);
				removeEventListener("mouseup",on_mouseup,l_opts);
				removeEventListener("contextmenu",prevent_default,options.capture);
				mouse_drag_active=false;
				drag_event=undefined;
			}
		};
		element.addEventListener("mousedown",function(ev) {
			if (mouse_drag_active || drag_event) return;
			drag_event={
				data:{},
				type:"mouse",
				event:ev,
				x:ev.pageX,
				y:ev.pageY,
				dx:0,
				dy:0,
				cdx:0,
				cdy:0,
			};
			if (on_start(drag_event)===false)
				drag_event=undefined;
			else {
				ev.preventDefault();
				mouse_drag_active=true;
				if (options.cursor) {
					drag_overlay.style.cursor=options.cursor;
					drag_overlay.style.display="block";
				}
				initial_x=last_x=ev.pageX;
				initial_y=last_y=ev.pageY;
				which=ev.which;
				addEventListener("mousemove",on_mousemove,l_opts);
				addEventListener("mouseup",on_mouseup,l_opts);
				addEventListener("contextmenu",prevent_default,options.capture);
			}
		},l_opts_non_passive);
	}
	if (options.touch) {
		element.addEventListener("touchstart",function(ev) {
			if (drag_event || touch_id!==undefined) return;
			const touch=ev.changedTouches[0];
			drag_event={
				data:{},
				type:"touch",
				event:ev,
				x:touch.pageX,
				y:touch.pageY,
				dx:0,
				dy:0,
				cdx:0,
				cdy:0,
			};
			if (on_start(drag_event)===false)
				drag_event=undefined;
			else {
				touch_id=touch.identifier;
				initial_x=last_x=touch.pageX;
				initial_y=last_y=touch.pageY;
			}
		},l_opts);
		element.addEventListener("touchmove",function(ev) {
			for (const touch of ev.changedTouches)
				if (touch.identifier===touch_id) {
					drag_event.event=ev;
					drag_event.x=touch.pageX;
					drag_event.y=touch.pageY;
					drag_event.dx=touch.pageX-last_x;
					drag_event.dy=touch.pageY-last_y;
					drag_event.cdx=touch.pageX-initial_x;
					drag_event.cdy=touch.pageY-initial_y;
					last_x=touch.pageX;
					last_y=touch.pageY;
					on_move(drag_event);
					break;
				}
		},l_opts);
		const on_touchend=function(ev) {
			for (const touch of ev.changedTouches)
				if (touch.identifier===touch_id) {
					try {
						if (on_end!==undefined) {
							drag_event.event=ev;
							drag_event.dx=0;
							drag_event.dy=0;
							on_end(drag_event);
						}
					}
					finally {
						touch_id=undefined;
						drag_event=undefined;
					}
					break;
				}
		};
		element.addEventListener("touchend",on_touchend,l_opts);
		element.addEventListener("touchcancel",on_touchend,l_opts);
	}
}

const pinch_listener_opts={passive:true};

// listen_for_pinch:
// 	supported options:
// 		(none)
// 	on_start,on_update,on_end:
// 		this callbacks will be called with a single argument that has the following members:
//			data: an initially empty object for the callbacks to store any data they like
//			event: the original DOM event
//			count: the number of active touches
//			scale: the amount of stretching that has been requested since the previous call, null if only one finger touches the touch screen
//			cscale: the cumulative scale
//			dx: the amount of space that the pointer has moved in the horizontal axis in pixels since the previous call
//			dy: the amount of space that the pointer has moved in the vertical axis in pixels since the previous call
//			cdx: cumulative dx
//			cdy: cumulative dy
//			pageX,pageY: the transform origin in page coordinates
// 		on_end is optional
export function listen_for_pinch(element,options,on_start,on_update,on_end) {
	var active_touches=new Map();
	let pinch_event;
	let initial_distance;
	let previous_distance;
	let previous_cscale;
	element.addEventListener("touchstart",function(ev) {
		if (active_touches.size>=2) return;
		for (let touch of ev.changedTouches) {
			active_touches.set(touch.identifier,touch);
			if (active_touches.size===1) {
				pinch_event={
					data:{},
					event:ev,
					count:1,
					scale:null,
					cscale:null,
					dx:0,
					dy:0,
					cdx:0,
					cdy:0,
					pageX:touch.pageX,
					pageY:touch.pageY,
				};
				previous_cscale=1;
				if (on_start(pinch_event)===false) {
					pinch_event=undefined;
					active_touches.delete(touch.identifier);
					break;
				}
			}
			else {
				let touch1=active_touches.values().next().value;
				let dist_x=touch.pageX-touch1.pageX;
				let dist_y=touch.pageY-touch1.pageY;
				previous_distance=Math.sqrt(dist_x*dist_x+dist_y*dist_y);
				initial_distance=previous_distance;
				break;
			}
		}
	});
	element.addEventListener("touchmove",function(ev) {
		if (!pinch_event) return;
		let total_dx=0;
		let total_dy=0;
		for (let touch of ev.changedTouches) {
			let old_touch=active_touches.get(touch.identifier);
			if (old_touch) {
				total_dx+=touch.pageX-old_touch.pageX;
				total_dy+=touch.pageY-old_touch.pageY;
				active_touches.set(touch.identifier,touch);
			}
		}
		let it=active_touches.values();
		let touch1=it.next().value;
		let touch2=it.next().value;
		pinch_event.event=ev;
		let count=active_touches.size;
		pinch_event.count=count;
		if (touch2===undefined) {
			pinch_event.scale=null;
			pinch_event.pageX=touch1.pageX;
			pinch_event.pageY=touch1.pageY;
		}
		else {
			let dist_x=touch2.pageX-touch1.pageX;
			let dist_y=touch2.pageY-touch1.pageY;
			let distance=Math.sqrt(dist_x*dist_x+dist_y*dist_y);
			pinch_event.scale=distance/previous_distance; // what of previous_distance===0?
			pinch_event.cscale=previous_cscale*distance/initial_distance; // what of initial_distance===0?
			previous_distance=distance;
			pinch_event.pageX=(touch1.pageX+touch2.pageX)/2;
			pinch_event.pageY=(touch1.pageY+touch2.pageY)/2;
		}
		pinch_event.cdx+=(pinch_event.dx=total_dx/count);
		pinch_event.cdy+=(pinch_event.dy=total_dy/count);
		on_update(pinch_event);
	},pinch_listener_opts);
	let on_touchend=function(ev) {
		if (!pinch_event) return;
		for (let touch of ev.changedTouches)
			active_touches.delete(touch.identifier);
		pinch_event.event=ev;
		pinch_event.scale=null;
		pinch_event.dx=0;
		pinch_event.dy=0;
		if (active_touches.size!==0) {
			previous_cscale=pinch_event.cscale;
			let touch1=active_touches.values().next().value;
			pinch_event.count=1;
			pinch_event.pageX=touch1.pageX;
			pinch_event.pageY=touch1.pageY;
			on_update(pinch_event);
		}
		else {
			pinch_event.count=0;
			try {
				if (on_end) on_end(pinch_event);
			}
			finally {
				pinch_event=undefined;
			}
		}
	}
	element.addEventListener("touchend",on_touchend,pinch_listener_opts);
	element.addEventListener("touchcancel",on_touchend,pinch_listener_opts);
}

const default_listen_for_taps_opts={
	passive:true,
	capture:false,
	max_move:4,
};

// listen_for_taps:
// 	supported options:
// 		capture: listen for events in the capturing phase (default: false)
// 		passive: make all event listeners passive (default: true)
// 		max_move: the maximum distance (in pixels) the finger is allowed to move before canceling then tap (default 4)
// 		timeout: the maximum allowed amount of time (in ms) between touchstart and touchend, if true, 1000 will be used (default: no timeout)
// 		timeout_cb: a callback to be called if a timeout has occurred. It will receive to arguments:
//			ev: the touchstart event that started the tap
//			touch: the touch object
// 	on_tap:
// 		this callback will be called with 2 argument:
//			ev: the touchend event that completed the tap
//			touch: the touch object
export function listen_for_taps(element,opts,on_tap) {
	opts=Object.assign({},default_listen_for_taps_opts,opts);
	const l_opts={
		passive:opts.passive,
		capture:opts.capture,
	};
	const max_move=opts.max_move;
	const timeout=opts.timeout===true || (opts.timeout_cb && !opts.timeout) ? 1000 : opts.timeout;
	const timeout_cb=opts.timeout_cb||do_nothing;
	const active_touches=new Map();
	let canceled=false;
	const delete_touch=id => {
		if (timeout)
			clearTimeout((active_touches.get(id)||[])[2]||0);
		active_touches.delete(id);
		if (active_touches.size===0)
			canceled=false;
	};
	element.addEventListener("touchstart",ev => {
		for (const touch of ev.changedTouches) {
			let timeout_id=0;
			if (timeout) {
				timeout_id=setTimeout(() => {
					const was_not_canceled=!canceled;
					canceled=true;
					if (was_not_canceled)
						timeout_cb(ev,touch);
				},timeout);
			}
			active_touches.set(touch.identifier,[touch.pageX,touch.pageY,timeout_id]);
		}
		if (active_touches.size>1)
			canceled=true;
	},l_opts);
	element.addEventListener("touchmove",ev => {
		for (const touch of ev.changedTouches) {
			const p=active_touches.get(touch.identifier);
			if (p!==undefined && Math.abs(touch.pageX-p[0])+Math.abs(touch.pageY-p[1])>max_move)
				delete_touch(touch.identifier);
		}
	},l_opts);
	element.addEventListener("touchend",ev => {
		for (const touch of ev.changedTouches) {
			if (active_touches.has(touch.identifier)) {
				const was_not_canceled=!canceled;
				delete_touch(touch.identifier);
				if (was_not_canceled)
					on_tap(ev,touch);
			}
		}
	},l_opts);
	element.addEventListener("touchcancel",ev => {
		for (const touch of ev.changedTouches) {
			if (active_touches.has(touch.identifier))
				delete_touch(touch.identifier);
		}
	},l_opts);
}

export const is_connection_metered=
	"connection" in navigator
		? () => navigator.connection.type==="cellular"
		: default_value => default_value;

const _style=Symbol();

export class DynStyleSheet {
	add(rule_text) {
		if (!this[_style]) {
			this[_style]=document.createElement("style");
			document.head.appendChild(this[_style]);
		}
		this[_style].sheet.insertRule(rule_text,this[_style].sheet.cssRules.length);
	}

	reset() {
		if (this[_style]) {
			const stylesheet=this[_style].sheet;
			while (stylesheet.cssRules.length)
				stylesheet.deleteRule(0);
			this[_style].remove();
			this[_style]=undefined;
		}
	}
}

export function set_elt_approval(elt,approval) {
	if (approval==null)
		elt.removeAttribute("data-approval");
	else
		elt.setAttribute("data-approval",approval<=-1 ? "rejected" : approval>=1 ? "approved" : "pending");
}

export function sleep(ms,aborter) {
	return new Promise((resolve,reject) => {
		const tid=setTimeout(() => {
			aborter?.unnotify(abort);
			resolve();
		},ms);
		const abort=() => {
			clearTimeout(tid);
			reject(new RequestAborted());
		};
		aborter?.notify(abort);
	});
}

export function sleep_visible(ms,aborter) {
	return new Promise((resolve,reject) => {
		let timeout_start;
		let timeout_id;
		const abort=() => {
			clearTimeout(timeout_id);
			document.removeEventListener("visibilitychange",on_visibilitychange);
			reject(new RequestAborted());
		};
		const on_visibilitychange=() => {
			if (timeout_id) {
				ms-=performance.now()-timeout_start;
				clearTimeout(timeout_id);
				timeout_id=0;
			}
			if (!document.hidden) {
				timeout_start=performance.now();
				timeout_id=setTimeout(() => {
					document.removeEventListener("visibilitychange",on_visibilitychange);
					aborter?.unnotify(abort);
					resolve();
				},ms);
			}
		};
		document.addEventListener("visibilitychange",on_visibilitychange);
		on_visibilitychange();
		aborter?.notify(abort);
	});
}

export function details_get_open(name,rev,default_value) {
	const tips_state=get_value(name);
	return tips_state && tips_state.v===rev ? tips_state.open : default_value;
}

export function remember_details_state(details_elt,name,rev,default_open) {
	details_elt.open=details_get_open(name,rev,default_open);
	details_elt.addEventListener("toggle",() => {
		set_value(name,{v:rev,open:details_elt.open});
	});
}

const svg_xmlns="http://www.w3.org/2000/svg";

export function create_svg() {
	return document.createElementNS(svg_xmlns,"svg");
}

export function create_svg_path(d) {
	const path=document.createElementNS(svg_xmlns,"path");
	if (d) path.setAttribute("d",d);
	return path;
}

export function init() {
	storage=
		!supports_localStorage ? create_memory_storage() :
		tx_history.tab_session ? sessionStorage :
			localStorage;
}
