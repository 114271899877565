import * as tx_util from "./util";
import {is_supported} from "./geolocation";

// quickly find the location without a prompt for permissions
export function get() {
	if (!("permissions" in navigator && is_supported()))
		return find_from_ip();
	else {
		return navigator.permissions.query({name:"geolocation"}).then(result => {
			if (result.state!=="granted")
				return find_from_ip();
			else {
				return getCurrentPositionAsync({timeout:3000,maximumAge:Infinity}).then(pos => {
					const coords=pos.coords;
					return location_found(coords.latitude,coords.longitude,coords.accuracy);
				},find_from_ip);
			}
		},find_from_ip);
	}
}

function find_from_ip() {
	return tx_util.api_request_promise("geolocate").then(res =>
		location_found(res.lat,res.lon,res.accuracy)
	,() =>
		location_found()
	);
}

// formats & returns the answer
function location_found(lat,lon,accuracy) {
	if (lat==null)
		throw new Error("cound not find the current location");
	else
		return {lat,lon,accuracy};
}

function getCurrentPositionAsync(options) {
	return new Promise((resolve,reject) => {
		navigator.geolocation.getCurrentPosition(resolve,reject,options);
	});
}

export function init() {
	// remove this after 2019-01-01:
	tx_util.delete_value("location");
}
