import * as tx_infobox from "./infobox";
import * as tx_img_overlay from "./img_overlay";
import * as tx_map from "./map";
import * as tx_main_menu from "./main_menu";
import * as tx_upload_overlay from "./upload_overlay";

// The following doesn't work on ie:
//     history.state.foo="bar"; // ie will ignore this
//     history.replaceState(history.state,"",location.search);
// You have to do it like this:
//     const state=history.state;
//     state.foo="bar";
//     history.replaceState(state,"",location.search);

const meta_desc_elt=document.head.querySelector('meta[name="description"]');
const initial_meta_desc=meta_desc_elt.getAttribute("content");
export let params={};

function parse_params(str,params_={}) {
	if (str.length===0) return params_;
	for (const x of str.split("&")) {
		const j=x.indexOf("=");
		if (j>=0) params_[decodeURIComponent(x.substr(0,j))]=decodeURIComponent(x.substr(j+1).replace(/\+/g," "));
	}
	return params_;
}

function encode_pair(name,value) {
	return encodeURIComponent(name)+"="+encodeURIComponent(value);
}

function encode_search_params(params_) {
	const res=[];
	for (const name of ["i","ic","p","t","sv"])
		if (params_[name]) res.push(encode_pair(name,params_[name]));
	for (const name in params_)
		if (name!=="i" && name!=="ic" && name!=="p" && name!=="t" && name!=="sv" && params_[name])
			res.push(encode_pair(name,params_[name]));
	return res.length ? "?"+res.join("&") : ".";
}

function update_title() {
	let full_title;
	const titles=history.state.titles;
	for (let i=titles.length-1; i>=0; i--)
		if (titles[i]) {
			full_title=titles[i]+" • "+initial_title;
			break;
		}
	document.title=full_title||initial_title;

	const meta_descs=history.state.meta_descs;
	let meta_desc;
	for (let i=meta_descs.length-1; i>=0; i--) {
		if (meta_descs[i]) {
			meta_desc=meta_descs[i];
			break;
		}
	}
	meta_desc_elt.setAttribute("content",meta_desc||initial_meta_desc);
}

export function set_title(level,title,meta_desc) {
	const state=history.state;
	state.titles[level]=title;
	state.meta_descs[level]=meta_desc;
	history.replaceState(state,"",location.search);
	update_title();
}

export function push_history(params_,state_) {
	Object.assign(params,params_);
	const state=history.state;
	Object.assign(state,state_);
	history.pushState(state,"",encode_search_params(params));
}

export function replace_history(params_,state_) {
	Object.assign(params,params_);
	const state=history.state;
	Object.assign(state,state_);
	history.replaceState(state,"",encode_search_params(params));
}

export function amend_state(state_) {
	const state=history.state;
	history.replaceState(Object.assign(state,state_),"",location.search)
}

export function back() {
	history.back();
}

function on_history(is_initial) {
	params=parse_params(location.search.replace(/^\?/,""));
	tx_infobox.on_history(params,history.state);
	tx_img_overlay.on_history(params,history.state);
	tx_map.on_history(params,history.state,is_initial,false);
	tx_upload_overlay.on_history(history.state);
	update_title();
}

export let tab_session;

(function () {
	parse_params(location.search.replace(/^\?/,""),params);
	const hash=location.hash.replace(/^#/,"");
	if (hash.length>0) {
		parse_params(hash,params);
		history.replaceState(history.state,"",encode_search_params(params));
	}
	tab_session=params.session==="tab";
})();

export function init() {
	if (history.state && history.state.titles && history.state.meta_descs)
		on_history(true);
	else {
		history.replaceState({titles:[],meta_descs:[]},"",location.search);
		let search_q;
		if (params.q!==undefined) {
			search_q=params.q;
			replace_history({q:undefined});
		}
		const set_map_center_from_infobox=params.p && /^t\d+$/.test(params.p);
		if (!params.i && !params.sv)
			tx_infobox.on_history(params,history.state,set_map_center_from_infobox);
		else {
			const {i:params_i,ic:params_ic,sv:params_sv}=params;
			replace_history({i:undefined,ic:undefined,sv:undefined});
			tx_infobox.on_history(params,history.state,set_map_center_from_infobox);
			push_history({i:params_i,ic:params_ic,sv:params_sv});
		}
		tx_img_overlay.on_history(params,history.state);
		tx_map.on_history(params,history.state,true,set_map_center_from_infobox);
		if (search_q!==undefined)
			tx_main_menu.perform_search_ex(search_q,false,true);
	}
	addEventListener("popstate",() => {
		on_history(false);
	});
}
