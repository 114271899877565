export const xy_bit_width=29;
export const xy_map_width=1<<xy_bit_width;

export const max_z_level=26;

export function x_to_lon(x) {
	return x/xy_map_width*360-180;
}

export function y_to_lat(y) {
	return Math.atan(Math.exp((1-y/xy_map_width*2)*Math.PI))/Math.PI*360-90;
}

export function lon_to_x(lon) {
	return Math.round(((lon+180)/360)*xy_map_width);
}

export function lat_to_y(lat) {
	return Math.round(((1-(Math.log(Math.tan(Math.PI*(0.25+lat/360)))/Math.PI))/2)*xy_map_width);
}

// assumes level<=max_z_level
function xyl_to_z(x,y,level) {
	y<<=1;
	let z=0;
	for (let i=level|0; i--;)
		z=z*4+(((x>>i)&1)|((y>>i)&2));
	return z;
}

if (xy_bit_width<max_z_level) console.error("level underflow");
const level_adjust=xy_bit_width-max_z_level;

export function zcoords_from_xy(x,y) {
	return xyl_to_z(x>>level_adjust,y>>level_adjust,max_z_level);
}

export function zrange_from_xy(x,y,level) {
	let diff=(level|0)-max_z_level;
	if (diff>=0) {
		const z=xyl_to_z(x>>diff,y>>diff,max_z_level);
		return [z,z];
	}
	else {
		const adj_factor=2**(-2*diff);
		const z_min=xyl_to_z(x,y,level)*adj_factor;
		return [z_min,z_min+(adj_factor-1)];
	}
}

export function bearing(p1,p2) {
	const bearing=Math.atan2(
		-Math.sin(p2.lon-p1.lon)*Math.cos(p2.lat),
		Math.cos(p1.lat)*Math.sin(p2.lat)-Math.sin(p1.lat)*Math.cos(p2.lat)*Math.cos(p2.lon-p1.lon)
	)*180/Math.PI;
	return (bearing+360)%360;
}
