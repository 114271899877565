import {AudioRecorder} from "./audio_recorder";
import * as tx_util from "./util";
import * as tx_map from "./map";
import * as tx_infobox from "./infobox";
import * as tx_upload from "./upload";
import {toast} from "./toast";
import {mke} from "./mke";
import {Retrier} from "./retrier";

const audio_note_bar=document.querySelector("#audio-note-bar");
const message_elt=audio_note_bar.querySelector(".msg");
const time_elt=audio_note_bar.querySelector(".time");
const pause_btn=audio_note_bar.querySelector(".pause");
const stop_btn=audio_note_bar.querySelector(".stop");
const cancel_btn=audio_note_bar.querySelector(".cancel");

let busy=false;
let aborter;
let audio_recorder;
let prev_time;

function pad_int(x,len) {
	return `${x}`.padStart(len,"0");
}

function set_time(time) {
	let secs=Math.floor(time/1000);
	const mins=Math.floor(secs/60);
	secs-=mins*60;
	const str=`${pad_int(mins,2)}:${pad_int(secs,2)}`;
	if (str!==prev_time)
		time_elt.textContent=prev_time=str;
}

pause_btn.addEventListener("click",() => {
	pause_btn.disabled=true;
	if (audio_recorder) {
		const state=audio_recorder.state;
		if (state==="paused")
			audio_recorder.resume();
		else if (state==="recording")
			audio_recorder.pause();
	}
});

stop_btn.addEventListener("click",() => {
	stop_btn.disabled=true;
	if (audio_recorder)
		audio_recorder.stop();
});

cancel_btn.addEventListener("click",() => {
	if (!confirm("Σίγουρα;"))
		return;
	cancel_btn.disabled=true;
	if (aborter)
		aborter.abort();
});

export async function add_audio_note(eid,etype,message) {
	if (busy) {
		toast("Άλλη ηχογράφηση σε εξέλιξη");
		return;
	}
	busy=true;
	aborter=new tx_util.Aborter();
	aborter.notify(() => {
		if (audio_recorder && (audio_recorder.state==="recording" || audio_recorder.state==="paused")) {
			audio_recorder.stop();
		}
	});
	message_elt.textContent=message;
	set_time(0);
	pause_btn.disabled=true;
	stop_btn.disabled=true;
	cancel_btn.disabled=false;
	audio_note_bar.classList.remove("recording");
	document.body.classList.add("recording-audio-note");
	tx_map.resized();
	try {
		const on_state_changed=(state) => {
			if (state==="recording") {
				audio_note_bar.classList.add("recording");
				pause_btn.disabled=false;
			}
			else if (state==="paused") {
				audio_note_bar.classList.remove("recording");
				pause_btn.disabled=false;
			}
			else if (state==="stopped" || state==="error") {
				audio_note_bar.classList.remove("recording");
				pause_btn.disabled=true;
				stop_btn.disabled=true;
			}
			else
				console.error("invalid state",state);
		};
		const on_error=(err) => {
			toast(`Παρουσιάστηκε σφάλμα κατά την ηχογράφηση: ${err.message}`);
			console.error(err);
		};
		const on_time_updated=() => {
			set_time(audio_recorder.time);
		};
		try {
			audio_recorder=await AudioRecorder.init(on_state_changed,on_error,on_time_updated);
		}
		catch (err) {
			toast(`Η πρόσβαση στο μικρόφωνο απέτυχε: ${err.message}`);
			throw err;
		}
		await audio_recorder.start();
		stop_btn.disabled=false;
		const blob=await audio_recorder.get_data();
		audio_recorder=undefined;
		if (!aborter.aborted)
			await upload_audio_note(eid,etype,blob);
	}
	finally {
		audio_recorder=undefined;
		document.body.classList.remove("recording-audio-note");
		tx_map.resized();
		aborter=undefined;
		busy=false;
	}
}

async function upload_audio_note(eid,etype,blob) {
	audio_note_bar.classList.add("uploading");
	try {
		const mime_type=blob.type.replace(/;.*/,"");
		const retrier=new Retrier(undefined,undefined,aborter);
		let done=false;
		while (!aborter.aborted && !done) {
			try {
				await tx_upload.upload("audio_note",blob,eid,etype,mime_type,aborter);
				done=true;
			}
			catch (err) {
				if (err instanceof tx_util.RequestAborted)
					done=true;
				else if (!err || err.code!==0) {
					const msg=err instanceof Error ? err.message : JSON.stringify(err);
					toast(`Το ανέβασμα της ηχογράφησης απέτυχε: ${msg}`);
					console.error(err);
					done=true;
				}
			}
			if (!done) {
				try {
					await retrier.retry();
				}
				catch {}
			}
		}
	}
	finally {
		audio_note_bar.classList.remove("uploading");
	}
}

export function audio_notes_widget(element,audio_notes,include_entry_links) {
	const container=mke(".audio-note-widget");
	let download_queue=Promise.resolve();
	for (const audio_note of audio_notes) {
		let title=`${new Date(audio_note.created*1000)} από ${audio_note.user.name}`;
		const audio_elt=mke("audio",{"controls":"",onplay:(ev) => {
			for (const other of document.querySelectorAll("audio,video")) {
				if (other!==ev.target)
					other.pause();
			}
		}});
		const audio_note_elt=mke(".audio-note",{title});
		if (include_entry_links && audio_note.entry) {
			const entry_page=(audio_note.entry.etype===0 ? "t" : "c")+audio_note.entry.eid;
			audio_note_elt.append(mke("",
				mke("a",{
					"data-entry":audio_note.entry.name,
					href:`?p=${entry_page}`,
					onclick:(ev) => {
						tx_util.prevent_default(ev);
						tx_infobox.browse(entry_page);
					},
				}),
			));
		}
		audio_note_elt.append(mke(".bar",
			audio_elt,
			mke("button.cancel",{type:"button",onclick:(ev) => {
				if (!confirm("Σίγουρα;"))
					return;
				ev.currentTarget.disabled=true;
				tx_util.api_request_promise("delete_audio_note",audio_note.id).then(() => {
					$(audio_note_elt).slideUp(() => {
						audio_note_elt.remove();
					});
				},(err) => {
					ev.currentTarget.disabled=false;
					toast(`Απέτυχε: ${JSON.stringify(err)}`);
				});
			}}),
		));
		container.append(audio_note_elt);
		download_queue=download_queue.finally(() => {
			return new Promise((resolve) => {
				tx_util.http_request("POST","action/audio_note",$.param({id:audio_note.id}),"application/x-www-form-urlencoded",(resp,status) => {
					resolve();
					if (status!==200) {
						toast(status===404 ? "Το ηχητικό δεν βρέθηκε στον server" : `Σφάλμα ${status}`);
						return;
					}
					const file_reader=new FileReader();
					file_reader.onload=(ev) => {
						audio_elt.src=ev.target.result;
					};
					file_reader.readAsDataURL(resp);

				},(xhr) => {
					xhr.responseType="blob";
					xhr.timeout=120000;
				});
			});
		});
	}
	element.replaceWith(container);
}
