import * as tx_util from "./util";
import * as tx_map from "./map";
import * as tx_history from "./history";
import * as tx_tabs from "./tabs";
import * as tx_accounts from "./accounts";
import * as tx_data from "./data";
import * as tx_geolocation from "./geolocation";
import * as tx_img_overlay from "./img_overlay";
import * as tx_input_validation from "./input_validation";
import * as tx_cache from "./cache";
import {toast} from "./toast";
import {mke} from "./mke";
import "./css3_transform";

var mainpane;
var infobox;
var $infobox;
var infoboxh;
var title_span;
var infoboxc;
var $infoboxo;
var share_btn;
var report_error;
const _street_view_editor=Symbol();
let set_map_center=false;

var minimized=false;
var infobox_pos;
var infobox_animation_duration="slow";
var infobox_compact_height_ratio=2/3;
var infobox_flex_basis;

const infobox_downloader=new tx_util.HtmlDownloader();

const state_cache=new tx_cache.Cache();
let cur_state_key;

function load_page(page_url,state_key,tab_name,is_default_page) {
	return new Promise(resolve => {
		tx_map.unshow_on_map();
		restore();
		const cached_div=state_cache.get(state_key);
		if (cached_div!==undefined) {
			infobox_downloader.abort();
			$infobox.removeClass("busy");
			let tabs_div=infoboxc.querySelector(".tabs_live");
			if (cur_state_key!==state_key) {
				if (tabs_div) tabs_div._scroll_save();
				// innerHTML="" causes problems in IE
				while (infoboxc.firstChild) infoboxc.removeChild(infoboxc.firstChild);
				infoboxc.appendChild(cached_div);
				cur_state_key=state_key;
				tabs_div=infoboxc.querySelector(".tabs_live");
				if (tabs_div) tabs_div._scroll_restore();
			}
			if (tabs_div) tabs_div._select_by_name(tab_name);
			metadata_updated();
			resolve();
		}
		else {
			$infobox.addClass("busy");
			title_span.textContent="Έφτασε!";
			infobox_downloader.get("infobox",{p:page_url},function(div) {
				div.classList.add("page-root");
				const tabs_div=infoboxc.querySelector(".tabs_live");
				if (tabs_div) tabs_div._scroll_save();
				// innerHTML="" causes problems in IE
				while (infoboxc.firstChild) infoboxc.removeChild(infoboxc.firstChild);
				infoboxc.appendChild(div);
				process_metadata_patches(div,div);
				$infobox.removeClass("busy");
				cur_state_key=state_key;
				state_cache.set(cur_state_key,div);
				resolve();
			},function(div) {
				for (const a of div.querySelectorAll(".tabs_live .tabs_header > a"))
					a.href="?p="+encodeURIComponent(page_url)+(a._page.name ? "&t="+encodeURIComponent(a._page.name) : "");
				if (is_default_page)
					div.append(mke("script.metadata",{type:"application/json"},JSON.stringify({default_page:true})))
				const tabs_div=div.querySelector(".tabs_live");
				if (tabs_div) {
					tabs_div._select_by_name(tab_name);
					tabs_div._resized();
					tabs_div._onselect(function(idx,name,clicked) {
						if (clicked)
							tx_history.replace_history({p:page_url,t:name},{infobox:state_key});
						tabs_div._resized();
						if (infoboxc.contains(div)) metadata_updated();
					});
				}
				if (infoboxc.contains(div)) metadata_updated();
			});
		}
	});
}

export function browse(page_url,tab_name,replace_hist) {
	tx_img_overlay.hide();
	tx_map.hide_street_view();
	set_map_center=false;
	const state_key=state_cache.new_key();
	tab_name=tab_name||null;
	const promise=load_page(page_url,state_key,tab_name);
	const url_params={
		p:page_url,t:tab_name,
		i:undefined,ic:undefined,
		sv:undefined,
	};
	const hist_state={
		infobox:state_key,
		img:undefined,
		street_view:undefined,
	};
	if (replace_hist)
		tx_history.replace_history(url_params,hist_state);
	else
		tx_history.push_history(url_params,hist_state);
	tx_history.set_title(1,"","");
	return promise;
}

export function reload() {
	set_map_center=false;
	const metadata=extract_metadata();
	const page_url=metadata.page;
	if (!page_url) {
		toast("Άγνωστη σελίδα");
		return Promise.reject("Άγνωστη σελίδα");
	}
	else {
		const state_key=state_cache.new_key();
		const tab_name=metadata.tab_name;
		const promise=load_page(page_url,state_key,tab_name);
		tx_history.replace_history({p:page_url,t:tab_name},{infobox:state_key});
		return promise;
	}
}

export function on_history(params,state,set_map_center_) {
	tx_map.cancel_select_coordinates_center();
	set_map_center=!!set_map_center_;
	let state_key=state.infobox;
	if (state_key==null) {
		state_key=state_cache.new_key();
		tx_history.amend_state({infobox:state_key});
	}
	let page_url=params.p;
	let is_default_page;
	if (!page_url) {
		page_url="u/welcome";
		is_default_page=true;
	}
	load_page(page_url,state_key,params.t,is_default_page);
}

function extract_metadata() {
	const metadata={};
	for (const elt of infoboxc.querySelectorAll(".metadata"))
		Object.assign(metadata,JSON.parse(elt.textContent));
	return metadata;
}

function metadata_updated() {
	const metadata=extract_metadata();
	const tab_title=metadata.tab_title_fixed!=null ? metadata.tab_title_fixed : metadata.tab_title;
	const title=
		!metadata.title ? "" :
		!tab_title ? metadata.title :
			metadata.title+" • "+tab_title;
	if (metadata.default_page)
		tx_history.set_title(0,"","");
	else
		tx_history.set_title(0,title,metadata.meta_desc);
	title_span.textContent=metadata.title || "";
	if (metadata.page) {
		share_btn.href="?p="+metadata.page+(metadata.tab_name ? "&t="+metadata.tab_name : "");
		share_btn._title=title;
		share_btn.style.display="";
		report_error.href="?p=report_error_"+metadata.page;
		report_error.onclick=function() {browse("report_error_"+metadata.page); return false};
		report_error.style.display="inline";
	}
	else {
		share_btn.style.display="none";
		report_error.style.display="none";
	}
	if (metadata.lat==null)
		tx_map.clear_current_point();
	else {
		tx_map.set_current_point(metadata.lat,metadata.lon);
		if (set_map_center) {
			tx_map.zoom_on_map(metadata.lat,metadata.lon);
			set_map_center=false;
		}
	}
	if (metadata.tlat==null)
		tx_map.clear_temporary_point();
	else
		tx_map.set_temporary_point(metadata.tlat,metadata.tlon);
	street_view_editor_updated(undefined,metadata);
	tx_map.show_arrow(!!infoboxc.querySelector(".street_view_editor_ref"));
}

function process_metadata_patches(page_root,elt) {
	const metadata_patches=elt.querySelectorAll(".metadata_patch");
	if (metadata_patches.length>0) {
		let metadata_patched_div=page_root.querySelector(".metadata.patched");
		if (!metadata_patched_div) {
			metadata_patched_div=mke("script.metadata.patched",{type:"application/json"});
			page_root.append(metadata_patched_div);
		}
		const metadata={};
		for (const elt of metadata_patches) {
			Object.assign(metadata,JSON.parse(elt.textContent));
			elt.remove();
		}
		metadata_patched_div.textContent=JSON.stringify(metadata);
	}
	const street_view_editor=elt.querySelector(".street_view_editor");
	if (street_view_editor) {
		let street_view_editor_ref=page_root.querySelector(".street_view_editor_ref");
		if (street_view_editor.classList.contains("remove")) {
			//street_view_editor_ref?.remove();
			if (street_view_editor_ref)
				street_view_editor_ref.remove();
		}
		else {
			if (!street_view_editor_ref) {
				street_view_editor_ref=mke(".street_view_editor_ref");
				page_root.append(street_view_editor_ref);
			}
			street_view_editor_ref[_street_view_editor]=street_view_editor;
		}
	}
}

function street_view_editor_updated(state,metadata) {
	let label;
	let label_italic=false;
	if (state===undefined) {
		const street_view_editor_ref=infoboxc.querySelector(".street_view_editor_ref");
		if (street_view_editor_ref) {
			state=true;
			const name_input=street_view_editor_ref[_street_view_editor].closest(".info-editor").querySelector('[name="name"]');
			label=tx_input_validation.get_value(name_input);
			label_italic=true;
		}
		else {
			const {entry}=metadata||extract_metadata();
			state=!!(entry && entry.etype===0);
			if (state)
				label=entry.name;
		}
	}
	if (state && label===undefined)
		label=((metadata||extract_metadata()).entry || {}).name;
	tx_map.show_street_view_get_value_button(state,label || "",label_italic);
}

export function on_street_view_new_value(value) {
	const street_view_editor_ref=infoboxc.querySelector(".street_view_editor_ref");
	if (street_view_editor_ref) {
		const street_view_editor=street_view_editor_ref[_street_view_editor];
		restore();
		street_view_editor.value=value;
		tx_input_validation.get_value(street_view_editor); // validate new value
	}
	else {
		const page_root=infoboxc.querySelector(".page-root");
		const tabs_page_content=page_root.querySelector(".tabs_live")._get_page_content(0);
		const {entry}=extract_metadata();
		const {eid,etype}=entry||{};
		if (etype===0) {
			const sv_data=tx_map.parse_street_view_value(value);
			tx.api_request_promise("set_street_view",eid,etype,sv_data).then(() => {
				submit_form(null,tabs_page_content,"merge_entry",{action:"info",eid,etype},null,true,page_root);
			},(err) => {
				toast(`Απέτυχε: ${JSON.stringify(err)}`);
			});
			return;
		}
		toast("Η ανοικτή σελίδα δεν αντιστοιχεί σε κατάστημα");
	}
}

let animating=false;
let animating_timeout=0;

function animating_started() {
	animating=true;
	requestAnimationFrame(function loop() {
		tx_map.resized();
		if (animating)
			requestAnimationFrame(loop);
	});
	clearTimeout(animating_timeout);
	animating_timeout=setTimeout(() => {
		animating=false;
	},220);
}

export function minimize(noanimation) {
	if (minimized) return;
	var bottom=mainpane.offsetHeight-infobox_pos.top-$infobox.outerHeight();
	var duration=noanimation ? 0 : infobox_animation_duration;
	$infoboxo.css("display","block");
	infobox.style.marginBottom=(infoboxh.offsetHeight-infobox_flex_basis)+"px";
	$infobox
		.css({top:"",bottom:bottom})
		.addClass("minimized")
		.stop(true)
		.animate({
			left:246,
			bottom:12,
			scale:0.2
		},duration);
	animating_started();
	minimized=true;
}

export function restore(noanimation) {
	tx_map.cancel_select_coordinates();
	if (!minimized) return;
	var duration=noanimation ? 0 : infobox_animation_duration;
	infobox.style.marginBottom="0px";
	$infobox
		.css({top:infobox.offsetTop,bottom:""})
		.removeClass("minimized")
		.stop(true)
		.animate({
			left:infobox_pos.left,
			top:infobox_pos.top,
			scale:1
		},{
			duration:duration,
			complete:function() {$infoboxo.css("display","")}
		});
	animating_started();
	minimized=false;
}

export function validate_user_comment(vars) {
	if (!vars.comment || !vars.comment.length) {
		toast("Το σχόλιο δεν πρέπει να είναι κενό",5000);
		return false;
	}
	if (vars.comment.length>4096) {
		toast("Το σχόλιο δεν πρέπει να υπερβαίνει τους 4096 χαρακτήρες",8000);
		return false;
	}

	if (vars.comment.length>20) {
		var m_gr_lc=vars.comment.match(/[α-ω]/g);
		var m_gr_uc=vars.comment.match(/[Α-Ω]/g);
		if (!m_gr_lc) {
			if (m_gr_uc && m_gr_uc.length>10) {
				if (!confirm('Το σχόλιό σας μοιάζει να είναι γραμμένο στα ΚΕΦΑΛΑΙΑ, κάτι που αν όντως ισχύει, μειώνει την πιθανότητα του να εγκριθεί στο 0,001%! Θέλετε να συνεχιστεί η υποβολή του σχολίου;')) return false;
			}
			else if (/[A-Za-z]/.test(vars.comment) && /^(?:http|www)/i.test(vars.comment)==false && /\b(?:the|be|of|and|a|in|that|have|it|for|not|on|with|you|at|this|but|his|by|from|they|we|say|her|she|or|will|my|one|all|would|there|their|what|so|up|out|if|about|who|get|which|go|when|make|can|like|time|no|just|him|know|take|person|into|year|your|good|some|could|them|see|other|than|then|now|look|only|come|its|over|think|also|back|after|use|two|how|our|work|first|well|way|even|new|want|because|any|these|give|day|most|us|food|restaurant|thank|thanks|thnx|recommended)\b/i.test(vars.comment)==false) {
				if (!confirm('Το σχόλιό σας μοιάζει να είναι γραμμένο σε greeklish, κάτι που αν όντως ισχύει, μειώνει την πιθανότητα του να εγκριθεί στο 0,001%! Θέλετε να συνεχιστεί η υποβολή του σχολίου;')) return false;
			}
		}
		else if (m_gr_uc && m_gr_lc.length/m_gr_uc.length<1.2) {
			if (!confirm('Ένα μεγάλο κομμάτι του σχόλιού σας μοιάζει να είναι γραμμένο στα ΚΕΦΑΛΑΙΑ, κάτι που αν όντως ισχύει, μειώνει τις πιθανότητες του να εγκριθεί στο 0,1%! Θέλετε να συνεχιστεί η υποβολή του σχολίου;')) return false;
		}
	}
	if (/σολωμ[οό][ςσυύ]?/i.test(vars.comment)) {
		toast("Μόλις υποπέσατε στο πιο κλασικό ορθογραφικό λάθος ονόματος φαγητού στον ταβερνοχώρο: το ψάρι σολομός γράφεται με όμικρον!!! Με ωμέγα είναι μόνο ο Διονύσιος Σολωμός! Αυτά για την επόμενη φορά, το παρόν σχόλιο θα υποβληθεί ως έχει...",12000);
	}

	return true;
}

export function validate_delete(vars) {
	return confirm("Διαγραφή;");
}

export function validate_set_email(vars,form) {
	var $loading=$(".loading",form).show();
	var $error=$("div.error",form).text("");
	var $success=$("div.success",form).hide();
	var $inputs=$("input",form).prop("disabled",true);
	var done=function(err) {
		$loading.hide();
		$error.text(err);
		$inputs.prop("disabled",false);
		if (!err) $success.show();
	}
	tx_util.api_request("set_email",vars.email,function(res,err) {
		if (!err)
			done();
		else if (tx_util.extract_argno_from_err(err)===0)
			done("Μη έγκυρη διεύθυνση email");
		else if (err.code===12)
			browse("x/account_settings");
		else if (err.code===6)
			done("Η διεύθυνση email χρησιμοποιείται ήδη από άλλον χρήστη");
		else if (err.code===16)
			done("Η διεύθυνση email δεν είναι αποδεκτή, δοκιμάστε ξανά με μία διαφορετική");
		else
			done("Παρουσιάστηκε σφάλμα ("+err.code+")");
	});
}

export function validate_set_password(vars,form) {
	var $loading=$(".loading",form).show();
	var $error=$("div.error",form).text("");
	var $success=$("div.success",form).hide();
	var $inputs=$("input",form).prop("disabled",true);
	var done=function(err) {
		$loading.hide();
		$error.text(err);
		$inputs.prop("disabled",false);
		if (!err) $success.show();
	}
	if (vars.new_password!==vars.new_password2)
		return done("Οι δύο κωδικοί πρόσβασης διαφέρουν");
	if (vars.new_password.length<6)
		return done("Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες");
	tx_util.api_request("set_password",vars.new_password,function(res,err) {
		if (!err)
			done();
		else if (err.code===-32602)
			done("Μη έγκυρος κωδικός πρόσβασης");
		else if (err.code===12)
			browse("x/account_settings");
		else if (err.code===13)
			done("Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες");
		else
			done("Παρουσιάστηκε σφάλμα ("+err.code+")");
	});
}

export function validate_req_pw_reset(vars,form) {
	tx_tabs.set_busy(form,true);
	var $error=$("div.error",form).text("");
	var $inputs=$("input",form).prop("disabled",true);
	var done=function(err) {
		tx_tabs.set_busy(form,false);
		$error.text(err);
		if (err)
			$inputs.prop("disabled",false);
		else
			$("div.success",form).show();
	}
	tx_util.api_request("request_password_reset",vars.email,function(res,err) {
		var m;
		if (!err)
			done();
		else if (err.code===-32602 || err.code===18)
			done("Άγνωστη διεύθυνση email");
		else
			done("Παρουσιάστηκε σφάλμα ("+err.code+")");
	});
}

export function validate_password_reset(vars,form) {
	tx_tabs.set_busy(form,true);
	var $error=$("div.error",form).text("");
	var $inputs=$("input",form).prop("disabled",true);
	var done=function(err) {
		tx_tabs.set_busy(form,false);
		$error.text(err);
		if (err)
			$inputs.prop("disabled",false);
		else
			$("div.success",form).show();
	}
	if (vars.new_password!==vars.new_password2)
		return done("Οι δύο κωδικοί πρόσβασης διαφέρουν");
	if (vars.new_password.length<6)
		return done("Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες");
	tx_util.api_request("reset_password",vars.reset_code,vars.new_password,function(res,err) {
		const errarg=tx_util.extract_argno_from_err(err);
		if (!err)
			done();
		else if (err.code===13 || errarg===1)
			done("Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες");
		else if (err.code===23 || errarg===0)
			done("Μη έγκυρος κωδικός επιβεβαίωσης");
		else
			done("Παρουσιάστηκε σφάλμα ("+err.code+")");
	});
}

export function validate_reset_google(vars,form) {
	tx_tabs.set_busy(form,true);
	var $error=$("div.error",form).text("");
	var done=function(err) {
		tx_tabs.set_busy(form,false);
		$error.text(err);
	}
	tx_accounts.get_google_token(function(auth_token) {
		if (!auth_token) return done("Η σύνδεση με το λογαριασμό Google απέτυχε");
		tx_util.api_request("reset_google",vars.reset_code,auth_token,function(res,err) {
			const errarg=tx_util.extract_argno_from_err(err);
			if (!err) {
				tx_accounts.login(res);
				done();
				show_success(form,"Η σύνδεση ολοκληρώθηκε επιτυχώς","Επιτυχία");
			}
			else if (err.code===8)
				done("Το αίτημα απέτυχε, δοκιμάστε ξανά σε λίγο");
			else if (err.code===11)
				done("Το αίτημα απέτυχε");
			else if (err.code===14)
				done("Ο λογαριασμός google είναι ήδη συνδεδεμένος με κάποιον άλλο λογαριασμό του ταβερνοχώρου");
			else if (err.code===23 || errarg===0)
				done("Μη έγκυρος κωδικός επιβεβαίωσης");
			else if (errarg===1)
				done("Μη έγκυρος κωδικός google");
			else
				done("Παρουσιάστηκε σφάλμα ("+err.code+")");
		});
	});
}

export function validate_logout_other_sessions(vars,form) {
	var $loading=$(".loading",form).show();
	var $error=$("div.error",form).text("");
	var $inputs=$("input",form).prop("disabled",true);
	var done=function(err) {
		$loading.hide();
		$error.text(err);
		$inputs.prop("disabled",false);
		if (!err) $("div.success",form).show();
	}
	tx_util.api_request("logout_other_sessions",function(res,err) {
		var m;
		if (!err)
			done();
		else
			done("Απέτυχε");
	});
}

export function validate_confirm_email_pw(vars,form) {
	tx_tabs.set_busy(form,true);
	var $error=$("div.error",form).text("");
	var $inputs=$("input",form).prop("disabled",true);
	var done=function(err) {
		tx_tabs.set_busy(form,false);
		$error.text(err);
		if (err)
			$inputs.prop("disabled",false);
		else
			$("div.success",form).show();
	}
	const method=vars.after_signup ? "confirm_email_login" : "confirm_email_pw";
	tx_util.api_request(method,vars.confirmation_code,vars.password,function(res,err) {
		const errarg=tx_util.extract_argno_from_err(err);
		if (!err) {
			done();
			if (vars.after_signup && res) {
				tx_accounts.login(res);
				load_page_content(form,"x/signup_success");
			}
		}
		else if (err.code===2 || errarg===1)
			done("Λανθασμένος κωδικός πρόσβασης");
		else if (err.code===22 || errarg===0)
			done("Μη έγκυρος κωδικός επιβεβαίωσης");
		else
			done("Παρουσιάστηκε σφάλμα ("+err.code+")");
	});
}

export function validate_info_editor(vars,node) {
	vars.closed=
		vars.not_open_yet ? "not_open_yet" :
		vars.permanently_closed ? "permanently" :
		vars.closed===undefined || vars.closed==="" ? null :
			vars.closed;
	delete vars.permanently_closed;
	delete vars.not_open_yet;
	const extra_keywords=[];
	for (const name of Object.keys(vars)) {
		const keyword=(/^keyword:(.+)/.exec(name)||[])[1];
		if (keyword) {
			if (vars[name])
				extra_keywords.push(keyword);
			delete vars[name];
		}
	}
	(vars.keywords||(vars.keywords=[])).unshift(...extra_keywords);
	if (vars.store_front) {
		const m=/^(\d+(?:\-\d+)?)\/(\d+)(\s?[adαδ])?$/i.exec(vars.store_front);
		if (m) {
			let value=`Πρόσοψη ${m[1]}/${m[2]}`;
			if (!m[3])
				value+=".";
			else if ("aα".indexOf(m[3])>=0)
				value+=" αριστερά από την είσοδο.";
			else if ("dδ".indexOf(m[3])>=0)
				value+=" δεξιά από την είσοδο.";
			else {
				toast("Interal error: store_front regex");
				return;
			}
			vars.store_front=value;
		}
	}
	if (!vars.closed && vars.date_closed) {
		toast("Υπάρχει ημερομηνία κλεισίματος, χωρίς όμως το σημείο να έχει δηλωθεί ως κλειστό");
		return;
	}
	if (!vars.closed && vars.moved_to && !confirm("Το κατάστημα μεταφέρθηκε, χωρίς όμως το σημείο να έχει δηλωθεί ως κλειστό, είσαι σίγουρος;"))
		return;
	if (vars.accuracy==null && vars.not_accurate!=null)
		vars.accuracy=(tx_accounts.get_user_id()===1 ? [0,1] : [90,91])[vars.not_accurate|0];
	if (vars.no_name) vars.name='[Χωρίς Όνομα]';
	const notes_for_admin=[];
	if (vars.part_of_a_chain)
		notes_for_admin.push("Ανήκει σε αλυσίδα");
	if (vars.replaces) {
		const m=/^([tc]\d{1,8}) \((.+)\)$/.exec(vars.replaces);
		notes_for_admin.push(m
			? `Στη θέση του υπήρχε το [tx=${m[1]}]${m[2]}[/tx]`
			: "Άνοιξε στη θέση του: "+vars.replaces
		);
	}
	if (vars.notes_for_admin)
		notes_for_admin.push(vars.notes_for_admin);
	vars.notes_for_admin=notes_for_admin.join("\n\n")||null;
	if (vars.self_described) {
		const self_described=`Αυτοχαρακτηρίζεται "${vars.self_described}".`;
		vars.description=vars.description ? `${vars.description}\n\n${self_described}` : self_described;
		delete vars.self_described;
	}
	vars.www=vars.www||[];
	if (vars.website) vars.www.push(vars.website);
	if (vars.facebook) vars.www.push(vars.facebook);
	if (vars.instagram) vars.www.push(vars.instagram);
	tx_tabs.set_busy(node,true);
	Object.assign(vars,{lat:null,lon:null},vars.latlon);
	if (vars.add_entry_checks==null)
		delete vars.add_entry_checks;
	else {
		for (const check of vars.add_entry_checks)
			check.on_site=false;
	}
	street_view_editor_updated(false);
	const page_root=node.closest(".page-root");
	const args=
		vars.propose ? ["propose_new_entry",vars] :
		vars.eid ? ["set_entry",vars.eid,vars.etype,vars,vars.minor_edit] :
			["add_entry",vars];
	tx_util.api_request_promise(...args).then(res => {
		window.__tx_on_entry?.(args[0], vars);
		//page_root.querySelector(".street_view_editor_ref")?.remove();
		const street_view_editor_ref=page_root.querySelector(".street_view_editor_ref");
		if (street_view_editor_ref)
			street_view_editor_ref.remove();
		if (vars.eid) {
			// submit_form will take care of tx_tabs.set_busy(node,false):
			submit_form(null,node,'merge_entry',{action:'info',eid:vars.eid,etype:vars.etype},null,true);
			if (vars.etype===0) tx_data.refresh_point(vars.eid);
			const latlon_div=page_root.querySelector(".metadata.latlon");
			if (latlon_div) {
				latlon_div.textContent=JSON.stringify({lat:vars.lat,lon:vars.lon});
				if (infoboxc.contains(latlon_div)) metadata_updated();
			}
		}
		else {
			const page="tc"[res.etype]+res.eid;
			browse(page).catch(tx_util.do_nothing).then(() => {
				tx_tabs.set_busy(node,false);
				replace_tabs_page(node,mke(
					".success","Η ",mke("a",{href:"?p="+page,onclick:ev => {ev.preventDefault(); browse(page)}},"νέα εγγραφή")," δημιουργήθηκε επιτυχώς"
				));
			});
			if (res.etype===0) tx_data.refresh_point(res.eid);
		}
	},err => {
		street_view_editor_updated(true);
		tx_tabs.set_busy(node,false);
		let msg;
		let field_name;
		if (err.code===31) {
			msg="Η αλυσίδα δεν υπάρχει";
			field_name="parent";
		}
		else if (err.code===36) {
			msg="Το κατάστημα δεν υπάρχει (μεταφέρθηκε στο)";
			field_name="moved_to";
		}
		else if (err.code===37) {
			msg="Το κατάστημα χρησιμοποιείται ήδη (μεταφέρθηκε στο)";
			field_name="moved_to";
		}
		else if (err.code===38) {
			msg="Το κατάστημα δεν υπάρχει (μεταφέρθηκε από)";
			field_name="moved_from";
		}
		else if (err.code===39) {
			msg="Το κατάστημα χρησιμοποιείται ήδη (μεταφέρθηκε από)";
			field_name="moved_from";
		}
		else if (err.code===30) {
			msg="Δεν υπάρχει τέτοιο κατάστημα στο e-table";
			field_name="etable_id";
		}
		else if (err.code===40) {
			msg="Το κατάστημα του e-table χρησιμοποιείται ήδη";
			field_name="etable_id";
		}
		else
			msg=err.message+" ("+err.code+")"+(err.data ? ": "+err.data : "")
		if (field_name) {
			const input=node.querySelector('[name="'+field_name+'"]');
			// only use reportValidity if the element is vibisle
			if (input && input.offsetParent) {
				input.setCustomValidity(msg.replace(/ \(.+?\)$/,""));
				input.reportValidity();
				msg=null;
			}
		}
		if (msg) toast(msg);
	});
}

export function validate_comment_editor(vars,node) {
	var $textarea=$("textarea",node);
	if ($textarea.text()===vars.comment)
		delete(vars.comment);
	return true;
}

export function find_form(node) {
	var form=node;
	while (form.nodeType!==1 || form.tagName!=="FORM")
		form=form.parentNode;
	return form;
}

// page_root is optional, but must be provided if the page is detached
export function submit_form(ev,node,mode,vars,validator,no_form_vars,page_root) {
	if (ev) tx_util.prevent_default(ev);
	const tabs_page_content=node.closest(".tabs_page_content");
	if (!tabs_page_content) return;
	page_root=page_root||tabs_page_content.closest(".page-root");
	if (!page_root) return;
	vars=vars||{};
	if (!no_form_vars) {
		for (const elt of tabs_page_content.querySelectorAll(".metadata_page.ref"))
			vars.ref=elt.textContent;
		if (node.nodeType===1 && node.tagName==="FORM") {
			const vars_=tx_input_validation.get_form_values(node);
			if (vars_) Object.assign(vars,vars_);
		}
	}
	if ((validator && !validator(vars,node)) || !mode) return;
	tabs_page_content._set_busy(true);
	new tx_util.HtmlDownloader().get(mode,vars,function(div) {
		page_root.querySelector(".tabs_body").scrollTop=0;
		tabs_page_content.innerHTML="";
		tabs_page_content.appendChild(div);
		tabs_page_content._set_busy(false);
		process_metadata_patches(page_root,div);
	},function() {
		if (infoboxc.contains(tabs_page_content)) metadata_updated();
		for (const title_node of tabs_page_content.querySelectorAll(".metadata_page.title")) {
			tx_tabs.set_title(tabs_page_content,title_node.textContent);
			title_node.parentNode.removeChild(title_node);
		}
	});
}

export function load_page_content(node,page,vars) {
	submit_form(null,node,"infobox_content",Object.assign({},vars,{p:page}),undefined,true);
}

function replace_tabs_page(node,content) {
	const tabs_page_content=node.closest(".tabs_page_content");
	if (tabs_page_content) {
		$(tabs_page_content).empty();
		tabs_page_content.appendChild(content);
		if (infoboxc.contains(tabs_page_content)) metadata_updated();
	}
}

export function show_success(node,msg,title) {
	const content=document.createDocumentFragment();
	content.appendChild(mke(".success",...(msg.split("\n").map(m => mke("div",m)))));
	if (title)
		content.appendChild(mke("script.metadata",{type:"application/json"},JSON.stringify({title})));
	replace_tabs_page(node,content);
}

export function add_google(node) {
	tx_accounts.get_google_token(function(auth_token) {
		if (!auth_token) return;
		tx_util.api_request("set_google",auth_token,function(res,err) {
			if (!err)
				show_success(node,"Μπορείτε από εδώ και πέρα να συνδέεστε και μέσω του λογαριασμού σας στη Google");
			else if (err.code===1 || err.code===12)
				browse("x/account_settings");
			else if (err.code===8)
				toast("Το αίτημα απέτυχε, δοκιμάστε ξανά σε λίγο");
			else if (err.code===11)
				toast("Το αίτημα απέτυχε");
			else if (err.code===14)
				toast("Ο λογαριασμός google είναι ήδη συνδεδεμένος με κάποιον άλλο λογαριασμό του ταβερνοχώρου",8000);
			else
				toast("Παρουσιάστηκε σφάλμα ("+err.code+")",5000);
		});
	});
}

export function set_comment_approval(node,id,approval) {
	tx_util.api_request("set_comment_approval",id,approval,(res,err) => {
		if (err)
			toast("Η έγκριση/απόρριψη του σχολίου απέτυχε\n"+JSON.stringify(err),Infinity);
		else
			tx_util.set_elt_approval(node.closest(".comment"),approval);
	});
}

export function propose_new_entry(args={}) {
	if (!tx_accounts.can("propose_new_entries")) {
		toast("Πρέπει να εγγραφείς ή να συνδεθείς πρώτα",5000);
		browse("x/login");
		return;
	}
	if (args.parent!=null && !/^\d{1,5}$/.test(args.parent))
		return;
	tx_history.push_history();
	tx_map.select_coordinates_center(args.latlon).then(latlon => {
		const latlon_str=latlon.lat+","+latlon.lon;
		const page=
			args.parent!=null
				? `propose_new_chain_store_${args.parent}_${latlon_str}`
				: `propose_new_entry${args.compact ? "_compact" : ""}_${latlon_str}`;
		browse(page,null,true);
	},tx_util.do_nothing);
}

export async function update_prefecture(prefecture_select,lat,lon) {
	if (prefecture_select) {
		const prefecture=await tx_util.api_request_promise("guess_prefecture",lat,lon);
		if (prefecture) {
			const old_prefecture=prefecture_select.value;
			if (old_prefecture!==prefecture && old_prefecture)
				toast(`Αυτόματη αλλαγή νομού από ${old_prefecture} σε ${prefecture}`);
			prefecture_select.value=prefecture;
			tx_input_validation.get_value(prefecture_select); // validate new value
		}
	}
}

export function pick_coordinates_center(elt) {
	const coords_input=elt.parentElement.querySelector('[name="latlon"]');
	const latlon=tx_input_validation.get_value(coords_input);
	if (latlon===undefined && coords_input.value.length!==0)
		toast("Μη έγκυρες συντεταγμένες");
	else {
		tx_history.push_history();
		const tlatlon_div=elt.closest(".tabs_page_content").querySelector(".metadata.tlatlon");
		if (latlon && tlatlon_div) {
			tlatlon_div.textContent=JSON.stringify({tlat:latlon.lat,tlon:latlon.lon});
			if (infoboxc.contains(tlatlon_div)) metadata_updated();
		}
		const prefecture_select=elt.closest("form").querySelector('select[name="prefecture"]');
		tx_map.select_coordinates_center(latlon).then(({lat,lon}) => {
			coords_input.value=`${lat} ${lon}`;
			tx_input_validation.get_value(coords_input); // validate new value
			if (tlatlon_div) {
				tlatlon_div.textContent=JSON.stringify({tlat:lat,tlon:lon});
				if (infoboxc.contains(tlatlon_div)) metadata_updated();
			}
			update_prefecture(prefecture_select,lat,lon);
			tx_history.back();
		},tx_util.do_nothing);
	}
}

export function pick_coordinates(elt) {
	minimize();
	const coords_input=elt.parentElement.querySelector('[name="latlon"]');
	const latlon=tx_input_validation.get_value(coords_input);
	if (latlon===undefined && coords_input.value.length!==0)
		toast("Μη έγκυρες συντεταγμένες");
	else {
		const tlatlon_div=elt.closest(".tabs_page_content").querySelector(".metadata.tlatlon");
		if (latlon && tlatlon_div) {
			tlatlon_div.textContent=JSON.stringify({tlat:latlon.lat,tlon:latlon.lon});
			if (infoboxc.contains(tlatlon_div)) metadata_updated();
		}
		const prefecture_select=elt.closest("form").querySelector('select[name="prefecture"]');
		tx_map.select_coordinates(({lat,lon}) => {
			coords_input.value=`${lat} ${lon}`;
			tx_input_validation.get_value(coords_input); // validate new value
			if (tlatlon_div) {
				tlatlon_div.textContent=JSON.stringify({tlat:lat,tlon:lon});
				if (infoboxc.contains(tlatlon_div)) metadata_updated();
			}
			update_prefecture(prefecture_select,lat,lon);
			restore();
		});
	}
}

export function on_latlon_change(coords_input) {
	const latlon=tx_input_validation.get_value(coords_input);
	const tlatlon_div=coords_input.closest(".tabs_page_content").querySelector(".metadata.tlatlon");
	if (tlatlon_div) {
		tlatlon_div.textContent=!latlon ? "{}" : JSON.stringify({tlat:latlon.lat,tlon:latlon.lon});
		if (infoboxc.contains(tlatlon_div)) metadata_updated();
	}
}

export function show_editor_coordinates(node) {
	const latlon=tx_input_validation.get_value(node.parentElement.querySelector('[name="latlon"]'));
	if (latlon) 
		tx_map.show_on_map(latlon.lat,latlon.lon);
	else
		toast("Μη έγκυρες συντεταγμένες");
}

export function pick_street_view(node) {
	const sv_data=tx_input_validation.get_value(node.parentElement.querySelector('[name="street_view"]'));
	if (sv_data)
		tx_map.show_street_view(sv_data);
	else {
		minimize();
		tx_map.select_street_view_on_map();
	}
}

const copy_from_parent_fields=["name","categories","www","keywords"];

export function copy_values_from_parent(btn) {
	const form=btn.closest("form.info-editor");
	const parent_input=form.querySelector('[name="parent"]');
	const parent_entry=tx_input_validation.get_value(parent_input);
	if (!parent_entry)
		return;
	btn.disabled=true;
	tx_util.api_request_promise("get_entry",parent_entry.eid,parent_entry.etype).then((info) => {
		for (const name of copy_from_parent_fields) {
			if (name!=="categories") {
				const input=form.querySelector(`[name="${name}"]`);
				const cur_value=tx_input_validation.get_value(input);
				const set=input.value==="" || (
					JSON.stringify(info[name])!==JSON.stringify(cur_value) &&
						confirm(`Αλλαγή του πεδίου ${name} από ${JSON.stringify(cur_value)} σε ${JSON.stringify(info[name])};`)
				);
				if (set) {
					input.value=
						name==="keywords" ? info[name].join(", ") :
						name==="www" ? info[name].join("\n") :
							info[name];
					tx_input_validation.get_value(input);
				}
			}
			else {
				const cur_value=[...form.querySelectorAll('[name="categories[]"]')].map(x => x.value);
				const set=cur_value.length===0 || (
					JSON.stringify(info[name])!==JSON.stringify(cur_value) &&
						confirm(`Αλλαγή του πεδίου ${name} από ${JSON.stringify(cur_value)} σε ${JSON.stringify(info[name])};`)
				);
				if (set) {
					const editor=form.querySelector(".categories_editor");
					while (editor._get_count()>0)
						editor._remove_category(0);
					for (const category of info[name])
						editor._add_category(category);
				}
			}
		}
	},(err) => {
		toast(`Απέτυχε ${JSON.stringify(err)}`);
	}).finally(() => {
		btn.disabled=false;
	});
}

const month_lengths=[31,29,31,30,31,30,31,31,30,31,30,31];

export function parse_entry_check(str) {
	const matches=/^((\d{4})(?:(\d{2})(\d{2})?)?)(?: +(.{1,256}))?$/.exec(str);
	if (matches) {
		const y=matches[2]|0;
		const m=matches[3] ? matches[3]|0 : 1;
		const d=matches[4] ? matches[4]|0 : 1;
		if (y>0 && m>=1 && m<=12 && d<=month_lengths[m-1] && (m!==2 || d!==29 || (y%4===0 && (y%100 || y%400===0))))
			return {date:matches[1],comment:matches[5]||null};
	}
}

export function parse_entry_checks(str) {
	const lines=str.split("\n");
	if (lines[lines.length-1]==="")
		lines.pop();
	const checks=[];
	for (const line of lines) {
		const check=parse_entry_check(line);
		if (!check) return;
		checks.push(check);
	}
	return checks;
}

export function add_entry_check_prompt(eid,etype,element,readonly) {
	let check;
	let str=prompt();
	while (true) {
		if (str==null) return;
		check=parse_entry_check(str);
		if (check) break;
		str=prompt("Μη έγκυρη τιμή",str);
	}
	add_entry_check(eid,etype,check.date,check.comment,element,readonly);
}

export function add_entry_check(eid,etype,date,comment,element,readonly) {
	let control=element;
	control && (control=control.closest(".tabs_page_content")) && (control=control.querySelector(".entry-checks"));
	tx_data.add_check(eid,etype,{date,comment,on_site:false}).then(res => {
		if (control) entry_checks_control_add(control,res.entry_check,readonly);
	},err => {
		toast("Η προσθήκη νέου ελέγχου απέτυχε: "+JSON.stringify(err));
	});
}

const entry_checks_control_check=Symbol();

function entry_checks_control_add(control,check,readonly) {
	let title=`${new Date(check.created*1000)} από ${check.user.name}`;
	if (check.on_site) title+=", επιτόπια";
	let text=check.date;
	if (check.comment!=null) text+=" "+check.comment;
	const div=mke(".check",mke("span.admin_only.check",{title,onclick() {toast(title,5000)}},text));
	if (!readonly && (tx_accounts.can("edit_entries") || tx_accounts.get_user_id()===check.user.id)) {
		div.append(mke("span",
			" (",
			mke("a",{onclick() {delete_entry_check(check.id,this)}},"διαγραφή"),
			")",
		));
	}
	div[entry_checks_control_check]=check;
	let sibling=control.firstElementChild;
	while (sibling) {
		const sibling_check=sibling[entry_checks_control_check];
		if (sibling_check.date<check.date) break;
		sibling=sibling.nextElementSibling;
	}
	if (control.parentElement)
		$(div).hide();
	control.insertBefore(div,sibling);
	if (control.parentElement)
		$(div).slideDown();
}

export function entry_checks_control(element,checks,readonly) {
	const div=mke(".entry-checks");
	for (const check of checks) entry_checks_control_add(div,check,readonly);
	$(element).replaceWith(div);
}

export function delete_entry_check(id,elt) {
	if (!confirm("Διαγραφή;")) return;
	const btn=elt.closest("span");
	const row=btn.closest(".check");
	btn.style.display="none";
	tx_data.delete_entry_check(id).then(() => {
		$(row).slideUp(() => {row.remove()});
	},err => {
		btn.style.display="";
		toast("Η διαγραφή του ελέγχου απέτυχε: "+JSON.stringify(err));
	});
}

export function accept_entry_check(id,elt,dont_hide) {
	const btn=elt.closest("span");
	const row=btn.closest(".check");
	btn.style.visibility="hidden";
	tx_util.api_request_promise("accept_entry_check",id).then(() => {
		if (!dont_hide)
			$(row).slideUp(() => {row.remove()});
	},err => {
		btn.style.visibility="";
		toast("Η αποδοχή του ελέγχου απέτυχε: "+JSON.stringify(err));
	});
}

export function delete_hours_check(id,elt) {
	if (!confirm("Διαγραφή;")) return;
	const btn=elt.closest("span");
	const row=btn.closest(".check");
	btn.style.display="none";
	tx_data.delete_hours_check(id).then(() => {
		if (row.tagName==="TR")
			$(row).children("td").wrapInner("<div/>").children().slideUp(() => {row.remove()});
		else
			$(row).slideUp(() => {row.remove()});
	},err => {
		btn.style.display="";
		toast("Η διαγραφή του ελέγχου απέτυχε: "+JSON.stringify(err));
	});
}

export function accept_hours_check(id,elt,dont_hide) {
	const btn=elt.closest("span");
	const row=btn.closest(".check");
	btn.style.visibility="hidden";
	tx_util.api_request_promise("accept_hours_check",id).then(() => {
		if (!dont_hide)
			$(row).slideUp(() => {row.remove()});
	},err => {
		btn.style.visibility="";
		toast("Η αποδοχή του ελέγχου απέτυχε: "+JSON.stringify(err));
	});
}

const _check_status_dataset=Symbol();
const _check_status_timeout=Symbol();

function check_value(elt,check,timeout=500) {
	if (!elt[_check_status_dataset])
		elt[_check_status_dataset]=elt.closest(".check-status-container").querySelector(".check-status").dataset;
	elt[_check_status_dataset].status="";
	clearTimeout(elt[_check_status_timeout]);
	elt[_check_status_timeout]=setTimeout(() => {
		const str=tx_input_validation.get_value(elt);
		if (str) {
			check(str).then((status) => {
				elt[_check_status_dataset].status=status|0;
			},tx_util.do_nothing);
		}
	},timeout);
}

export function check_date_opened(elt) {
	check_value(elt,(str) =>
		tx.api_request_promise("parse_date_opened",str).then(([f,t]) => !!t)
	);
}

export function check_phone(elt) {
	check_value(elt,(str) =>
		tx.api_request_promise("check_phones",str.split(",")).then(vs => vs.every(x => x))
	);
}

function is_afm_valid(afm) {
	if (!/^\d{9}$/.test(afm) || afm === "000000000")
		return false;
	let sum = 0;
	for (let i = 0; i < 8; i++)
		sum += afm[i] << (8 - i);
	return ((sum % 11) % 10) === (afm[8] | 0);
}

export function check_afm(elt) {
	check_value(elt,(str) => Promise.resolve(is_afm_valid(str)),10);
}

export function categories_editor(node,categories,all_categories,allow_zero,show_note_for_loipa,auto_size) {
	const hidden_input=mke("input",{type:"hidden",name:"categories","data-validate":"[]~"});
	tx_input_validation.enable_validation(hidden_input);
	const note_loipa=mke(
		{style:{display:"none","font-size":"90%"}},
		mke({style:{color:"red"}},"Μια καφετέρια που έχει απλά ένα-δύο τοστάκια δεν αξίζει να μπει στον ταβερνοχώρο!"),
		mke("",'Για τα "Λοιπά", γράψε στις παρατηρήσεις τι είδους κατάστημα είναι, πχ παγωτατζίδικο.'),
	);
	const div=mke(".categories_editor",hidden_input,note_loipa);
	let count=0;
	let select_new;
	function check_validity() {
		if (count===0 && !allow_zero)
			select_new.setCustomValidity("Πρέπει να οριστεί τουλάχιστο μία κατηγορία");
	}
	const on_input=(ev) => {
		const select=ev.currentTarget;
		if (select.value==="Λοιπά" && show_note_for_loipa)
			$(note_loipa).slideDown();
		if (select.getAttribute("name")) {
			if (!select.value)
				remove_select(select);
		}
		else {
			if (select.value) {
				select.name="categories[]";
				count++;
				// this will update select_new:
				add_select();
				$(select_new).hide().slideDown("fast");
			}
		}
	};
	function add_select(category) {
		const select=mke("select",mke("option"));
		if (auto_size)
			select.classList.add("auto-size");
		select.addEventListener("input",on_input);
		note_loipa.before(select);
		if (select_new) select_new.setCustomValidity("");
		if (category) {
			select.name="categories[]";
			count++;
		}
		else {
			select_new=select;
			check_validity();
		}
		for (const cat_name of all_categories)
			select.appendChild(mke("option",{value:cat_name,selected:category===cat_name ? "selected" : undefined},cat_name));
	}
	function remove_select(select) {
		select.removeEventListener("input",on_input);
		select.removeAttribute("name");
		count--;
		check_validity();
		$(select).slideUp("fast",() => {select.remove()});
	}
	for (const category of categories)
		add_select(category);
	add_select();
	div._add_category=(category) => {
		if (!all_categories.includes(category))
			return;
		if (!select_new)
			add_select();
		select_new.value=category;
		on_input({currentTarget:select_new});
	};
	div._remove_category=(idx) => {
		const select=div.querySelectorAll(".categories_editor > select[name]")[idx];
		if (select)
			remove_select(select);
	};
	div._get_count=() => count;
	$(node).replaceWith(div);
}

export function delete_invite(button,code) {
	button.disabled=true;
	if (!confirm("Διαγραφή πρόσκλησης;")) {
		button.disabled=false;
		return false;
	}
	tx_util.api_request("cancel_invite",code,function(res,err) {
		if (err) {
			if (err.code===31)
				toast("Δεν υπάρχει η πρόσκληση αυτή");
			else
				toast("Απέτυχε\n"+JSON.stringify(err),Infinity);
			button.disabled=false;
		}
		else {
			var $form=$(find_form(button));
			$form.slideUp(function() {$form.remove()});
		}
	});
	return false;
}

export function validate_user_notes(vars,form) {
	var $loading=$(".loading",form).show();
	var $inputs=$("input,textarea,button",form).prop("disabled",true);
	tx_util.api_request("set_user_notes",vars.user_id,vars.notes,function(res,err) {
		if (err) toast("Παρουσιάστηκε σφάλμα ("+JSON.stringify(err)+")",Infinity);
		$loading.hide();
		$inputs.prop("disabled",false);
	});
}

export function login_reset_code(elt,reset_code) {
	elt=elt.parentElement;
	tx_tabs.set_busy(elt,true);
	tx_accounts.login_reset_code(reset_code).then(
		() => {
			show_success(elt,"Η σύνδεση ολοκληρώθηκε επιτυχώς");
		},
		(err) => {
			if (err.code===-32602 || err.code===23)
				toast("Μη έγκυρος κωδικός επιβεβαίωσης");
			else if (err.code===7)
				toast("Ο λογαριασμός δεν έχει ενεργοποιηθεί ακόμα");
			else
				toast(`Παρουσιάστηκε σφάλμα (${err.code})`);
			tx_tabs.set_busy(elt,false);
		},
	);
}

export function checkbox_toggle_dependents(element,flags,...names) {
	const negate=flags.includes("n");
	const disable=flags.includes("d");
	const hide=flags.includes("h");
	const form=element.closest("form");
	if (form) {
		//const value=element.checked xor negate;
		const value=element.checked!==(!!negate);
		for (const name of names) {
			for (const elt of form.querySelectorAll('input[name="'+name+'"]')) {
				if (elt.type!=="hidden") {
					if (disable) elt.disabled=value;
					if (hide) elt.closest(".ext-field").style.display=value ? "none" : "";
				}
			}
		}
	}
}

export function zoom_on_map(elt) {
	const page_root=elt.closest(".page-root");
	if (!page_root) return;
	const latlon_div=page_root.querySelector(".metadata.latlon");
	if (!latlon_div) return;
	const latlon=JSON.parse(latlon_div.textContent);
	minimize();
	tx_map.zoom_on_map(latlon.lat,latlon.lon);
}

export function delete_entry(eid,etype) {
	if (!confirm("Σίγουρα;")) return;
	tx_util.api_request_promise("delete_entry",eid,etype).then(() => {
		reload();
		if (etype===0) tx_data.refresh_point(eid);
	},err => {
		toast("Απέτυχε\n"+JSON.stringify(err),Infinity);
	});
}

export function ban_user(elt,user_id) {
	if (!confirm("Σίγουρα;")) return;
	const ban_root=elt.closest("[data-banned]");
	const loading_style=ban_root.querySelector(".loading").style;
	const new_value=!(ban_root.getAttribute("data-banned")|0);
	loading_style.display="";
	tx_util.api_request_promise("set_user_banned",user_id,new_value).then(() => {
		ban_root.setAttribute("data-banned",new_value|0);
	},err => {
		toast("Απέτυχε\n"+JSON.stringify(err),Infinity);
	}).then(() => {
		loading_style.display="none";
	});
}

export function remove_proposed_entry_closure(elt,eid,etype,user_id) {
	if (!confirm("Σίγουρα;")) return;
	const entry_div=elt.closest(".entry-closure");
	tx_util.api_request_promise("remove_proposed_entry_closure",eid,etype,user_id).then(() => {
		$(entry_div).slideUp(() => {entry_div.remove()});
	},err => {
		toast("Απέτυχε\n"+JSON.stringify(err));
	});
}

export function accept_entry_edit(id,elt,dont_hide) {
	const li=elt && elt.closest(dont_hide ? ".buttons" : "li");
	tx_util.api_request_promise("accept_entry_edit",id).then(() => {
		if (li) $(li).slideUp(() => {li.remove()});
	},err => {
		toast("Απέτυχε\n"+JSON.stringify(err));
	});
}

function infoboxo_click() {
	tx_map.unshow_on_map();
	restore();
}

function infoboxt_drag_start(ev) {
	if (ev.type==="mouse" && ev.event.which!==1) return false;
	ev.event.preventDefault(); // for the ctrl case
	ev.data.r=infobox_compact_height_ratio;
}

function infoboxt_drag_start_ctrl(ev) {
	if (minimized || !ev.event.ctrlKey) return false;
	return infoboxt_drag_start(ev);
}

function infoboxt_drag_move(ev) {
	infobox_pos.left+=ev.dx;
	infobox_pos.top+=ev.dy;
	$infobox.css(infobox_pos);
	infobox_compact_height_ratio=ev.data.r-ev.cdy/mainpane.offsetHeight;
	update_compact_height();
	infobox_resized();
	tx_map.resized();
}

function infobox_resize_start(ev) {
	if ((ev.type==="mouse" && ev.event.which!==1) || ev.event.ctrlKey) return false;
	ev.data.w=infobox.offsetWidth;
	ev.data.h=infobox.offsetHeight;
}

function infobox_resize_move(ev) {
	infobox.style.width=Math.max(400,ev.data.w+ev.cdx)+"px";
	infobox.style.height=Math.max(200,ev.data.h+ev.cdy)+"px";
	infobox_resized();
}

function infobox_resized() {
	var tabs_div=infoboxc.querySelector(".tabs_live");
	if (tabs_div && tabs_div._resized) tabs_div._resized();
}

function update_compact_height() {
	infobox_flex_basis=Math.round(infobox_compact_height_ratio*mainpane.offsetHeight);
	infobox.style[tx_util.flexBasis]=infobox_flex_basis+"px";
}

function window_resized() {
	update_compact_height();
	infobox_resized();
}

export function init() {
	mainpane=document.getElementById("mainpane");
	infobox=document.getElementById("infobox");
	$infobox=$(infobox);
	infoboxh=document.getElementById("infoboxh");
	var infoboxt=document.getElementById("infoboxt");
	title_span=infoboxt.querySelector(".title");
	infoboxc=document.getElementById("infoboxc");
	$infoboxo=$(document.getElementById("infoboxo")).on("click touchstart",infoboxo_click);
	share_btn=document.querySelector("#infoboxs .share-permalink-btn");
	report_error=document.getElementById("report_error");
	var $infoboxse=$("#infoboxse");

	infobox.style.width=Math.min(650,mainpane.offsetWidth-120)+"px";
	infobox.style.height=Math.min(650,mainpane.offsetHeight-120)+"px";
	infobox_pos={
		left:(mainpane.offsetWidth-$infobox.outerWidth()) >> 1,
		top:(mainpane.offsetHeight-$infobox.outerHeight()) >> 1
	};
	$infobox.css(infobox_pos);
	tx_util.make_draggable(infoboxt,{cursor:"move",passive:false},infoboxt_drag_start,infoboxt_drag_move);
	tx_util.make_draggable(infobox,{cursor:"move",passive:false},infoboxt_drag_start_ctrl,infoboxt_drag_move);
	tx_util.make_draggable($infoboxse[0],{cursor:"se-resize"},infobox_resize_start,infobox_resize_move);
	addEventListener("resize",window_resized);
	window_resized();
	tx_map.resized();
}

export function browse_prompt() {
	var x=prompt("go to page:","");
	if (x!=null) browse(x);
}
