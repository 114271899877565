import * as tx_util from "./util";
import * as tx_infobox from "./infobox";
import * as tx_input_validation from "./input_validation";
import {toast} from "./toast";
import {mke} from "./mke";

let close_active_widget;

const fields={
	keywords:{
		method:"suggest_additional_keywords",
		validate:"keywords",
		header:"Πρότεινε κάποιες λέξεις κλειδιά:",
		footer:["Πχ:","#κατάλληλο για παιδιά,#κήπος","#σούβλες","#brunch,#cocktails"],
	},
	www:{
		method:"suggest_additional_www",
		validate:"mediumstr",
		post_process:str => [/^https?:/.test(str) ? str : `http://${str}`],
		header:"Πρόσθεσε site/fb/instagram:",
	},
	phone:{
		method:"suggest_edit_phone",
		validate:"smallstr",
		header:"Πρόσθεσε τηλέφωνα:",
		footer:["Προσοχή, μόνο επίσημα τηλέφωνα του καταστήματος!"],
	},
	hours:{
		method:"suggest_edit_hours",
		validate:"smallstr",
		header:"Πρόσθεσε τις ώρες λειτουργίας:",
		footer:["Πχ: 13:30-01:00, Δευτέρα κλειστά."],
	},
	date_closed: {
		method:"suggest_edit_date_closed",
		validate:"smallstr?",
		header:"Ημερομηνία κλεισίματος:",
		footer:["Πχ:","Νοέμβριο ή Δεκέμβριο 2016","καλοκαίρι 2015","τέλη δεκαετίας 80"],
	},
	address:{
		method:"suggest_edit_address",
		validate:"smallstr?",
	},
	company_name:{
		method:"suggest_edit_company_name",
		validate:"mediumstr?",
	},
	afm:{
		method:"suggest_edit_afm",
		validate:"afm?",
	},
};

export function suggest_edit(placeholder_elt,eid,etype,field_name,old_value) {
	let {method,validate,post_process,header,footer}=fields[field_name];
	post_process=post_process||(x => x);
	let open=false;
	const toggle=() => {
		open=!open;
		if (!open)
			close_active_widget=undefined;
		else {
			if (close_active_widget)
				close_active_widget();
			close_active_widget=hide;
			const {left:x0}=elt.closest(".tabs_page_content").getBoundingClientRect();
			const {left:x1}=elt.getBoundingClientRect();
			elt.classList.toggle("left",x1-x0>=260);
			input.select();
		}
		elt.classList.toggle("open",open);
		if (open)
			input.focus();
	};
	const hide=() => {
		if (open) toggle();
	};
	const on_submit=(ev) => {
		tx_util.prevent_default(ev);
		const value=post_process(tx_input_validation.get_value(input));
		inputs.forEach(x => x.toggleAttribute("disabled",true));
		tx.api_request_promise(method,eid,etype,value).then(() => {
			hide();
			input.value="";
			tx_infobox.submit_form(null,elt,'merge_entry',{action:'info',eid,etype},null,true);
		},() => {
			toast("Απέτυχε");
			input.select();
		}).finally(() => {
			inputs.forEach(x => x.toggleAttribute("disabled",false));
			input.focus();
		});
	};
	const input=mke("input",{
		size:"30",
		"data-validate":validate,
		onkeydown:(ev) => {if (ev.keyCode===27) hide()},
	});
	const a=mke("a",{onclick:hide},"Άκυρο");
	const submit_btn=mke("input",{type:"submit",value:"Υποβολή"});
	const inputs=[input,a,submit_btn];
	const footer_elts=(footer||[]).map(text => mke("",text));
	const elt=mke(".suggest-edit",
		mke(".btn",{onclick:toggle}),
		mke("form",{onsubmit:on_submit},
			mke("",header||""),
			mke(input),
			mke(".footer",...footer_elts),
			mke(".btns",a,submit_btn),
		),
	);
	tx_input_validation.enable_validation(input);
	if (old_value!==undefined) {
		input.value=old_value;
		elt.classList.add("edit");
	}
	placeholder_elt.after(elt);
}
