import * as tx_map from "./map";
import * as tx_infobox from "./infobox";
import {normalize_url} from "./normalize_url";

class ValidationError {
	constructor(msg) {
		this.m=msg;
	}
}

function mk_str_bound(max_len) {
	return s => {
		if (s.length>max_len)
			throw new ValidationError("Δεν επιτρέπονται περισσότεροι από "+max_len+" χαρακτήρες");
		return s
	};
}

function mk_str_regex(regex) {
	return s => regex.test(s) ? s : undefined;
}

function moved(str,input) {
	const eid=uint(str);
	if (eid!==undefined) {
		const ne=input.getAttribute("data-validate-ne");
		if (ne && eid===parseInt(ne))
			throw new ValidationError("Το κατάστημα δεν μπορεί να μεταφερθεί στον εαυτό του");
		return {eid,etype:0};
	}
}

function mk_list(elt_validator,separator,allow_trailing) {
	return s => {
		if (s==="") return [];
		const elts=s.split(separator);
		let len=elts.length;
		if (allow_trailing && elts[len-1].length===0)
			elts.length=--len;
		for (let i=0; i<len; i++) {
			if (elts[i].length===0)
				throw new ValidationError("Κενό στοιχείο στη λίστα");
			elts[i]=elt_validator(elts[i]);
			if (elts[i]===undefined)
				throw new ValidationError("Μη έγκυρο στοιχείο στη λίστα");
		}
		return elts;
	};
}

function entry_id_1(str) {
	const m=/^(\d{1,10})$|\{(\d{1,10})\}$/.exec(str) || [];
	const eid=m[1]||m[2];
	return eid!=null ? {eid:parseInt(eid),etype:1} : undefined;
}

const url_regex=/^https?:\/\/[^.\/]{1,256}\.[^.\/].{1,4096}$/;

function url(str) {
	if (url_regex.test(str))
		return normalize_url(str);
}

const uint=s => /^\d{1,10}$/.test(s) ? parseInt(s) : undefined;
const bool=s => /^(1|true)$/i.test(s) ? true : /^(0|false)$/i.test(s) ? false : undefined;
const smallstr=mk_str_bound(256);
const mediumstr=mk_str_bound(4096);
const keywords_raw_list=mk_list(smallstr,/\s*,\s*/,false);
const keywords=s => keywords_raw_list(s.replace(/^\s+|\s+$/g,""));

const all_types={
	"":() => null,
	false:() => false,
	uint,
	bool,
	"[]": () => [],
	sms_code:s => /^\d{1,4}$/.test(s) ? parseInt(s) : undefined,
	to_num:s => s|0,
	latlon:s => {const m=/^([+-]?\d+(?:\.\d+)?) ([+-]?\d+(?:\.\d+)?)$/.exec(s); if (m) return {lat:m[1],lon:m[2]}},
	tinystr:mk_str_bound(32),
	smallstr,
	mediumstr,
	largestr:mk_str_bound(65536),
	afm:mk_str_regex(/^\d{9}$/),
	email:mk_str_regex(/^[^@\s]{1,48}@[^@\s]{1,48}\.[^@\s]{1,16}$/),
	url,
	keywords,
	"mediumstr[]":mk_list(mediumstr,"\n",true),
	"url[]":mk_list(url,"\n",true),
	moved,
	entry_id_1,
	street_view:tx_map.parse_street_view_value,
	entry_checks:tx_infobox.parse_entry_checks,
};

const validate_function=Symbol();
const _mode=Symbol();

// returns undefined if and only if validation failed
export function get_value(input) {
	let err="";
	let res;
	const value=
		input.tagName==="INPUT" && (input.type==="checkbox" || input.type==="radio")
			? input.checked
			: input.value.replace(/ +$/,"").replace(/^ +/,"");
	if (!input[validate_function])
		res=value;
	else if (value==="" && input[_mode]!=="~") {
		if (input[_mode]==="?")
			res=null;
		else
			err="Το πεδίο είναι υποχρεωτικό";
	}
	else {
		try {
			res=input[validate_function](value,input);
			if (res===undefined) err="Μη έγκυρη τιμή";
		}
		catch (exn) {
			if (exn instanceof ValidationError)
				err=exn.m;
			else
				throw exn;
		}
	}
	input.setCustomValidity(err);
	return res;
}

export function get_form_values(node,report_validity) {
	const params={};
	for (const elt of node.querySelectorAll("[name]")) {
		if (!elt.disabled) {
			let name=elt.name;
			if (elt.tagName==="INPUT" && elt.type==="radio")
				params[name]=params[name]||(elt.checked ? elt.value : null);
			else {
				const value=get_value(elt);
				if (value===undefined) {
					if (report_validity)
						elt.reportValidity();
					return undefined;
				}
				if (name.slice(-2)!=="[]")
					params[name]=value
				else {
					name=name.slice(0,-2);
					(params[name]||(params[name]=[])).push(value);
				}
			}
		}
	}
	return params;
}

function on_input() {
	get_value(this);
}

export function enable_validation(input) {
	let type=input.getAttribute("data-validate");
	if (!type) return;
	const mode=type.slice(-1);
	if (mode==="?"||mode==="~") {
		type=type.slice(0,-1);
		input[_mode]=mode;
	}
	const validator=all_types[type];
	if (!validator)
		throw new Error("Unkown validator type: "+type);
	input[validate_function]=validator;
	input.addEventListener(input.tagName==="INPUT" && input.type==="checkbox" ? "change" : "input",on_input);
	get_value(input);
}

export function enable_validation_recursive(elt) {
	for (const input of elt.querySelectorAll("[data-validate]"))
		enable_validation(input);
}

export function report_error(err,ancestor,selector) {
	const input=selector ? ancestor.querySelector(selector) : ancestor;
	input.setCustomValidity(err);
	input.reportValidity();
}
