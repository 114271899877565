import * as tx_util from "./util";
import * as tx_infobox from "./infobox";
import * as tx_input_validation from "./input_validation";
import {toast} from "./toast";
import {mke} from "./mke";

const busy=Symbol();

function remove_etable_store(elem) {
	const store_div=elem.closest(".etable-store");
	$(store_div).slideUp(() => {
		$(store_div).remove();
	})
}

export function set_etable_id(elem,eid,etype,etable_id) {
	if (elem[busy]) return;
	elem[busy]=true;
	const loading_div=mke(".loading");
	elem.closest("li").appendChild(loading_div);
	tx_util.api_request_promise("set_entry_etable",eid,etype,etable_id).then(() => {
		$(loading_div).remove();
		remove_etable_store(elem);
	},() => {
		$(loading_div).remove();
		toast("Απέτυχε");
	}).then(() => {
		elem[busy]=false;
	});
}

export function set_etable_ignored(elem,etable_id,value) {
	if (value && !confirm("Είσαι σίγουρος;")) return;
	if (elem[busy]) return;
	elem[busy]=true;
	const loading_div=mke(".loading");
	elem.closest(".set-ignored").appendChild(loading_div);
	tx_util.api_request_promise("set_etable_ignored",etable_id,value).then(() => {
		$(loading_div).remove();
		remove_etable_store(elem);
	},() => {
		$(loading_div).remove();
		toast("Απέτυχε");
	}).then(() => {
		elem[busy]=false;
	});
}

// -----------------------------------------------------------------------------

const form_normal=Symbol();
const form_working=Symbol();
const form_disabled=Symbol();

function set_form_state(form,state) {
	const disabled=state!==form_normal;
	for (const elt of form.querySelectorAll("input,textarea,select,button"))
		elt.disabled=disabled;
	const anchor_visibility=disabled ? "hidden" : "";
	for (const elt of form.querySelectorAll("a.button"))
		elt.style.visibility=anchor_visibility;
	const value=state===form_working ? "" : "none";
	for (const elt of form.querySelectorAll(".loading"))
		elt.style.display=value;
}

export function etable_widget(eid,etype,button) {
	const widget_div=button.closest(".etable-reservation").querySelector(".widget");
	const offer_div=button.closest(".offer");
	set_form_state(offer_div,form_working);
	tx_util.api_request_promise('get_etable_schedule',eid,etype).then(schedule => {
		offer_div.style.display="none";
		set_form_state(offer_div,form_disabled);
		const days={};
		for (const zones of schedule) {
			for (const zone of zones) {
				const date=zone.booking_date;
				const day=days[date]||(days[date]={zones:[]});
				day.min=Math.min(day.min||100,zone.option_title);
				day.max=Math.max(day.max||0,zone.option_title);
				day.zones.push(zone);
			}
		}
		const reservation_params={eid,etype};
		const on_submit_info=(vars,form) => {
			set_form_state(form,form_working);
			const phone=vars.phone;
			if (!/^\s*\+/.test(phone))
				vars.phone="+30 "+vars.phone;
			tx_util.api_request_promise('make_etable_reservation',vars).then(res => {
				set_form_state(form,form_disabled);
				date_picker.style.display="none";
				time_picker.style.display="none";
				user_info.style.display="none";
				const on_cancel=() => {
					confirm_div.remove();
					date_picker.style.display="";
					time_picker.style.display="";
					user_info.style.display="";
					user_info.scrollIntoView({behavior:"smooth"});
				};
				const sms_code_input=mke("input",{name:"sms_code","data-validate":"sms_code",size:4,maxlength:4});
				const submit_btn=mke("input",{type:"submit",value:"Επιβεβέωση κράτησης"});
				const on_submit_confirm=ev => {
					tx_util.prevent_default(ev);
					set_form_state(confirm_div,form_working);
					tx_util.api_request_promise("confirm_etable_reservation",res.id,sms_code_input.value).then(res => {
						set_form_state(confirm_div,form_disabled);
						widget_div.innerHTML="";
						widget_div.append(
							mke("","Η κράτηση ολοκληρώθηκε επιτυχώς"), //////////////////////
							mke("","Κωδικός κράτησης: ",mke("b",res.code)), ///////////////////////////
						);
					},err => {
						if (err.code===45) {
							set_form_state(confirm_div,form_normal);
							sms_code_input.setCustomValidity("Λανθασμένος 4ψήφιος κωδικός")
							sms_code_input.reportValidity();
						}
						else {
							set_form_state(confirm_div,form_disabled);
							if (err.code===46)
								toast("Η επιβεβαίωση του 4ψήφιου κωδικού απέτυχε πολλές φορές. Πρέπει να ξανακάνετε την κράτηση από την αρχή",10000);
							else
								toast("Η κράτηση απέτυχε. Δοκιμάστε να ξανακάνετε την κράτηση από την αρχή",10000);
						}
					});
				};
				const confirm_div=mke("form",{onsubmit:on_submit_confirm},
					mke("","Επιβεβεώστε τον 4ψήφιο κωδικό που λάβατε μέσω SMS:",sms_code_input),
					mke("","Στοιχεία κράτησης:"),
					mke("","Ονοματεπώνυμο: ",mke("b",vars.name)),
					mke("","email: ",mke("b",vars.email)),
					mke("","Τηλέφωνο: ",mke("b",phone)),
					mke("","Ωρα: ",mke("b",vars.date)," στις ",mke("b",vars.time)),
					mke(
						submit_btn,
						mke(".loading",{style:{display:"none"}}),
						mke("a.button",{onclick:on_cancel},"αλλαγή στοιχείων"),
					),
				);
				tx_input_validation.enable_validation_recursive(confirm_div);
				widget_div.appendChild(confirm_div);
				confirm_div.scrollIntoView({behavior:"smooth"});
			},err => {
				set_form_state(form,form_normal);
				let msg="Το αίτημα απέτυχε.";
				if (err.code===43) msg+=" "+err.data;
				toast(msg);
			});
		};
		const time_picker=mke(".time");
		const user_info=mke({style:{display:"none"}},
			mke("form.info",{onsubmit:function(ev) {tx_infobox.submit_form(ev,this,null,reservation_params,on_submit_info)}},
				mke(mke("input",   {name:"name", "data-validate":"smallstr",  placeholder:"Ονοματεπώνυμο κράτησης"})),
				mke(mke("input",   {name:"email","data-validate":"email",     placeholder:"email",type:"email"})),
				mke(mke("input",   {name:"phone","data-validate":"tinystr",   placeholder:"Τηλέφωνο (κινητό)",type:"tel"})),
				mke(mke("textarea",{name:"notes","data-validate":"mediumstr?",placeholder:"Παρατηρήσεις (προαιρετικό)"})),
				mke(mke("input",{type:"submit",value:"Υποβολή"}),mke(".loading",{style:{display:"none"}})),
			)
		);
		tx_input_validation.enable_validation_recursive(user_info);
		const select_date=mke("select");
		const select_persons=mke("select");
		const on_input=() => {
			time_picker.innerHTML="";
			user_info.style.display="none";
			const date=select_date.value;
			const persons=select_persons.value|0;
			reservation_params.date=date;
			reservation_params.persons=persons;
			const day=days[date];
			for (const zone of day.zones) {
				if (zone.booking_date===date) {
					for (const hour of zone.hours) {
						if (zone.persons>=persons) {
							let text=hour;
							if (zone.option_title>0) text+=" "+zone.option_title+"%";
							time_picker.appendChild(mke("button",{onclick:function() {
								for (const btn of time_picker.querySelectorAll("button.selected"))
									btn.classList.remove("selected");
								this.classList.add("selected");
								user_info.style.display="";
								user_info.scrollIntoView({behavior:"smooth"});
								reservation_params.time=hour;
								reservation_params.option_id=zone.option_id;
							}},text));
						}
					}
				}
			}
			time_picker.scrollIntoView({behavior:"smooth"});
		};
		select_date.addEventListener("input",on_input);
		select_persons.addEventListener("input",on_input);
		for (const date of Object.keys(days).sort()) {
			let text=date;
			if (days[date].max>0) text+=" "+(days[date].min<days[date].max ? "μέχρι " : "")+days[date].max+"%";
			select_date.appendChild(mke("option",{value:date},text));
		}
		for (let n=1; n<=12; n++) {
			const option=mke("option",{value:n},n+" άτομ"+(n===1 ? "o" : "α"));
			select_persons.appendChild(option);
			if (n===2) option.selected=true;
		}
		const date_picker=mke(select_date,select_persons);
		widget_div.appendChild(date_picker);
		widget_div.appendChild(time_picker);
		widget_div.appendChild(user_info);
		select_date.dispatchEvent(new Event("input"));
	},err => {
		if (err.code!==42)
			toast("Το αίτημα απέτυχε.");
		else {
			set_form_state(offer_div,form_normal);
			toast("Το αίτημα απέτυχε. Προσπαθήστε ξανά σε λίγο.");
		}
	});
}
