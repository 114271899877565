import * as tx_util from "./util";
import * as tx_history from "./history";
import {mke} from "./mke";

const editor_div=document.querySelector("#image-editor-overlay");

const editor_html_template=
	'<div class="image-pane">'+
		'<div class="image-container">'+
			'<div class="resize-container draw" data-state="hidden">'+
				'<div data-dir="nw"></div>'+
				'<div data-dir="n-"></div>'+
				'<div data-dir="ne"></div>'+
				'<div data-dir="-e"></div>'+
				'<div data-dir="se"></div>'+
				'<div data-dir="s-"></div>'+
				'<div data-dir="sw"></div>'+
				'<div data-dir="-w"></div>'+
			'</div>'+
			'<div class="resize-container crop" data-state="hidden">'+
				'<div data-dir="nw"></div>'+
				'<div data-dir="n-"></div>'+
				'<div data-dir="ne"></div>'+
				'<div data-dir="-e"></div>'+
				'<div data-dir="se"></div>'+
				'<div data-dir="s-"></div>'+
				'<div data-dir="sw"></div>'+
				'<div data-dir="-w"></div>'+
			'</div>'+
			'<canvas class="canvas" width="1" height="1"></canvas>'+
		'</div>'+
	'</div>'+
	'<div class="toolbar">'+
		'<button class="auto-hide btn-submit" data-on-mode="normal" title="Αποθήκευση αλλαγών"><div>Αποθήκευση</div></button>'+
		'<button class="auto-hide btn-cancel" data-on-mode="normal" title="Ακύρωση αλλαγών"><div>Ακύρωση</div></button>'+
		'<button class="auto-hide btn-rot-90-ccw" data-on-mode="normal" title="Περιστροφή κατά 90 μοίρες αντίθετα από τη φορά του ρολογιού"><div>Περιστροφή</div></button>'+
		'<button class="auto-hide btn-rot-90-cw" data-on-mode="normal" title="Περιστροφή κατά 90 μοίρες στη φορά του ρολογιού"><div>Περιστροφή</div></button>'+
		'<button class="auto-hide btn-hflip" data-on-mode="normal" title="Καθρεπτισμός"><div>Καθρεπτισμός</div></button>'+
		'<button class="auto-hide btn-draw-rect" data-on-mode="normal" title="Σχεδίαση μαύρου πλαισίου για απόκρυψη προσώπων"><div><div>Απόκρυψη</div><div>προσώπων</div></div></button>'+
		'<button class="auto-hide btn-crop" data-on-mode="normal" title="Περικοπή"><div>Περικοπή</div></button>'+
		'<button class="auto-hide btn-undo" data-on-mode="normal" title="Αναίρεση τελευταίας ενέργειας"><div>Αναίρεση</div></button>'+
		'<button class="auto-hide btn-draw-rect-ok" data-on-mode="rect" title="Εντάξει"><div>Εντάξει</div></button>'+
		'<button class="auto-hide btn-draw-rect-cancel" data-on-mode="rect" title="Ακύρωση ενέργειας"><div>Ακύρωση</div></button>'+
		'<button class="auto-hide btn-crop-ok" data-on-mode="crop" title="Εντάξει"><div>Εντάξει</div></button>'+
		'<button class="auto-hide btn-crop-cancel" data-on-mode="crop" title="Ακύρωση ενέργειας"><div>Ακύρωση</div></button>'+
	'</div>'+
	'';

const _operations = Symbol();

export class ImageOperations {
	constructor() {
		this[_operations] = [];
	}

	clone() {
		const operations = new this.constructor();
		operations[_operations] = this[_operations].slice();
		return operations;
	}

	copyFrom(operations) {
		this[_operations] = operations[_operations].slice();
	}

	toJSON() {
		return this[_operations].map(op => op.toJSON());
	}

	append(...operations) {
		this[_operations].push(...operations);
	}

	canUndo() {
		const ops = this[_operations];
		return ops.length>0 && !ops[ops.length-1].isAuto;
	}

	undo() {
		if (this.canUndo())
			this[_operations].pop();
	}

	appendFromJSON(array) {
		for (const op_desc of array) {
			const op_type = op_desc.shift();
			switch (op_type) {
				case "end_auto":
					this.append(new EndAutoOperations());
					break;
				case "rotate":
					this.append(new ImageRotateOperation(op_desc[0]));
					break;
				case "hflip":
					this.append(new HorizontalFlipOperation());
					break;
				case "vflip":
					this.append(new VerticalFlipOperation());
					break;
				case "black_rect":
					this.append(new DrawRectangleOperation({x:op_desc[0],y:op_desc[1],w:op_desc[2],h:op_desc[3]}));
					break;
				case "crop":
					this.append(new ImageCropOperation({x:op_desc[0],y:op_desc[1],w:op_desc[2],h:op_desc[3]}));
					break;
				default:
					throw new Error("Unknown operation type: "+op_type);
			}
		}
	}

	appendFromExif(orientation_value) {
		switch (orientation_value) {
			case 2:
				this.append(new HorizontalFlipOperation());
				break;
			case 3:
				this.append(new ImageRotateOperation(180));
				break;
			case 4:
				this.append(new VerticalFlipOperation());
				break;
			case 5:
				this.append(new VerticalFlipOperation(), new ImageRotateOperation(90));
				break;
			case 6:
				this.append(new ImageRotateOperation(90));
				break;
			case 7:
				this.append(new HorizontalFlipOperation(), new ImageRotateOperation(90));
				break;
			case 8:
				this.append(new ImageRotateOperation(270));
				break;
		}
		if (this[_operations].length > 0)
			this.append(new EndAutoOperations());
	}

	// maxWidth and maxHeight are optional
	renderBounded(img, maxWidth, maxHeight) {
		let scalingFactor = 1;
		if (maxWidth !== undefined || maxHeight !== undefined) {
			let width = img.width, height = img.height;
			for (const operation of this[_operations])
				[width, height] = operation.transformDimensions(width, height);
			if (maxWidth !== undefined)
				scalingFactor = Math.min(scalingFactor, maxWidth / width);
			if (maxHeight !== undefined)
				scalingFactor = Math.min(scalingFactor, maxHeight / height);
		}

		let canvas = mke("canvas");
		canvas.width = Math.round(img.width * scalingFactor);
		canvas.height = Math.round(img.height * scalingFactor);
		const context = canvas.getContext("2d");
		context.imageSmoothingQuality = "high";
		context.imageSmoothingEnabled = true;
		const orig_fillStyle = context.fillStyle;
		context.fillStyle = "white";
		context.fillRect(0, 0, canvas.width, canvas.height);
		context.fillStyle = orig_fillStyle;
		context.drawImage(img, 0, 0, canvas.width, canvas.height);
		for (const operation of this[_operations])
			canvas = operation.render(canvas, scalingFactor);
		return {canvas, scalingFactor};
	}

	render(img) {
		return this.renderBounded(img).canvas;
	}
}

// Operations
class ImageOperation {
	get isAuto() {
		return false;
	}

	toJSON() {
	}

	transformDimensions(w, h) {
		return [w, h];
	}

	// scaling with scalingFactor has already been applied
	// the supplied canvas, may or may not be modified
	// it returns a canvas, which may be the supplied canvas
	render(canvas, scalingFactor) {
	}
}

class EndAutoOperations extends ImageOperation {
	get isAuto() {
		return true;
	}

	toJSON() {
		return ["end_auto"];
	}

	render(canvas, scalingFactor) {
		return canvas;
	}
}

class ImageRotateOperation extends ImageOperation {
	constructor(angle) {
		super();
		this.angle = angle - Math.floor(angle / 360) * 360;
	}

	toJSON() {
		return ["rotate", this.angle];
	}

	transformDimensions(w, h) {
		return this.angle === 90 || this.angle === 270 ? [h, w] : [w, h];
	}

	render(canvas, scalingFactor) {
		const canvasOut = mke("canvas");
		[canvasOut.width, canvasOut.height] = this.transformDimensions(canvas.width, canvas.height);
		const contextOut = canvasOut.getContext("2d");

		if (this.angle === 90)
			contextOut.transform(0, 1, -1, 0, canvas.height, 0);
		else if (this.angle === 270)
			contextOut.transform(0, -1, 1, 0, 0, canvas.width);
		else if (this.angle === 180)
			contextOut.transform(-1, 0, 0, -1, canvas.width, canvas.height);

		contextOut.drawImage(canvas, 0, 0);
		contextOut.setTransform(1, 0, 0, 1, 0, 0); // ie does not support resetTransform

		return canvasOut;
	}
}

class HorizontalFlipOperation extends ImageOperation {
	toJSON() {
		return ["hflip"];
	}

	render(canvas, scalingFactor) {
		const canvasOut = mke("canvas");
		canvasOut.width = canvas.width;
		canvasOut.height = canvas.height;
		const contextOut = canvasOut.getContext("2d");
		contextOut.scale(-1, 1);
		contextOut.drawImage(canvas, -canvas.width, 0);
		contextOut.setTransform(1, 0, 0, 1, 0, 0); // ie does not support resetTransform
		return canvasOut;
	}
}

class VerticalFlipOperation extends ImageOperation {
	toJSON() {
		return ["vflip"];
	}

	render(canvas, scalingFactor) {
		const canvasOut = mke("canvas");
		canvasOut.width = canvas.width;
		canvasOut.height = canvas.height;
		const contextOut = canvasOut.getContext("2d");
		contextOut.scale(1, -1);
		contextOut.drawImage(canvas, 0, -canvas.height);
		contextOut.setTransform(1, 0, 0, 1, 0, 0); // ie does not support resetTransform
		return canvasOut;
	}
}

class DrawRectangleOperation extends ImageOperation {
	constructor(rectangle) {
		super();
		this.rectangle = rectangle;
	}

	toJSON() {
		return [
			"black_rect",
			this.rectangle.x,
			this.rectangle.y,
			this.rectangle.w,
			this.rectangle.h
		];
	}

	render(canvas, scalingFactor) {
		const {x, y, w, h} = this.rectangle;
		canvas.getContext("2d").fillRect(x * scalingFactor, y * scalingFactor, w * scalingFactor, h * scalingFactor);
		return canvas;
	}
}

class ImageCropOperation extends ImageOperation {
	constructor(rectangle) {
		super();
		this.rectangle = rectangle;
	}

	toJSON() {
		return [
			"crop",
			this.rectangle.x,
			this.rectangle.y,
			this.rectangle.w,
			this.rectangle.h
		];
	}

	transformDimensions(w_, h_) {
		const {w, h} = this.rectangle;
		return [w, h];
	}

	render(canvas, scalingFactor) {
		let {x, y, w, h} = this.rectangle;

		let canvasOut = mke("canvas");
		w = Math.round(w * scalingFactor);
		h = Math.round(h * scalingFactor);
		canvasOut.width = w;
		canvasOut.height = h;

		canvasOut.getContext("2d").drawImage(canvas, Math.round(x * scalingFactor), Math.round(y * scalingFactor), w, h, 0, 0, w, h);

		return canvasOut;
	}
}

// Helpers
class RectangleDimensionsManager {
	constructor($offset) {
		this.$offset = $offset;
		this.clean();
	}

	clean() {
		this.rectangle = {};
		this.startPoint = {x: 0, y: 0};
		this.endPoint = {x: 0, y: 0};
		this.moveOffset = {};
	}

	rescale(adjustment) {
		for (const object of [this.rectangle, this.startPoint, this.endPoint, this.moveOffset]) {
			for (const name in object)
				object[name] *= adjustment;
		}
	}

	getCursor(event) {
		const offset = this.$offset.offset();
		return {
			x: event.x - offset.left + $(window).scrollLeft(),
			y: event.y - offset.top + $(window).scrollTop()
		};
	}

	fitEndPointToBounds(bounds) {
		this.endPoint.x = Math.clamp(this.endPoint.x, 0, bounds.width);
		this.endPoint.y = Math.clamp(this.endPoint.y, 0, bounds.height);
	}

	computeHarmonizedRectangle() {
		const {x, y} = this.startPoint;
		if (x !== undefined) {
			this.rectangle.x = Math.min(x, this.endPoint.x);
			this.rectangle.w = Math.abs(x - this.endPoint.x);
		}
		if (y !== undefined) {
			this.rectangle.y = Math.min(y, this.endPoint.y);
			this.rectangle.h = Math.abs(y - this.endPoint.y);
		}
	}

	startResize(e) {
		const [v, h] = e.event.target.getAttribute("data-dir");
		let x,y;
		if (h === "w")
			x = this.rectangle.x + this.rectangle.w;
		else if (h === "e")
			x = this.rectangle.x;
		if (v === "n")
			y = this.rectangle.y + this.rectangle.h;
		else if (v === "s")
			y = this.rectangle.y;
		this.startPoint = {x, y};
	}

	resizing(event, bounds) {
		this.endPoint = this.getCursor(event);
		this.fitEndPointToBounds(bounds);
		this.computeHarmonizedRectangle();
	}

	startMove(event) {
		const cursorPosition = this.getCursor(event);
		this.moveOffset = {
			x: cursorPosition.x - this.rectangle.x,
			y: cursorPosition.y - this.rectangle.y
		};
	}

	moving(event, bounds) {
		const cursorPosition = this.getCursor(event);
		this.rectangle.x = Math.clamp(cursorPosition.x - this.moveOffset.x, 0, bounds.width - this.rectangle.w);
		this.rectangle.y = Math.clamp(cursorPosition.y - this.moveOffset.y, 0, bounds.height - this.rectangle.h);

		//this.moveOffset.x = Math.clamp(cursorPosition.x - this.rectangle.x, 0, this.rectangle.w);
		//this.moveOffset.y = Math.clamp(cursorPosition.y - this.rectangle.y, 0, this.rectangle.h);
	}

	fullScaleRectangle(scalingFactor) {
		const rectangle = this.rectangle;
		return {
			x: Math.round(rectangle.x / scalingFactor),
			y: Math.round(rectangle.y / scalingFactor),
			w: Math.round(rectangle.w / scalingFactor),
			h: Math.round(rectangle.h / scalingFactor),
		};
	}
}

class DrawManager extends RectangleDimensionsManager {
	startPainting(event) {
		this.startPoint = this.getCursor(event)
	}

	painting(event, bounds) {
		this.endPoint = this.getCursor(event);
		this.fitEndPointToBounds(bounds);
		this.computeHarmonizedRectangle();
	}
}

class CropManager extends RectangleDimensionsManager {
	init(canvas) {
		this.rectangle = {
			x: 0,
			y: 0,
			w: canvas.width,
			h: canvas.height,
		};
	}

}

export function edit_image(img, operations) {
	tx_history.push_history();
	editor_div.classList.add("active");

	editor_div.innerHTML = editor_html_template;
	const canvas = editor_div.querySelector(".canvas");
	const context = canvas.getContext("2d");

	let drawRectEnabled = false;

	let resizeContainerDraw = $(".resize-container.draw", editor_div)[0];
	let resizeContainerCrop = $(".resize-container.crop", editor_div)[0];
	const inputs = $("button:not(.btn-undo), input", editor_div);
	const buttonsAutoHide = editor_div.querySelectorAll(".auto-hide[data-on-mode]");
	const btnUndo = editor_div.querySelector(".btn-undo");

	setEditorMode();

	let scalingFactor = 1;

	const drawManager = new DrawManager($(canvas));
	const cropManager = new CropManager($(canvas));

	tx_util.make_draggable(resizeContainerCrop, {capture: false, cursor: "move"}, startMoveForCrop, movingForCrop);
	for (const handle of resizeContainerCrop.children)
		tx_util.make_draggable(handle, {cursor: getComputedStyle(handle).cursor}, startResizeForCrop, resizingForCrop);

	tx_util.make_draggable(canvas, {cursor: "crosshair"}, startPainting, painting, stopPainting);
	tx_util.make_draggable(resizeContainerDraw, {capture: false, cursor: "move"}, startMove, moving, endMove);
	for (const handle of resizeContainerDraw.children)
		tx_util.make_draggable(handle, {cursor: getComputedStyle(handle).cursor}, startResize, resizing, endResize);

	const workOps = operations.clone();
	checkUndo();
	disableAllEffects(true);
	draw();
	disableAllEffects(false);

	function disableAllEffects(disabled) {
		inputs.prop('disabled', disabled);
	}

	function draw() {
		const res = workOps.renderBounded(img, $(".image-pane", editor_div).width(), $(".image-pane", editor_div).height());
		scalingFactor = res.scalingFactor;
		const tempCanvas = res.canvas;
		canvas.width = tempCanvas.width;
		canvas.height = tempCanvas.height;
		context.clearRect(0, 0, canvas.width, canvas.height);
		context.drawImage(tempCanvas, 0, 0);
	}

	function checkUndo(forceDisabled) {
		btnUndo.disabled = forceDisabled || !workOps.canUndo();
	}

	function resetEffects(keepCurrentRectangle) {
		if (keepCurrentRectangle && !$.isEmptyObject(drawManager.rectangle)) {
			workOps.append(new DrawRectangleOperation(drawManager.fullScaleRectangle(scalingFactor)));
			checkUndo();
		}

		drawManager.clean();
		cropManager.clean();
		hideContainer(resizeContainerCrop);
		hideContainer(resizeContainerDraw);
	}

	function hideContainer(container) {
		container.setAttribute("data-state", "hidden");
	}

	function fitRectangleToContainer(rectangle, container) {
		container.style.top = rectangle.y + "px";
		container.style.left = rectangle.x + "px";
		container.style.width = rectangle.w + "px";
		container.style.height = rectangle.h + "px";
	}

	function fitRectangleBorderToContainer(rectangle, container) {
		const {x, y} = rectangle;
		container.style.borderWidth = `${y}px ${canvas.width - x - rectangle.w}px ${canvas.height - y - rectangle.h}px ${x}px`;
	}

	function setEditorMode(mode = "normal") {
		for (const btn of buttonsAutoHide)
			btn.classList.toggle("hidden", btn.getAttribute("data-on-mode") !== mode);
	}

	$(".btn-rot-90-cw", editor_div).click(function () {
		resetEffects(true);
		workOps.append(new ImageRotateOperation(90));
		checkUndo();
		draw();
	});

	$(".btn-rot-90-ccw", editor_div).click(function () {
		resetEffects(true);
		workOps.append(new ImageRotateOperation(-90));
		checkUndo();
		draw()
	});

	$(".btn-hflip", editor_div).click(function () {
		resetEffects(true);
		workOps.append(new HorizontalFlipOperation());
		checkUndo();
		draw()
	});

	$(".btn-draw-rect", editor_div).click(function () {
		resetEffects(true);
		setEditorMode("rect");
		enableDrawRect();
	});

	function enableDrawRect() {
		canvas.style.cursor = "crosshair";
		drawRectEnabled = true;
	}

	function disableDrawRect() {
		canvas.style.cursor = "auto";
		drawRectEnabled = false;
	}

	function startPainting(e) {
		if (!drawRectEnabled) return false;
		resetEffects(true);
		drawManager.startPainting(e);
	}

	function painting(e) {
		drawManager.painting(e, {width: canvas.width, height: canvas.height});
		fitRectangleToContainer(drawManager.rectangle, resizeContainerDraw);
		resizeContainerDraw.setAttribute("data-state", "drawing");
	}

	function stopPainting(e) {
		resizeContainerDraw.setAttribute("data-state", "shown");
		disableDrawRect();
	}

	function startResize(e) {
		// needed for touch events:
		e.event.stopPropagation();
		resizeContainerDraw.setAttribute("data-state", "moving");
		drawManager.startResize(e);
	}

	function resizing(e) {
		drawManager.resizing(e, {width: canvas.width, height: canvas.height});
		fitRectangleToContainer(drawManager.rectangle, resizeContainerDraw);
	}

	function endResize(e) {
		resizeContainerDraw.setAttribute("data-state", "shown");
	}

	function startMove(e) {
		drawManager.startMove(e);
		resizeContainerDraw.setAttribute("data-state", "moving");
	}

	function moving(e) {
		drawManager.moving(e, {width: canvas.width, height: canvas.height});
		fitRectangleToContainer(drawManager.rectangle, resizeContainerDraw);
	}

	function endMove(e) {
		resizeContainerDraw.setAttribute("data-state", "shown");
	}

	$(".btn-draw-rect-ok", editor_div).click(function () {
		disableDrawRect();
		setEditorMode();
		resetEffects(true);
		draw();
	});

	$(".btn-draw-rect-cancel", editor_div).click(function () {
		disableDrawRect();
		setEditorMode();
		resetEffects(false);
		draw();
	});

	$(".btn-crop", editor_div).click(function () {
		resetEffects(true);
		cropManager.init(canvas);
		fitRectangleBorderToContainer(cropManager.rectangle, resizeContainerCrop);
		resizeContainerCrop.setAttribute("data-state", "shown");
		setEditorMode("crop");
	});

	function startMoveForCrop(e) {
		cropManager.startMove(e);
	}

	function movingForCrop(e) {
		cropManager.moving(e, {width: canvas.width, height: canvas.height});
		fitRectangleBorderToContainer(cropManager.rectangle, resizeContainerCrop);
	}

	function startResizeForCrop(e) {
		// needed for touch events:
		e.event.stopPropagation();
		cropManager.startResize(e);
	}

	function resizingForCrop(e) {
		cropManager.resizing(e, {width: canvas.width, height: canvas.height});
		fitRectangleBorderToContainer(cropManager.rectangle, resizeContainerCrop);
	}

	$(".btn-crop-ok", editor_div).click(function () {
		if (!$.isEmptyObject(cropManager.rectangle)) {
			workOps.append(new ImageCropOperation(cropManager.fullScaleRectangle(scalingFactor)));
			checkUndo();
		}
		draw();
		hideContainer(resizeContainerCrop);
		resetEffects(false);
		setEditorMode();
	});

	$(".btn-crop-cancel", editor_div).click(function () {
		hideContainer(resizeContainerCrop);
		resetEffects(false);
		setEditorMode();
	});

	$(btnUndo).click(function () {
		if (workOps.canUndo()) {
			workOps.undo();
			checkUndo();
		}
		draw();
		resetEffects(false);
	});

	let resize_timeout = 0;
	function resize() {
		clearTimeout(resize_timeout);
		resize_timeout = setTimeout(() => {
			const previousScalingFactor = scalingFactor;
			draw();
			const adjustment = scalingFactor / previousScalingFactor;
			drawManager.rescale(adjustment);
			cropManager.rescale(adjustment);
			fitRectangleToContainer(drawManager.rectangle, resizeContainerDraw);
			fitRectangleBorderToContainer(cropManager.rectangle, resizeContainerCrop);
		}, 20);
	}
	addEventListener("resize", resize);

	return new Promise(resolve => {
		function on_popstate() {
			close(false);
		}
		addEventListener("popstate",on_popstate);

		function close(commit) {
			disableAllEffects(true);
			checkUndo();
			resetEffects(false);
			setEditorMode();

			removeEventListener("resize", resize);
			removeEventListener("popstate", on_popstate);
			editor_div.classList.remove("active");
			editor_div.innerHTML = "";
			
			if (commit)
				operations.copyFrom(workOps);

			resolve(commit);
		}

		$(".btn-submit", editor_div).click(function () {
			close(true);
			tx_history.back();
		});

		$(".btn-cancel", editor_div).click(function () {
			close(false);
			tx_history.back();
		});
	});
}
