// exponential backoff
// stops retring when offline

const _cap=Symbol();
const _base=Symbol();
const _failed_count=Symbol();
const _abort=Symbol();
const _check_not_waiting=Symbol();

export class Retrier {
	constructor(cap=32000,base=1000,aborter) {
		this[_cap]=cap;
		this[_base]=base;
		this[_failed_count]=0;
		//this[_abort]=undefined;
		if (aborter) {
			aborter.notify(() => {
				if (this[_abort])
					this[_abort]();
			});
		}
	}

	success() {
		this[_check_not_waiting]();
		this[_failed_count]=0;
	}

	retry() {
		return new Promise((resolve,reject) => {
			this[_check_not_waiting]();
			// prevent integer overflow:
			const failed_count=Math.min(30,this[_failed_count]++);
			if (navigator.onLine) {
				const wait_time=Math.min(this[_cap],(1<<failed_count)*this[_base])+Math.random()*this[_base];
				const timeout_id=setTimeout(() => {
					this[_abort]=undefined;
					resolve();
				},wait_time|0);
				this[_abort]=() => {
					clearTimeout(timeout_id);
					reject();
				};
			}
			else {
				const on_online=() => {
					const timeout_id=setTimeout(() => {
						this[_abort]=undefined;
						resolve();
					},1000);
					this[_abort]=() => {
						clearTimeout(timeout_id);
						reject();
					};
				};
				addEventListener("online",on_online,{once:true});
				this[_abort]=() => {
					removeEventListener("online",on_online,{once:true});
					reject();
				};
			}
		});
	}

	abort() {
		if (!this[_abort]) throw new Error("Not retrying");
		this[_abort]();
	}

	[_check_not_waiting]() {
		if (this[_abort]) throw new Error("Still waiting for the previous retry");
	}
}
