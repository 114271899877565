import * as tx_util from "./util";
import * as tx_map from "./map";
import * as tx_menu from "./menu";
import * as tx_autocomplete from "./autocomplete";

var search_downloader;
var $menu_search;
var searchresults;
var searchresults_has_content=false;
var search_menu;
const mainpane=document.querySelector("#mainpane");
const searchbox=document.querySelector("#searchbox");
const searchform=document.querySelector("#searchform");

function check_searchbox() {
	searchform.classList.toggle("nonempty",searchbox.value!=="" || searchresults_has_content);
}

export function perform_search(q,search_on_map) {
	return perform_search_ex(q,search_on_map,false);
}

export function perform_search_ex(q,search_on_map,from_url) {
	const p=do_search(q,undefined,search_on_map,from_url);
	if (search_menu)
		search_menu.open(true);
	return p;
}

export function perform_search_next(q,offset) {
	return do_search(q,offset);
}

function do_search(q,offset,search_on_map,from_url) {
	return new Promise((resolve,reject) => {
		if (searchbox.value!==q)
			searchbox.value=q;
		const vars={q};
		if (offset!==undefined) vars.offset=offset;
		if (from_url) vars.from_url=1;
		$menu_search.addClass("busy");
		searchresults_has_content=true;
		check_searchbox();
		search_downloader.get("search",vars,function(div,success) {
			searchresults.innerHTML="";
			searchresults.scrollTop=0;
			searchresults.appendChild(div);
			if (search_on_map) {
				const search_on_map_btn=div.querySelector(".search_on_map");
				if (search_on_map_btn)
					search_on_map_btn.click();
			}
			$menu_search.removeClass("busy").addClass("has-content");
			if (success) resolve(); else reject();
		});
	});
}

export function show_search_on_map(button,q) {
	button.disabled=true;
	var loading=button.parentNode.querySelector(".loading");
	loading.style.visibility="visible";
	tx_map.show_search_results(q).then(function() {
		loading.style.visibility="hidden";
	});
}

export function close_search() {
	search_menu.close();
}

function resize_search_menu() {
	searchresults.style.maxHeight=(mainpane.clientHeight-main_menu.offsetTop-main_menu.offsetHeight-(12+12+1+1+1))+"px";
}

export function init() {
	search_downloader=new tx_util.HtmlDownloader();
	$menu_search=$(document.getElementById("menu_search"));
	searchresults=document.getElementById("searchresults");
	searchform.addEventListener("submit",(ev) => {
		tx_util.prevent_default(ev);
		perform_search_next(searchbox.value);
	});
	$(searchbox).on("input",check_searchbox);
	check_searchbox();
	var autocompleter=new tx_autocomplete.AutocompleterApi(searchbox,500,"fts");
	var searchbox_x=searchform.querySelector(".x");
	searchbox_x.addEventListener("click",function() {
		autocompleter.close();
		searchbox.value="";
		if (search_menu.is_open)
			searchbox.focus();
		searchresults.scrollTop=0;
		searchresults.innerHTML="";
		$menu_search.removeClass("has-content busy");
		searchresults_has_content=false;
		check_searchbox();
		tx_map.clear_search_results();
		search_downloader.abort();
	});
	var main_menu=document.getElementById("main_menu");
	if (!window.ResizeObserver)
		addEventListener("resize",resize_search_menu);
	else
		new ResizeObserver(resize_search_menu).observe(mainpane);

	new tx_menu.Menu($("#menu_mapTypeT")[0],$("#menu_mapType")[0]);
	new tx_menu.Menu($("#menu_userT")[0],$("#menu_user")[0]);
	new tx_menu.Menu($("#menu_recentT")[0],$("#menu_recent")[0]);
	new tx_menu.Menu($("#menu_loipaT")[0],$("#menu_loipa")[0]);
	new tx_menu.Menu($("#menu_categoriesT")[0],$("#menu_categories")[0]);
	new tx_menu.Menu($("#menu_filtersT")[0],$("#menu_filters")[0]);
	search_menu=new tx_menu.Menu($("#menu_searchT")[0],$menu_search[0],{
		right_aligned:true,
		allow_scrolling:true,
		onclose:() => {searchbox.blur()},
		onopen:resize_search_menu,
	});
}
