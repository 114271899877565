import "core-js/es/set";
import "core-js/es/map";
import "core-js/es/symbol";
import "core-js/es/promise";
import "core-js/es/promise/finally";
import "core-js/es/array/from";
import "core-js/es/array/includes";
import "core-js/features/math/clamp";
import "core-js/es/object/assign";
import "core-js/es/object/entries";
import "core-js/es/object/values";
import "core-js/es/string/pad-start";
import "core-js/es/string/starts-with";
import "core-js/es/string/includes";
import "core-js/web/dom-collections";
import "intersection-observer";
import "./polyfill-DOMTokenList";
import "./polyfill-Element-methods";
import "./polyfill-scrollIntoView-opts";
import "./polyfill-HTMLCanvasElement-toBlob";
import "./polyfill-Blob-arrayBuffer";

import * as tx_css3_transform from "./css3_transform";
import * as tx_geolocation from "./geolocation";
import * as tx_util from "./util";
import * as tx_oauth2 from "./oauth2";
import * as tx_accounts from "./accounts";
import * as tx_signup_widget from "./signup_widget";
import * as tx_tabs from "./tabs";
import * as tx_menu from "./menu";
import * as tx_menu_overlay from "./menu_overlay";
import * as tx_gallery from "./gallery";
import * as tx_img_overlay from "./img_overlay";
import * as tx_upload_overlay from "./upload_overlay";
import * as tx_map from "./map";
import * as tx_initial_location from "./initial_location";
import * as tx_data from "./data";
import * as tx_infobox from "./infobox";
import * as tx_autocomplete from "./autocomplete";
import * as tx_main_menu from "./main_menu";
import * as tx_check_street_view from "./check_street_view";
import * as tx_share_btn from "./share_btn";
import * as tx_history from "./history";
import * as tx_counters from "./counters";
import * as tx_affiliates from "./affiliates";

tx_util.init();
tx_oauth2.init();
tx_menu.init();
tx_menu_overlay.init();
tx_img_overlay.init();
tx_upload_overlay.init();
tx_initial_location.init();
if (!tx_history.params.nomap)
	tx_map.init();
tx_data.init();
tx_accounts.init();
tx_signup_widget.init();
tx_infobox.init();
tx_main_menu.init();
tx_check_street_view.init();
tx_share_btn.init();
tx_history.init();
tx_counters.init();

document.addEventListener("dragover",ev => {
	tx_util.prevent_default(ev);
	ev.dataTransfer.dropEffect="none";
});
document.addEventListener("drop",tx_util.prevent_default);

export {
	api_request_promise,
	remember_details_state,
} from "./util";

export {
	gmaps_cb,
} from "./gmaps_loader";

export {
	perform_search,
	perform_search_next,
	show_search_on_map,
	close_search,
} from "./main_menu";

export {
	browse as infobox_browse,
	minimize as infobox_minimize,
	restore as infobox_restore,
	submit_form as infobox_submit_form,
	find_form,
	validate_comment_editor as infobox_validate_comment_editor,
	validate_confirm_email_pw as infobox_validate_confirm_email_pw,
	validate_delete as infobox_validate_delete,
	validate_info_editor as infobox_validate_info_editor,
	validate_logout_other_sessions as infobox_validate_logout_other_sessions,
	validate_password_reset as infobox_validate_password_reset,
	validate_req_pw_reset as infobox_validate_req_pw_reset,
	validate_reset_google as infobox_validate_reset_google,
	validate_set_email as infobox_validate_set_email,
	validate_set_password as infobox_validate_set_password,
	validate_user_comment as infobox_validate_user_comment,
	login_reset_code as infobox_login_reset_code,
	validate_user_notes as infobox_validate_user_notes,
	checkbox_toggle_dependents as infobox_checkbox_toggle_dependents,
	add_google as infobox_add_google,
	delete_invite as infobox_delete_invite,
	set_comment_approval as infobox_set_comment_approval,
	entry_checks_control as infobox_entry_checks_control,
	delete_entry_check as infobox_delete_entry_check,
	accept_entry_check as infobox_accept_entry_check,
	delete_hours_check as infobox_delete_hours_check,
	accept_hours_check as infobox_accept_hours_check,
	categories_editor as infobox_categories_editor,
	check_date_opened,
	check_phone,
	check_afm,
	propose_new_entry,
	update_prefecture as infobox_update_prefecture,
	pick_coordinates_center as infobox_pick_coordinates_center,
	pick_coordinates as infobox_pick_coordinates,
	on_latlon_change as infobox_on_latlon_change,
	show_editor_coordinates as infobox_show_editor_coordinates,
	pick_street_view as infobox_pick_street_view,
	copy_values_from_parent,
	add_entry_check_prompt as infobox_add_entry_check_prompt,
	add_entry_check as infobox_add_entry_check,
	zoom_on_map as infobox_zoom_on_map,
	delete_entry as infobox_delete_entry,
	ban_user as infobox_ban_user,
	remove_proposed_entry_closure,
	accept_entry_edit as infobox_accept_entry_edit,
	browse_prompt as infobox_browse_prompt,
	reload as infobox_reload,
} from "./infobox";

export {
	set_map_type,
	toggle_layer,
	reset_gmaps,
	toggle_filter,
	toggle_category,
	select_all_categories,
	select_only_category,
	deselect_all_categories,
	toggle_last_check,
	prompt_last_check_min_value,
	toggle_admin_overlay,
	set_view as set_map_view,
	zoom_on_map,
	show_on_map,
	unshow_on_map,
	find_nearest_street_view,
	show_street_view,
} from "./map";

export {
	add_point,
	set_billable,
	set_user_entry_status,
	reset_points_db,
} from "./data";

export {
	logout,
	login_reset_code,
	login_cn,
	get_user_id,
} from "./accounts";

export {
	ImageCollectionEntry,
	ImageCollectionRecent,
	ImageCollectionPending,
	ImageCollectionUser,
	ImageCollectionRandom,
	Gallery,
} from "./gallery";

export {
	get_value as input_validation_get_value,
} from "./input_validation"

export {
	import_gps_tracks_prompt,
	clear_gps_tracks,
	show_gps_track,
	delete_gps_track,
	set_gps_track_title,
} from "./gps_tracks";

export {
	add_audio_note,
	audio_notes_widget,
} from "./audio_notes";

export {
	set_etable_id,
	set_etable_ignored,
	etable_widget,
} from "./affiliates";

export {show_by_name as show_image_by_name} from "./img_overlay";

export {on_redirect as oauth2_on_redirect} from "./oauth2";

export {
	init_widget as init_signup_widget,
} from "./signup_widget";

export {
	suggest_edit,
} from "./suggest_edit";

export {
	toast,
	close_toast,
} from "./toast";

$("noscript,#splash").remove();

if ("serviceWorker" in navigator)
	addEventListener("load",function() {
		navigator.serviceWorker.register("sw.js").catch(tx_util.do_nothing);
	});
