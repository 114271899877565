import * as base64js from "base64-js";

const proto=HTMLCanvasElement.prototype;
if (!proto.toBlob) {
	if (proto.msToBlob) {
		proto.toBlob=function(callback) {
			setTimeout(() => {
				callback(this.msToBlob());
			});
		};
	}
	else {
		const dataURL_regex=/^data:(.*?);base64,/;
		proto.toBlob=function(callback,type,quality) {
			const dataURL=this.toDataURL(type,quality);
			const m=dataURL.match(dataURL_regex);
			if (!m) throw new Error("Cannot parse dataURL");
			setTimeout(() => {
				const type_out=m[1];
				const base64_data=dataURL.substr(m[0].length);
				const blob=new Blob([base64js.toByteArray(base64_data)],{type:m[1]});
				callback(blob);
			});
		};
	}
}
