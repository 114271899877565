let supports_scrollIntoView_opts=false;
const div=document.createElement("div");
div.scrollIntoView({get behavior() {supports_scrollIntoView_opts=true}});

if (!supports_scrollIntoView_opts) {
	const proto=Element.prototype;
	const orig_scrollIntoView=proto.scrollIntoView;
	proto.scrollIntoView=function(value) {
		orig_scrollIntoView.call(this,typeof value!=="object" ? value : value.block==="start");
	};
}
