const event_regex=/^on./;

export function mke(...args) {
	const spec_parts=typeof args[0]==="string" ? args.shift().split(/([.#])/) : [];
	const elt=document.createElement(spec_parts.shift()||"div");
	const classes=elt.classList;
	for (let i=0,len=spec_parts.length; i<len; i+=2) {
		const value=spec_parts[i+1];
		if (!value) throw new Error("Empty id or class");
		if (spec_parts[i]==="#")
			elt.setAttribute("id",value);
		else
			classes.add(value);
	}
	if (typeof args[0]==="object" && !(args[0] instanceof Node)) {
		const attributes=args.shift()||{};
		for (const name in attributes) {
			const value=attributes[name];
			const type=typeof value;
			if (name==="style") {
				if (type!=="object")
					elt.style.cssText=value;
				else {
					elt.style.cssText="";
					for (const property in value) {
						const m=/^(.*?)(?:\s*!(important))?$/.exec(value[property]);
						elt.style.setProperty(property,m[1],m[2]);
					}
				}
			}
			else if (event_regex.test(name) && type==="function")
				elt.addEventListener(name.slice(2),value);
			else if (type!=="undefined")
				elt.setAttribute(name,value);
		}
	}
	for (const arg of args) {
		const type=typeof arg;
		if (type==="string" || type==="number")
			elt.appendChild(document.createTextNode(arg));
		else
			elt.appendChild(arg);
	}
	return elt;
}
