import * as tx_util from "./util";
import * as tx_input_validation from "./input_validation";
import * as tx_upload from "./upload";
import * as tx_history from "./history";
import * as tx_accounts from "./accounts";
import * as tx_image_editor from "./image_editor";
import {get_exif_orientation} from "./exif_orientation";
import {toast} from "./toast";
import {mke} from "./mke";

const max_filesize_MiB=50;
const max_megapixels=100;
const max_width=20000;
const max_height=20000;

const max_filesize=max_filesize_MiB<<20;

const upload_overlay=document.querySelector("#upload-overlay");
let active_uploader;
export const drag_mime_type="application/x-tx-upload";

const uploader_html_template=
	'<div class="files-pane"></div>'+
	'<div class="toolbar">'+
		'<details class="tips">'+
			'<summary>Συμβουλές:</summary>'+
			'<div>1) Οι φωτογραφίες είναι πιο χρήσιμες για τους χρήστες όταν έχουν λεζάντα.</div>'+
			'<div>2) Παρακαλώ σεβαστείτε την ιδιωτικότητα τρίτων, όπως πρόσωπα ή αριθμοί κυκλοφορίας, τα οποία μπορείτε να αποκρύψετε με την "επεξεργασία".</div>'+
			'<div>3) Μπορείτε πιάνοντας το σύμβολο <div class="grab-area"></div> να αλλάξετε τη σειρά των φωτογραφιών.</div>'+
		'</details>'+
		'<button class="btn-filename-to-caption" data-req="edit_images">Λεζάντες από ονόματα</button>'+
		'<button class="btn-add-more">Προσθήκη επιπλέον εικόνων</button>'+
		'<button class="btn-upload-all" disabled="disabled">Ανέβασμα όλων</button>'+
	'</div>'+
	'';

const file_html_template=
	'<div class="file">'+
		'<div class="thumb busy"><div class="loading"></div></div>'+
		'<div class="sep"></div>'+
		'<div class="info">'+
			'<div>'+
				'<div>Όνομα αρχείου:</div>'+
				'<div class="filename"><div></div></div>'+
			'</div>'+
			'<div>'+
				'<label>'+
					'<div>Λεζάντα:</div>'+
					'<div><input name="caption" data-validate="smallstr?"></div>'+
				'</label>'+
			'</div>'+
			'<div data-req="edit_images">'+
				'<label>BBCode στη λεζάντα: <input name="bbcode" type="checkbox"></label>'+
			'</div>'+
			'<div data-req="edit_images">'+
				'<label>Χρήστης: <input name="user_id" data-validate="uint?" size="10"></label>'+
			'</div>'+
			'<div data-req="edit_images">'+
				'<label>Χωρίς λογότυπο: <input name="no_watermark" type="checkbox"></label>'+
			'</div>'+
			'<div>'+
				'<button class="btn-delete">Διαγραφή</button>'+
				'<button class="btn-edit">Επεξεργασία</button>'+
			'</div>'+
			'<div class="error">&#8203</div>'+
		'</div>'+
		'<div class="grab-area"></div>'+
		'<div class="drop-target before"></div>'+
		'<div class="drop-target after"></div>'+
	'</div>'+
	'';

function svg_circle_part(p) {
	p=Math.min(1,Math.max(0,p));
	const a=p*2*Math.PI;
	let d="M 0,-1 A 1,1 0 0,1";
	if (p>0.5)
		d+=" 0,1 A 1,1 0 0,1";
	return `${d} ${Math.sin(a)},${-Math.cos(a)}${p>=1 ? "z" : ""}`;
}

const _veil=Symbol();
const _path=Symbol();

class ProgressRing {
	constructor(elt) {
		const veil=this[_veil]=mke(".progress-ring");
		const svg=tx_util.create_svg();
		svg.append(tx_util.create_svg_path(svg_circle_part(1)));
		this[_path]=svg.appendChild(tx_util.create_svg_path());
		svg.setAttribute("viewBox","-1.2 -1.2 2.4 2.4");
		veil.append(svg);
		elt.append(veil);
		this.set(0);
	}

	enable() {
		this[_veil].classList.add("active");
	}

	set(p) {
		this[_path].setAttribute("d",svg_circle_part(p));
	}
}

const _upload_id=Symbol();

const _eid=Symbol();
const _etype=Symbol();
const _foodmenu=Symbol();
const _on_uploaded=Symbol();
const _top_div=Symbol();
const _files_input=Symbol();
const _files_pane=Symbol();
const _last_upload_id=Symbol();
const _upload_queue=Symbol();
const _filename_to_caption=Symbol();
const _add_more_button=Symbol();
const _upload_all_button=Symbol();
const _aborter=Symbol();
const _state=Symbol(); // values: 0: not shown yet, 1: shown, 2: upload started (and maybe finished), 3: destroyed

const _open=Symbol();
const _close=Symbol();
const _close_now=Symbol();
const _remove_job=Symbol();
const _remove_file=Symbol();

export class Uploader {
	constructor(eid,etype,foodmenu,on_uploaded) {
		this[_eid]=eid;
		this[_etype]=etype;
		this[_foodmenu]=foodmenu;
		this[_on_uploaded]=on_uploaded;
		this[_top_div]=mke(".frame");
		this[_top_div].innerHTML=uploader_html_template;
		//this[_files_input]=undefined;
		this[_files_pane]=this[_top_div].querySelector(".files-pane");;
		this[_last_upload_id]=0;
		this[_upload_queue]=new Map();
		this[_filename_to_caption]=this[_top_div].querySelector(".btn-filename-to-caption");
		this[_add_more_button]=this[_top_div].querySelector(".btn-add-more");
		this[_upload_all_button]=this[_top_div].querySelector(".btn-upload-all");
		this[_aborter]=new tx_util.Aborter();
		this[_state]=0;

		let last_dragover_elt;
		this[_files_pane].addEventListener("dragenter",ev => {
			last_dragover_elt=ev.target;
			if (Array.from(ev.dataTransfer.types).some(t => t===drag_mime_type || t==="Files"))
				ev.currentTarget.classList.add("dragging");
		});
		this[_files_pane].addEventListener("dragleave",ev => {
			if (last_dragover_elt===ev.target) {
				ev.currentTarget.classList.remove("dragging");
				last_dragover_elt=undefined;
			}
		});

		this[_filename_to_caption].addEventListener("click",() => {
			for (const job of this[_upload_queue].values())
				job.file_div.querySelector('[name="caption"]').value=job.file.name.replace(/\.[a-z]+$/i,"");
		});
		this[_add_more_button].addEventListener("click",() => {
			this.select_files();
		});
		this[_upload_all_button].addEventListener("click",() => {
			this.upload_all()
		});
		//this[_top_div].addEventListener("drop",ev => {
			//ev.preventDefault();
			//this.add_files(ev.dataTransfer.files);
		//});

		tx_util.remember_details_state(this[_top_div].querySelector(".tips"),"upload_tips",0,true);
	}

	[_open]() {
		if (this[_state]===0) {
			if (active_uploader)
				active_uploader[_close_now]();
			else
				tx_history.push_history(null,{upload:true});
			active_uploader=this;
			upload_overlay.classList.add("active");
			upload_overlay.append(this[_top_div]);
			this[_state]=1;
		}
	}

	[_close]() {
		if (this[_state]<3) {
			this[_state]=3;
			tx_history.back();
		}
	}

	[_close_now]() {
		this[_files_pane].innerHTML="";
		this[_filename_to_caption].disabled=true;
		this[_add_more_button].disabled=true;
		this[_upload_all_button].disabled=true;
		for (const job of this[_upload_queue].values())
			this[_remove_job](job);
		this[_upload_queue].clear();
		this[_state]=3;
		if (this[_files_input])
			this[_files_input].remove();
		if (active_uploader===this) {
			this[_top_div].remove();
			upload_overlay.classList.remove("active");
			active_uploader=undefined;
		}
	}

	add_files(files,before_file) {
		if (this[_state]>=2) return;
		files=Array.from(files).sort((f1,f2) => f1.name<f2.name ? -1 : f1.name>f2.name ? 1 : 0);
		let count=0;
		for (const file of files) {
			if (file.type!=="image/jpeg" && file.type!=="image/png" && file.type!=="image/gif") {
				toast(file.name + ": το αρχείο δεν εικόνα τύπου jpeg, png και gif");
				continue;
			}
			else if (file.size>max_filesize) {
				toast(file.name + `: το αρχείο δεν πρέπει να είναι μεγαλύτερο από ${max_filesize_MiB} MiB`);
				continue;
			}

			count++;
			const load_exif_orientation=get_exif_orientation(file).catch(() => undefined).then(exif => exif || [undefined,file]);
			const get_operations=load_exif_orientation.then(([orientation]) => {
				const operations=new tx_image_editor.ImageOperations();
				operations.appendFromExif(orientation);
				return operations;
			});
			const get_patched_img=load_exif_orientation.then(([value,patched_blob]) => tx_util.load_image(URL.createObjectURL(patched_blob)));
			const temp_div=mke();
			temp_div.innerHTML=file_html_template;
			const file_div=temp_div.firstElementChild;
			file_div.querySelector('[name="caption"]').placeholder=
				this[_foodmenu] ? 'πχ "Κατάλογος Ιουνίου 2018: σελίδα 4/6"' : 'πχ "Κοκορέτσι (εξαιρετικό!)"';
			if (before_file)
				before_file.before(file_div);
			else
				this[_files_pane].append(file_div);
			const thumb_div=file_div.querySelector(".thumb");
			const progress=new ProgressRing(thumb_div);
			const grab_area=file_div.querySelector(".grab-area");
			tx_input_validation.enable_validation_recursive(file_div);
			const this_upload_id=++this[_last_upload_id];
			file_div[_upload_id]=this_upload_id;
			file_div.querySelector(".filename>div").textContent=file.name;
			const job={
				removed:false,
				upload_id:this_upload_id,
				file,
				operations:get_operations,
				file_div,
				progress,
				cleanup:() => {
					get_patched_img.then(patched_img => {
						URL.revokeObjectURL(patched_img.src);
					});
				},
			};
			this[_upload_queue].set(this_upload_id,job);

			file_div.querySelector(".btn-delete").addEventListener("click",() => {
				this[_remove_file](job);
			});
			grab_area.addEventListener("mousedown",ev => {
				if (ev.which===1)
					file_div.draggable=true;
			});
			grab_area.addEventListener("mouseup",() => {
				file_div.draggable=false;
			});
			file_div.addEventListener("dragstart",ev => {
				if (this[_state]>=2)
					tx_util.prevent_default(ev);
				else {
					try { // setData fails on ie
						ev.dataTransfer.setData(drag_mime_type,this_upload_id);
						file_div.classList.add("dragged");
					}
					catch (err) {
						tx_util.prevent_default(ev);
					}
				}
			});
			file_div.addEventListener("dragend",ev => {
				file_div.classList.remove("dragged");
				file_div.draggable=false;
			});
			for (const drop_target of file_div.querySelectorAll(".drop-target")) {
				drop_target.addEventListener("dragenter",ev => {
					tx_util.prevent_default(ev);
					if (this[_state]<2 && Array.from(ev.dataTransfer.types).some(t => t===drag_mime_type || t==="Files"))
						ev.currentTarget.classList.add("dragover");
				});
				drop_target.addEventListener("dragleave",ev => {
					ev.currentTarget.classList.remove("dragover");
				});
				drop_target.addEventListener("dragover",ev => {
					tx_util.prevent_default(ev);
					ev.stopPropagation();
					const types=Array.from(ev.dataTransfer.types);
					ev.dataTransfer.dropEffect=
						this[_state]>=2 ? "none" :
						types.includes(drag_mime_type) ? "move" :
						types.includes("Files") ? "copy" :
							"none";
				});
				drop_target.addEventListener("drop",ev => {
					tx_util.prevent_default(ev);
					ev.stopPropagation();
					ev.currentTarget.classList.remove("dragover");
					this[_files_pane].classList.remove("dragging");
					if (this[_state]>=2) return;
					const types=Array.from(ev.dataTransfer.types);
					const is_before=ev.currentTarget.classList.contains("before");
					if (types.includes(drag_mime_type)) {
						const src_job=this[_upload_queue].get(ev.dataTransfer.getData(drag_mime_type)|0)
						if (src_job && src_job.file_div!==file_div) {
							const method=is_before ? "before" : "after";
							file_div[method](src_job.file_div);
						}
					}
					else if (types.includes("Files")) {
						const ref=is_before ? file_div : file_div.nextElementSibling;
						this.add_files(ev.dataTransfer.files,ref);
					}
				});
			}

			Promise.all([get_patched_img,get_operations]).then(([patched_img,operations]) => {
				let last_thumb_seq_no=0;
				const refresh_thumb=() => {
					const this_thumb_seq_no=++last_thumb_seq_no;
					thumb_div.classList.add("busy");
					const canvas=operations.renderBounded(patched_img,thumb_div.scrollWidth,thumb_div.scrollHeight).canvas;
					canvas.toBlob(blob => {
						if (this_thumb_seq_no!==last_thumb_seq_no)
							return;
						const thumb_url=URL.createObjectURL(blob);
						tx_util.load_image(thumb_url).then(thumb_img => {
							if (this_thumb_seq_no!==last_thumb_seq_no) {
								URL.revokeObjectURL(thumb_url);
								return;
							}
							for (const elt of thumb_div.querySelectorAll("img")) {
								URL.revokeObjectURL(elt.src);
								elt.remove();
							}
							thumb_div.classList.remove("busy");
							thumb_img.draggable=false;
							thumb_div.append(thumb_img);
							job.thumb_url=thumb_url;
						});
					},file.type||"image/jpeg",0.85);
				};
				refresh_thumb();

				let err;
				if (patched_img.width*patched_img.height>=max_megapixels*1e6*1.01)
					err=`Η εικόνα πρέπει έχει λιγότερα από ${max_megapixels} megapixel`;
				else if (patched_img.width>max_width || patched_img.height>max_height)
					err=`Η εικόνα πρέπει να είναι μικρότερη από ${max_width}x${max_height} pixel`;
				if (err) {
					toast(job.file.name+": "+err);
					job.file_div.querySelector(".error").textContent=err;
					job.aborted=true;
				}
				else {
					file_div.querySelector(".btn-edit").addEventListener("click",() => {
						tx_image_editor.edit_image(patched_img,operations).then(committed => {
							if (committed) refresh_thumb();
						});
					});
				}
			},() => {
				toast(`Η φόρτωση του αρχείου ${job.file.name} απέτυχε`);
				job.file_div.querySelector(".error").textContent="Η φόρτωση του αρχείου απέτυχε";
				job.aborted=true;
			});
		}
		if (count>0) this[_open]();
		this[_upload_all_button].disabled=this[_upload_queue].size===0;
	}

	select_files() {
		if (this[_state]>=2) return;
		if (!this[_files_input]) {
			const files_input=mke("input",{type:"file",accept:"image/jpeg,image/png,image/gif",multiple:"multiple"});
			files_input.addEventListener("change",() => {
				this.add_files(files_input.files);
				files_input.value="";
			});
			upload_overlay.append(files_input);
			this[_files_input]=files_input;
		}
		this[_files_input].click();
	}

	[_remove_job](job) {
		if (job.removed) return;
		this[_upload_queue].delete(job.upload_id);
		job.removed=true;
		job.cleanup();
		if (job.thumb_url)
			URL.revokeObjectURL(job.thumb_url);
		$(job.file_div).slideUp(() => {
			job.file_div.remove();
		});
	}

	[_remove_file](job) {
		this[_remove_job](job);
		if (this[_upload_queue].size===0)
			this[_close]();
	}

	upload_all() {
		if (this[_state]>=2) return;
		let user_id;
		for (const file_div of this[_files_pane].querySelectorAll(".file")) {
			const params=tx_input_validation.get_form_values(file_div,true);
			if (params===undefined) return;
			if (user_id===undefined)
				user_id=params.user_id;
			else if (user_id!==params.user_id) {
				toast("Τα user id είναι διαφορετικά");
				return;
			}
			const job=this[_upload_queue].get(file_div[_upload_id]);
			if (job) job._params=params;
		}
		this[_state]=2;
		const jobs=[];
		for (const file_div of this[_files_pane].querySelectorAll(".file")) {
			for (const elt of file_div.querySelectorAll("input,button"))
				elt.disabled=true;
			const job=this[_upload_queue].get(file_div[_upload_id]);
			if (job) {
				jobs.push(job);
				job.progress.enable();
			}
		}
		if (jobs.length>0)
			jobs[0].file_div.scrollIntoView({behavior:"smooth"});
		this[_add_more_button].disabled=true;
		this[_filename_to_caption].disabled=true;
		this[_upload_all_button].disabled=true;
		let first_image_name;
		const loop=() => {
			if (jobs.length===0)
				return;
			const job=jobs.shift();
			job.operations.then(operations => {
				if (job.removed || job.aborted) return;
				if (this[_aborter].aborted) {
					this[_remove_job](job);
					return;
				}
				const progress=job.progress;
				const {caption,bbcode,user_id,no_watermark}=job._params;
				const img_info={
					foodmenu:this[_foodmenu],
					entry:{eid:this[_eid],etype:this[_etype]},
					caption,
					bbcode,
					watermark:!no_watermark,
					operations:operations.toJSON(),
				};
				if (user_id!=null) img_info.user=user_id===0 ? null : {id:user_id};
				if (first_image_name) img_info.album_with=first_image_name;
				return tx_upload.upload(
					"image",job.file,img_info,this[_aborter],(bytes,frac) => {progress.set(frac)}
				).then(new_image => {
					this[_remove_file](job);
					this[_on_uploaded](new_image);
					if (!first_image_name) first_image_name=new_image.name;
				},err => {
					if (err instanceof tx_util.RequestAborted)
						this[_remove_job](job);
					else {
						const msg=err.code===57 ? "Η επεξεργασία της εικόνας απέτυχε" : "Το ανέβασμα της εικόνας απέτυχε";
						toast(`${job.file.name}: ${msg}`);
						console.log("upload image failed",job.file.name,err);
						job.file_div.querySelector(".error").textContent=msg;
					}
				});
			}).then(loop);
		};
		loop();
	}

	abort() {
		this[_aborter].abort();
	}
}

export function on_history(state) {
	if (active_uploader && !state.upload)
		active_uploader[_close_now]();
}

export function init() {
	upload_overlay.querySelector(".close-btn").addEventListener("click",() => {
		if (active_uploader && confirm("Δεν έχουν ανέβει όλες οι εικόνες ακόμα, θέλεις να σταματήσουν;")) {
			active_uploader.abort();
			active_uploader[_close]();
		}
	});
}
