import * as tx_infobox from "./infobox";
import * as tx_tabs from "./tabs";
import * as tx_input_validation from "./input_validation";
import * as tx_accounts from "./accounts";
import * as tx_util from "./util";
import * as tx_counters from "./counters";
import {toast} from "./toast";

let max_step=-1;
const max_step_no={
	login:               -1,
	select_method:       0,
	signup_email:        1,
	affiliation:         2,
	username:            3,
	accept_terms:        4,
	submit_accept_terms: 5,
};

export function init_widget(elt,mode) {
	const widget_div=elt.closest(".signup");
	let signup_with_email=false;
	const forms={};
	for (const form of widget_div.querySelectorAll("form[data-step]"))
		forms[form.getAttribute("data-step")]=form;
	const select_method_form=forms.select_method;
	const signup_email_form=forms.signup_email;
	const affiliation_form=forms.affiliation;
	const username_form=forms.username;
	const login_form=forms.login;
	let active_form;
	let google_token;
	const set_state=state => {
		if (active_form) {
			active_form.style.display="";
			active_form.noValidate=true;
		}
		max_step=Math.max(max_step,max_step_no[state]);
		active_form=forms[state];
		if (active_form) {
			active_form.style.display="block";
			active_form.noValidate=false;
		}
		if (state==="select_method") {
			google_token=undefined;
		}
	};
	const update_step_numbers=() => {
		const steps=["affiliation","username","accept_terms"];
		if (signup_with_email) steps.unshift("signup_email");
		const count=steps.length;
		for (let i=0; i<count; i++)
			forms[steps[i]].querySelector(".step>div").textContent=`${i+1}/${count}`;
	};
	if (mode==="signup") 
		set_state("select_method");
	else if (mode==="login") 
		set_state("login");
	else if (mode==="relogin") {
		login_form.setAttribute("data-relogin","1");
		set_state("login");
	}

	const done=(err,form_name,input_selector) => {
		tx_tabs.set_busy(widget_div,false);
		if (err) {
			if (!form_name)
				toast(err);
			else {
				set_state(form_name);
				tx_input_validation.report_error(err,forms[form_name],input_selector);
			}
		}
	};

	const do_login_request=(method_name,token) => {
		tx_util.api_request(method_name,token,(res,err) => {
			if (!err) {
				done();
				max_step=-1;
				tx_accounts.login(res);
				if (mode!=="relogin")
					tx_infobox.show_success(widget_div,"Η σύνδεση ολοκληρώθηκε επιτυχώς");
				else
					successful_relogin(widget_div);
			}
			else if (err.code===8)
				done("Το αίτημα απέτυχε, δοκιμάστε ξανά σε λίγο");
			else if (err.code===26) {
				if (mode==="relogin")
					done("Ο λογαριασμός Google δεν είναι συνδεδεμένος με κάποιον λογαριασμό του ταβερνοχώρου");
				else {
					done();
					signup_with_email=false;
					update_step_numbers();
					set_state("affiliation");
				}
			}
			else
				done(`Παρουσιάστηκε σφάλμα (${err.code})`);
		});
	};
	const login_google=() => {
		tx_tabs.set_busy(widget_div,true);
		tx_accounts.get_google_token(id_token => {
			if (!id_token) return done("Η σύνδεση με το λογαριασμό Google απέτυχε");
			google_token=id_token;
			do_login_request("login_google",id_token);
		});
	};
	for (const logo_img of widget_div.querySelectorAll(".login_logo.google img")) {
		logo_img.addEventListener("click",login_google);
	}

	widget_div.querySelector(".signup-email").addEventListener("click",() => {
		signup_with_email=true;
		update_step_numbers();
		set_state("signup_email");
	});

	const renew_captcha=() => {
		tx_util.api_request_promise("request_captcha").then(captcha_key => {
			signup_email_form.querySelector('[name="captcha_key"]').value=captcha_key;
			const img=new Image();
			img.onload=() => {
				signup_email_form.querySelector('[name="captcha_text"]').value="";
				signup_email_form.querySelector(".captcha img").replaceWith(img);
			};
			img.src=`https://devel.tavernoxoros.gr/action/captcha?key=${captcha_key}`;
		});
	};
	signup_email_form.addEventListener("submit",ev => {
		tx_util.prevent_default(ev);
		const params=tx_input_validation.get_form_values(signup_email_form,true);
		if (!params) {
			// do nothing
		}
		else if (params.password.length<6)
			tx_input_validation.report_error("Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες",signup_email_form,'[name="password"]');
		else if (params.password!==params.password2)
			tx_input_validation.report_error("Οι δύο κωδικοί πρόσβασης διαφέρουν",signup_email_form,'[name="password2"]');
		else {
			tx_tabs.set_busy(widget_div,true);
			const check_captcha_loop=() => {
				tx_util.api_request("check_captcha",params.captcha_key,params.captcha_text,(res,err) => {
					if (err && err.code===47)
						return setTimeout(check_captcha_loop,25000);
					tx_tabs.set_busy(widget_div,false);
					if (!err)
						set_state("affiliation");
					else if ((err.code|1)===49) {
						tx_input_validation.report_error("Ο κωδικός επαλήθευσης δεν είναι σωστός",signup_email_form,'[name="captcha_text"]');
						if (err.code===48) renew_captcha();
					}
					else
						toast(`Παρουσιάστηκε σφάλμα (${err.code})`);
				});
			};
			check_captcha_loop();
		}
	});
	signup_email_form.querySelector(".back").addEventListener("click",() => {
		set_state("select_method");
	});

	const affiliation_next=affiliation_form.querySelector('[type="submit"]');
	const affiliation_updated=() => {
		const params=tx_input_validation.get_form_values(affiliation_form);
		affiliation_next.disabled=!params.affiliation;
	};
	for (const radio of affiliation_form.querySelectorAll('[type="radio"]'))
		radio.addEventListener("change",affiliation_updated);
	affiliation_updated();
	affiliation_form.addEventListener("submit",ev => {
		tx_util.prevent_default(ev);
		const params=tx_input_validation.get_form_values(affiliation_form);
		set_state("username");
	});
	affiliation_form.querySelector(".back").addEventListener("click",() => {
		set_state(signup_with_email ? "signup_email" : "select_method");
	});

	const username_submit=username_form.querySelector('[type="submit"]');
	const username_input=username_form.querySelector('[name="username"]');
	const username_error_div=username_form.querySelector('.error');
	const username_loading_style=username_form.querySelector('.loading').style;
	let timeout_id=0;
	username_input.addEventListener("input",() => {
		const username=username_input.value;
		username_submit.disabled=true;
		username_error_div.textContent="";
		username_loading_style.display="none";
		clearTimeout(timeout_id);
		timeout_id=setTimeout(() => {
			if (username.length<2)
				username_error_div.textContent="Το ψευδώνυμο είναι πολύ μικρό";
			else if (username.length>30)
				username_error_div.textContent="Το ψευδώνυμο είναι πολύ μεγάλο";
			else {
				username_loading_style.display="";
				tx_util.api_request("is_username_available",username,r => {
					if (username!==username_input.value) return;
					username_loading_style.display="none";
					if (!r) username_error_div.textContent="Το ψευδώνυμο δεν είναι διαθέσιμο";
					username_submit.disabled=!r;
				});
			}
		},1000);
	});
	username_form.addEventListener("submit",ev => {
		tx_util.prevent_default(ev);
		set_state("accept_terms");
	});
	username_form.querySelector(".back").addEventListener("click",() => {
		set_state("affiliation");
	});

	const signup=() => {
		max_step=Math.max(max_step,max_step_no["submit_accept_terms"]);
		const params=Object.assign(
			tx_input_validation.get_form_values(username_form),
			tx_input_validation.get_form_values(affiliation_form),
		);
		const affiliation=params.affiliation==="no" ? null : params.affiliation;
		let args;
		if (signup_with_email) {
			Object.assign(params,tx_input_validation.get_form_values(signup_email_form));
			args=["signup_email",params.invite_code,params.username,params.email,params.password,affiliation,params.captcha_key,params.captcha_text];
		}
		else if (google_token)
			args=["signup_google",params.invite_code,params.username,affiliation,google_token];
		else
			return;
		tx_tabs.set_busy(widget_div,true);
		const signup_loop=() => {
			tx_util.api_request(...args,(res,err) => {
				if (!err) {
					if (res)
						tx_accounts.login(res);
					done();
					if (signup_with_email)
						tx_infobox.submit_form(null,widget_div,"signup_success_email",{email:params.email},undefined,true);
					else
						tx_infobox.load_page_content(widget_div,"x/signup_success");
					max_step=-1;
				}
				else if (err.code===5)
					done("Το ψευδώνυμο δεν είναι διαθέσιμο",'username','[name="username"]');
				else if (err.code===6)
					done("Η διεύθυνση email χρησιμοποιείται ήδη από άλλον χρήστη",'signup_email','[name="email"]');
				else if (err.code===8)
					done("Το αίτημα απέτυχε, δοκιμάστε ξανά σε λίγο");
				else if (err.code===11)
					done("Μη έγκυρος κωδικός Google");
				else if (err.code===14)
					done("Ο λογαριασμός google είναι ήδη συνδεδεμένος με κάποιον άλλο λογαριασμό του ταβερνοχώρου");
				else if (err.code===15)
					done("Το ψευδώνυμο που επιλέξατε είναι πολύ μικρό ή υπερβολικά μεγάλο",'username','[name="username"]');
				else if (err.code===16)
					done("Η διεύθυνση email δεν είναι αποδεκτή, δοκιμάστε ξανά με μία διαφορετική",'signup_email','[name="email"]');
				else if (err.code===21)
					done("Μη έγκυρος κωδικός πρόσκλησης");
				else if (err.code===47)
					setTimeout(signup_loop,25000);
				else if (err.code===48) {
					renew_captcha();
					done("Ο κωδικός επαλήθευσης δεν είναι σωστός",'signup_email','[name="captcha_text"]');
				}
				else if (err.code===49)
					done("Ο κωδικός επαλήθευσης δεν είναι σωστός",'signup_email','[name="captcha_text"]');
				else
					done(`Παρουσιάστηκε σφάλμα (${err.code})`);
			});
		};
		signup_loop();
	};

	forms.accept_terms.addEventListener("submit",ev => {
		tx_util.prevent_default(ev);
		signup();
	});
	forms.accept_terms.querySelector(".back").addEventListener("click",() => {
		set_state("username");
	});

	login_form.addEventListener("submit",ev => {
		tx_util.prevent_default(ev);
		tx_tabs.set_busy(widget_div,true);
		const params=tx_input_validation.get_form_values(login_form);
		if (mode!=="relogin") {
			tx_util.api_request("login_email",params.email,params.password,(res,err) => {
				if (!err) {
					max_step=-1;
					tx_accounts.login(res);
					done();
					tx_infobox.show_success(widget_div,"Η σύνδεση ολοκληρώθηκε επιτυχώς");
				}
				else if (err.code===-32602 || err.code===2)
					done("Λανθασμένος κωδικός πρόσβασης ή email");
				else if (err.code===7)
					done("Ο λογαριασμός δεν έχει ενεργοποιηθεί ακόμα");
				else
					done(`Παρουσιάστηκε σφάλμα (${err.code})`);
			});
		}
		else {
			tx_util.api_request("relogin_email",params.password,(res,err) => {
				if (!err) {
					tx_accounts.login(res);
					done();
					successful_relogin(widget_div);
				}
				else if (err.code===-32602 || err.code===2)
					done("Λανθασμένος κωδικός πρόσβασης");
				else if (err.code===7)
					done("Ο λογαριασμός δεν έχει ενεργοποιηθεί ακόμα");
				else
					done(`Παρουσιάστηκε σφάλμα (${err.code})`);
			});
		}
	});
}

function successful_relogin(elt) {
	const page_div=elt.closest(".tabs_page_content");
	$(".signup",page_div).hide();
	$(".after-relogin",page_div).show();
}

export function init() {
	tx_counters.register_on_unload(() => {
		if (max_step>=0)
			tx_counters.inc(46000+max_step,1);
	});
}
